<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div class="d-flex align-items-center justify-content-between">
        <h4 routerLink="/docs/welcome" class="fw-bold">
          {{ "GUILD-WELCOME.TITLE" | translate }}
        </h4>
        <div class="slide-toggle">
          <input
            type="checkbox"
            id="check"
            class="slide-toggle-input"
            aria-checked="true"
            formControlName="enabled"
          />
          <label for="check" class="slide-toggle-label"></label>
        </div>
      </div>
      <hr />
      <div class="pt-1" title="Customize">
        <p class="desc">{{ "GUILD-WELCOME.DESC" | translate }}</p>
      </div>
      <div class="row">
        <div class="col-lg-4 col-sm-6 mb-3">
          <label>{{ "GUILD-WELCOME.CHANNEL" | translate }}</label>
          <select formControlName="channel">
            <option *ngFor="let channel of textChannels" [value]="channel.id">
              #{{ channel.name }}
              <span class=""
                >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
              >
            </option>
          </select>
        </div>
        <div class="col-lg-4 col-sm-6 mb-3">
          <label id="lblmess">{{ "GUILD-WELCOME.AUTOROLE" | translate }}</label>
          <mat-select multiple formControlName="autoRoles">
            <mat-option *ngFor="let role of roles" [value]="role.id">
              <div
                [style.color]="'#' + role.color?.toString(16) + '!important'"
              >
                &#64;{{ role.name }}
              </div>
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="accordion d-flex flex-column gap-2" id="accordionPrincipal">
        <div class="rounded-3 accordion-item">
          <div class="accordion-header" id="headingSendMessage">
            <button
              class="p-4 rounded-3 accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapsePrimero"
              aria-expanded="false"
              aria-controls="collapsePrimero"
            >
              {{ "GUILD-WELCOME.SEND-MESSAGE.TITLE" | translate }}
            </button>
          </div>
          <div
            id="collapsePrimero"
            class="accordion-collapse collapse"
            aria-labelledby="headingSendMessage"
            data-bs-parent="#accordionPrincipal"
          >
            <div class="accordion-body">
              <div class="kroAiu">
                <div class="btn-group" role="group">
                  <div id="headingOneMessage">
                    <button
                      [class.interactive-muted]="
                        !this.form.get('message.enabled').value === true
                      "
                      (click)="
                        this.messageEnabled.setValue(true);
                        this.embedEnabled.setValue(false)
                      "
                      class="btn bQHyRb"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseUno"
                      aria-controls="collapseUno"
                    >
                      {{
                        "GUILD-WELCOME.SEND-MESSAGE.MESSAGE.TITLE" | translate
                      }}
                    </button>
                  </div>
                  <div id="headingTwoEmbed">
                    <button
                      [class.interactive-muted]="
                        !this.form.get('embed.enabled').value === true
                      "
                      (click)="
                        this.messageEnabled.setValue(false);
                        this.embedEnabled.setValue(true)
                      "
                      class="btn bQHyRb"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseDos"
                      aria-controls="collapseDos"
                    >
                      {{ "GUILD-WELCOME.SEND-MESSAGE.EMBED.TITLE" | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <div id="accordionSecundario" class="row">
                <div formGroupName="message">
                  <div
                    id="collapseUno"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOneMessage"
                    data-bs-parent="#accordionSecundario"
                  >
                    <div class="d-flex flex-column py-2">
                      <textarea
                        #content
                        maxlength="2000"
                        formControlName="content"
                      ></textarea>
                      <hint align="end">{{ content.value.length }} / 2000</hint>
                    </div>
                    <message-preview
                      [content]="content.value"
                      [guild]="guild"
                    ></message-preview>
                  </div>
                </div>
                <div formGroupName="embed">
                  <div
                    id="collapseDos"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwoEmbed"
                    data-bs-parent="#accordionSecundario"
                  >
                    <div class="embed">
                      <span
                        class="stripeLine"
                        [style.background]="color.value"
                      ></span>
                      <div class="embedBody">
                        <div class="">
                          <div class="position-relative max-w-field w-100 mt-4">
                            <div
                              style="
                                background-color: var(--background-tertiary);
                              "
                              class="rounded flex items-center cursor-pointer p-3 justify-between"
                            >
                              <div class="colors">
                                <button
                                  [style.background]="arrayColors[color]"
                                  *ngFor="let color of colorspicker"
                                  (click)="
                                    submitColor();
                                    buttonClicked = 'EmbedCard ' + color
                                  "
                                ></button>
                              </div>
                            </div>
                          </div>
                          <div class="position-relative max-w-field w-100 mt-4">
                            <label
                              class="fs-6 mb-2 d-flex align-items-start justify-content-start"
                              >{{
                                "GUILD-WELCOME.SEND-MESSAGE.EMBED.TITLE"
                                  | translate
                              }}</label
                            >
                            <div class="d-flex flex-column">
                              <textarea
                                #title
                                maxlength="256"
                                formControlName="title"
                              ></textarea>
                              <hint align="end"
                                >{{ title.value.length }} / 4096</hint
                              >
                            </div>
                          </div>
                        </div>
                        <div class="position-relative max-w-field w-100 mt-4">
                          <label
                            class="fs-6 mb-2 d-flex align-items-start justify-content-start"
                            >{{
                              "GUILD-WELCOME.SEND-MESSAGE.EMBED.THUMBNAIL"
                                | translate
                            }}</label
                          >
                          <div class="d-flex flex-column">
                            <input
                              type="url"
                              #thumbnail
                              formControlName="thumbnail"
                            />
                          </div>
                        </div>
                        <div class="description">
                          <label
                            class="fs-6 mb-2 d-flex align-items-start justify-content-start"
                            >{{
                              "GUILD-WELCOME.SEND-MESSAGE.EMBED.DESCRIPTION"
                                | translate
                            }}</label
                          >
                          <div class="d-flex flex-column">
                            <textarea
                              #description
                              maxlength="4096"
                              formControlName="description"
                            ></textarea>
                            <hint align="end"
                              >{{ description.value.length }} / 4096</hint
                            >
                          </div>
                        </div>
                        <div class="image">
                          <label
                            class="fs-6 mb-2 d-flex align-items-start justify-content-start"
                            >{{
                              "GUILD-WELCOME.SEND-MESSAGE.EMBED.IMAGE"
                                | translate
                            }}</label
                          >
                          <div class="d-flex flex-column">
                            <textarea
                              #image
                              maxlength="4096"
                              formControlName="image"
                            ></textarea>
                            <hint align="end"
                              >{{ description.value.length }} / 4096</hint
                            >
                          </div>
                        </div>
                        <div class="footer">
                          <label
                            class="fs-6 mb-2 d-flex align-items-start justify-content-start"
                            >{{
                              "GUILD-WELCOME.SEND-MESSAGE.EMBED.FOOTER"
                                | translate
                            }}</label
                          >
                          <div class="d-flex flex-column">
                            <textarea
                              #footer
                              maxlength="2048"
                              formControlName="footer"
                            ></textarea>
                            <hint align="end"
                              >{{ footer.value.length }} / 2048</hint
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <embed-preview
                      [title]="title.value"
                      [color]="color.value"
                      [thumbnail]="thumbnail.value"
                      [content]="description.value"
                      [image]="image.value"
                      [footer]="footer.value"
                      [button]="this.button"
                      [guild]="guild"
                    >
                    </embed-preview>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formGroupName="card" class="rounded-3 accordion-item">
          <div class="accordion-header" id="headingWelcomeCard">
            <button
              class="p-4 rounded-3 accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSegundo"
              aria-expanded="false"
              aria-controls="collapseSegundo"
            >
              {{ "GUILD-WELCOME.WELCOMECARD.TITLE" | translate }}
            </button>
          </div>
          <div
            id="collapseSegundo"
            class="accordion-collapse collapse"
            aria-labelledby="headingWelcomeCard"
            data-bs-parent="#accordionPrincipal"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="col-lg-4 col-sm-6 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.BACKGROUND" | translate
                    }}</label
                  >
                  <div class="d-flex flex-column">
                    <textarea
                      formControlName="backgroundURL"
                      #backgroundURL
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.TITLE" | translate
                    }}</label
                  >
                  <div class="d-flex flex-column">
                    <textarea formControlName="titulo" #titulo></textarea>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.SUBTITLE" | translate
                    }}</label
                  >
                  <div class="d-flex flex-column">
                    <textarea formControlName="subtitulo" #subtitulo></textarea>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.OVERLAY" | translate
                    }}</label
                  >
                  <slider class="d-flex gap-2">
                    <input
                      type="range"
                      step="0.1"
                      min="0.001"
                      max="1.001"
                      *ngIf="savedGuild"
                      readonly
                      formControlName="opacityOverlay"
                    />
                    <output>{{ this.form.value.card.opacityOverlay }}</output>
                  </slider>
                </div>
                <div class="col-lg-4 col-sm-6 mt-3 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.COLOR.PRIMARY"
                        | translate
                    }}</label
                  >
                  <div
                    style="background-color: var(--background-tertiary)"
                    class="rounded flex items-center cursor-pointer p-3 justify-between"
                  >
                    <div class="colors">
                      <input
                        type="color"
                        value="colorPickers[0]"
                        formControlName="primary"
                      />
                      <button
                        [style.background]="arrayColors[color]"
                        *ngFor="let color of colorspicker"
                        (click)="
                          submitColor();
                          buttonClicked = 'WelcomeCard primary ' + color
                        "
                      ></button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-3 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.COLOR.SECONDARY"
                        | translate
                    }}</label
                  >
                  <div
                    style="background-color: var(--background-tertiary)"
                    class="rounded flex items-center cursor-pointer p-3 justify-between"
                  >
                    <div class="colors">
                      <input
                        type="color"
                        value="colorPickers[1]"
                        formControlName="secondary"
                      />
                      <button
                        [style.background]="arrayColors[color]"
                        *ngFor="let color of colorspicker"
                        (click)="
                          submitColor();
                          buttonClicked = 'WelcomeCard secondary ' + color
                        "
                      ></button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-3 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.COLOR.TERTIARY"
                        | translate
                    }}</label
                  >
                  <div
                    style="background-color: var(--background-tertiary)"
                    class="rounded flex items-center cursor-pointer p-3 justify-between"
                  >
                    <div class="colors">
                      <input
                        type="color"
                        value="colorPickers[2]"
                        formControlName="tertiary"
                      />
                      <button
                        [style.background]="arrayColors[color]"
                        *ngFor="let color of colorspicker"
                        (click)="
                          submitColor();
                          buttonClicked = 'WelcomeCard tertiary ' + color
                        "
                      ></button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-3 mb-3">
                  <label
                    class="fs-6 mb-2 d-flex align-items-center justify-content-start"
                    >{{
                      "GUILD-WELCOME.WELCOMECARD.CANVA.COLOR.CUATERNARY"
                        | translate
                    }}</label
                  >
                  <div
                    style="background-color: var(--background-tertiary)"
                    class="rounded flex items-center cursor-pointer p-3 justify-between"
                  >
                    <div class="colors">
                      <input
                        type="color"
                        value="colorPickers[3]"
                        formControlName="quarter"
                      />
                      <button
                        [style.background]="arrayColors[color]"
                        *ngFor="let color of colorspicker"
                        (click)="
                          submitColor();
                          buttonClicked = 'WelcomeCard quarter ' + color
                        "
                      ></button>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-4 col-sm-6 d-flex flex-row align-items-center justify-content-evenly"
                >
                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="typeOverlay"
                    class="d-flex gap-2 align-items-center justify-content-evenly"
                  >
                    <mat-radio-button matInput [value]="types.option1"
                      >Recto</mat-radio-button
                    >
                    <mat-radio-button matInput [value]="types.option2"
                      >Redondeado</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
                <div class="col-lg-4 col-sm-6">
                  <label>{{
                    "GUILD-WELCOME.WELCOMECARD.CANVA.FONTFAMILY" | translate
                  }}</label>
                  <select formControlName="fontFamily">
                    <option
                      *ngFor="let fontFamily of fonts"
                      [value]="fontFamily"
                    >
                      {{ fontFamily | snakeToSentenceCase }}
                    </option>
                  </select>
                </div>
              </div>
              <hr />
              <div class="d-flex align-items-center gap-2">
                <div class="slide-toggle">
                  <input
                    type="checkbox"
                    id="check1"
                    class="slide-toggle-input"
                    aria-checked="true"
                    formControlName="enabled"
                  />
                  <label for="check1" class="slide-toggle-label"></label>
                </div>
                <a>{{
                  "GUILD-WELCOME.WELCOMECARD.CANVA.SLIDER" | translate
                }}</a>
              </div>
              <div class="text-center">
                <img [src]="welcomeCardPreviewURL" class="rounded" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-3 accordion-item">
          <div class="accordion-header" id="headingVariables">
            <button
              class="p-4 rounded-3 accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTercero"
              aria-expanded="false"
              aria-controls="collapseTercero"
            >
              {{ "GUILD-LOGS2.MAT-VARIBLES" | translate }}
            </button>
          </div>
          <div
            id="collapseTercero"
            class="accordion-collapse collapse"
            aria-labelledby="headingVariables"
            data-bs-parent="#accordionPrincipal"
          >
            <div class="accordion-body table-responsive-sm">
              <p>{{ "GUILD-LOGS2.MAT-VARIABLES-DESC" | translate }}</p>
              <table class="table table-striped table-borderless">
                <thead>
                  <tr>
                    <th scope="col">{{ "GUILD-LOGS2.THEAD-1" | translate }}</th>
                    <th scope="col">{{ "GUILD-LOGS2.THEAD-2" | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <code>{{ "{server} | {server.name}" }}</code>
                    </th>
                    <td scope="row">{{ "GUILD-LOGS2.TBODY-9" | translate }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{server.id}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-10" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{server.icon_url}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-11" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{server.member_count}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-12" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{server.owner_id} | {server.owner}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-13" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{user} | {user.mention}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-14" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{user.avatar_url}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-15" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{user.discriminator}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-16" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{user.id}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-17" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{user.name}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-18" | translate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <code>{{ "{user.tag}" }}</code>
                    </th>
                    <td scope="row">
                      {{ "GUILD-LOGS2.TBODY-19" | translate }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
