<div
  class="d-inline-flex justify-content-center align-items-center"
  routerLink="/premium"
  routerLinkActive="active"
>
  <svg
    class="{{ classvg }}"
    viewBox="0 0 256 192"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M 97.782 98.807 Z M 85.814 135.231 L 54.41 135.231 L 33.431 98.807 L 64.706 98.807 L 85.814 135.231 Z M 84.913 98.807 L 74.874 81.304 L 3.185 81.304 L 13.224 98.807 L 34.204 135.231 L 44.371 152.736 L 116.059 152.736 L 106.02 135.231 L 84.913 98.807 Z M 160.076 98.807 Z M 172.046 135.231 L 203.449 135.231 L 224.429 98.807 L 193.152 98.807 L 172.046 135.231 Z M 128.929 167.407 L 204.995 167.407 L 194.954 184.911 L 62.905 184.911 L 52.866 167.407 L 128.929 167.407 Z M 172.946 98.807 L 182.985 81.304 L 254.674 81.304 L 244.634 98.807 L 223.654 135.231 L 213.488 152.736 L 141.799 152.736 L 151.839 135.231 L 172.946 98.807 Z M 128.929 117.728 L 117.99 98.807 L 107.951 81.304 L 128.929 44.881 L 149.908 81.304 L 139.869 98.807 L 128.929 117.728 Z M 118.762 135.231 L 128.929 152.736 L 139.098 135.231 L 160.076 98.807 L 170.114 81.304 L 160.076 63.801 L 128.929 9.873 L 97.782 63.801 L 87.744 81.304 L 97.782 98.807 L 118.762 135.231 Z"
      style="fill: var(--premium)"
    /></svg
  >{{ title }}
</div>
