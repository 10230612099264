import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayService {
  endpoint = environment.endpoint + '/pay';
  constructor() { }

  payURL(plan: number) {
    const key = localStorage.getItem('key');
    return `${this.endpoint}?key=${key}&plan=${plan}`;
  }
}