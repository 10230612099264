<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div class="d-flex align-items-center justify-content-between">
        <h4 routerLink="/docs/auto-mod" class="fw-bold">
          {{ "AUTO-MOD.TITLE" | translate }}
        </h4>
        <div class="slide-toggle">
          <input
            type="checkbox"
            id="check"
            class="slide-toggle-input"
            aria-checked="true"
            formControlName="enabled"
          />
          <label for="check" class="slide-toggle-label"></label>
        </div>
      </div>
      <hr />
      <h3>{{ "AUTO-MOD.FILTER" | translate }}</h3>
      <p class="desc">{{ "AUTO-MOD.FILTER-DESC" | translate }}</p>
      <div class="row g-3">
        <div class="col-lg-4 col-sm-6">
          <label>{{ "AUTO-MOD.FILTER-ENABLED" | translate }}</label>
          <mat-select multiple formControlName="filters">
            <mat-option *ngFor="let filter of filters" [value]="filter"
              >{{ filter | snakeToSentenceCase }}
            </mat-option>
          </mat-select>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "AUTO-MOD.FILTER-THRESOLD" | translate }}</label>
          <slider class="d-flex gap-2">
            <input
              type="range"
              min="0"
              max="20"
              step="5"
              formControlName="filterThreshold"
            />
            <output>{{ this.form.value.filterThreshold }}</output>
          </slider>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "AUTO-MOD.IGNORE-CHANNELS" | translate }}</label>
          <mat-select multiple formControlName="ignoredChannels">
            <mat-option
              *ngFor="let channel of textChannels"
              [value]="channel.id"
              >#{{ channel.name }}
              <span class="float-right text-muted"
                >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
              >
            </mat-option>
          </mat-select>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "AUTO-MOD.PROHIBIT-WORDS" | translate }}</label>
          <mat-chip-grid
            #banWords
            formControlName="banWords"
            aria-label="Prohibir la selección de palabras"
          >
            <mat-chip-option
              *ngFor="let word of savedGuild.autoMod.banWords"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(word, savedGuild.autoMod.banWords)"
            >
              {{ word }}
              <button matChipRemove *ngIf="removable">X</button>
            </mat-chip-option>
            <input
              type="text"
              placeholder="Nueva palabra..."
              [matChipInputFor]="banWords"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event, savedGuild.autoMod.banWords)"
            />
          </mat-chip-grid>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "AUTO-MOD.PROHIBIT-LINKS" | translate }}</label>
          <mat-chip-grid
            #banLinks
            aria-label="Prohibir la selección de enlaces"
          >
            <mat-chip-option
              *ngFor="let link of savedGuild.autoMod.banLinks"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(link, savedGuild.autoMod.banLinks)"
            >
              {{ link }}
              <button matChipRemove *ngIf="removable">X</button>
            </mat-chip-option>
            <input
              type="text"
              placeholder="Nuevo enlace..."
              [matChipInputFor]="banLinks"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event, savedGuild.autoMod.banLinks)"
            />
          </mat-chip-grid>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "AUTO-MOD.IGNORE-ROLES" | translate }}</label>
          <mat-select multiple formControlName="ignoredRoles">
            <mat-option *ngFor="let role of roles" [value]="role.id">
              <div
                [style.color]="'#' + role.color?.toString(16) + '!important'"
              >
                &#64;{{ role.name }}
              </div>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-lg-4 col-sm-6 d-flex align-items-center">
          <label class="mx-2">{{
            "AUTO-MOD.AUTOMATICALLY-NOTIFY-USERS" | translate
          }}</label>
          <input type="checkbox" formControlName="autoWarnUsers" />
        </div>
        <div class="col-lg-4 col-sm-6 d-flex align-items-center">
          <label class="mx-2">{{
            "AUTO-MOD.AUTOMATICALLY-DELETE-MESSAGES" | translate
          }}</label>
          <input type="checkbox" formControlName="autoDeleteMessages" />
        </div>
      </div>
      <hr />
      <premium-paywall>
        <h3 class="gap-2 d-flex justify-content-start align-items-center">
          {{ "AUTO-MOD.AUTOMATIC-PUNISHMENT" | translate }}
          <premium-badge
            class="d-inline-flex justify-content-center align-items-center h-2 px-3 btn btn-bd-premium"
            title="{{ 'DASHBOARD.PREMIUM' | translate }}"
            classvg="me-2"
          ></premium-badge>
        </h3>
        <p class="desc">
          {{ "AUTO-MOD.AUTOMATIC-PUNISHMENT-DESC" | translate }}
        </p>
        <div
          class="alert alert-info rounded-4"
          role="alert"
          style="max-width: max-content"
        >
          <i class="fas fa-info-circle" aria-hidden="true"></i>
          {{ "AUTO-MOD.AUTOMATIC-PUNISHMENT-INFO" | translate }}
        </div>
        <div formArrayName="punishments" class="row gap-4 punishes">
          <div
            *ngFor="let i of maxRules"
            [formGroupName]="i"
            class="d-flex flex-wrap gap-2 col-12 query"
          >
            <strong class="mr-5">#{{ i + 1 }}</strong>
            <label
              >{{ "AUTO-MOD.AUTOMATIC-PUNISHMENT-INFO1" | translate }}
            </label>
            <div style="width: 150px">
              <input type="number" formControlName="warnings" />
            </div>
            <label>
              {{ "AUTO-MOD.AUTOMATIC-PUNISHMENT-INFO2" | translate }}
            </label>
            <div style="width: 150px">
              <input type="number" formControlName="minutes" />
            </div>
            <label>{{
              "AUTO-MOD.AUTOMATIC-PUNISHMENT-INFO3" | translate
            }}</label>
            <select formControlName="type" style="width: 150px">
              <option value="BAN">
                {{ "AUTO-MOD.AUTOMATIC-PUNISHMENT-BAN" | translate }}
              </option>
              <option value="KICK">
                {{ "AUTO-MOD.AUTOMATIC-PUNISHMENT-KICK" | translate }}
              </option>
            </select>
            <label>{{
              "AUTO-MOD.AUTOMATIC-PUNISHMENT-INFO4" | translate
            }}</label>
          </div>
        </div>
      </premium-paywall>
    </form>
  </div>
</guild-sidebar>
