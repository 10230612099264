<nav class="navbar navbar-expand-sm">
  <div class="custom-container">
    <div class="d-flex align-items-center gap-2" routerLink="/">
      <div class="esp-customs d-flex justify-content-center align-items-center">
        <img
        class="z-1"
        loading="auto"
        src="assets/img/esp-customs-cara-transparente.webp"
        alt=""
        width="32"
        height="32"
        style="aspect-ratio: 1/1"
      />
      </div>
      <strong
        class="cursor-pointer ml-2 small-devices-text"
        style="font-family: 'Ginto-Nord'"
        >ESP CUSTOMS</strong
      >
    </div>
    <ul class="navbar-nav align-items-center">
      <li class="nav-item">
        <a
          class="nav-link d-flex gap-2"
          routerLink="/commands"
          routerLinkActive="active"
          >{{ "DASHBOARD.COMMANDS" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link d-flex gap-2"
          routerLink="/docs"
          routerLinkActive="active"
          >{{ "DASHBOARD.DOCUMENTATION" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link d-flex gap-2"
          style="color: #d2af26 !important"
          routerLink="/premium"
          routerLinkActive="active"
          >{{ "DASHBOARD.PREMIUM" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link d-flex gap-2" [href]="environment.discordInvite">
          {{ "DASHBOARD.SUPPORT" | translate }}
        </a>
      </li>
    </ul>
    <div *ngIf="!user" class="row align-items-center">
      <div class="cursor-pointer dropdown">
        <div
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 448 512"
          >
            <path
              d="M224 256a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7 0-98.5-79.8-178.3-178.3-178.3h-91.4z"
              style="fill: var(--font)"
            />
          </svg>
        </div>
        <ul
          class="dropdown-menu dropdown-m-r"
          aria-labelledby="dropdownMenuButton"
        >
          <form #f="ngForm" [formGroup]="form">
            <select
              [value]="translate.currentLang"
              class="nav-item selector-nav"
              formControlName="lang"
              id="language-select"
              name="language-select"
              #langselect
              (change)="translate.use(langselect.value)"
              aria-label="select"
            >
              <option value="es">Español</option>
              <option value="en">English</option>
              <option value="fr">Français</option>
            </select>
          </form>
          <a class="dropdown-item" routerLink="/login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 448 512"
            >
              <path
                d="M224 256a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7 0-98.5-79.8-178.3-178.3-178.3h-91.4z"
                style="fill: var(--font)"
              />
            </svg>
            {{ "DASHBOARD.SESSION" | translate }}
          </a>
        </ul>
      </div>
    </div>
    <div *ngIf="user" class="row align-items-center">
      <div class="col p-2">
        <div class="cursor-pointer dropdown">
          <div
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <img
              [src]="user.displayAvatarURL"
              loading="auto"
              alt="{{ user.name }}"
              width="100%"
              height="100%"
              class="mw-100 w-2 h-2"
              style="aspect-ratio: 1/1"
            />
            <span>{{ user.username }}</span>
          </div>
          <ul
            class="dropdown-menu dropdown-m-r"
            aria-labelledby="dropdownMenuButton"
          >
            <form #f="ngForm" [formGroup]="form">
              <select
                [value]="translate.currentLang"
                class="nav-item selector-nav"
                formControlName="lang"
                id="language-select"
                name="language-select"
                #langselect
                (change)="translate.use(langselect.value)"
                aria-label="select"
              >
                <option value="es">Español</option>
                <option value="en">English</option>
                <option value="fr">Français</option>
              </select>
            </form>
            <a
              class="dropdown-item"
              routerLink="/dashboard"
              routerLinkActive="active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 512 512"
              >
                <path
                  d="M495.9 166.6c3.2 8.7.5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4l-55.6 17.8c-8.8 2.8-18.6.3-24.5-6.8-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4c-1.1-8.4-1.7-16.9-1.7-25.5s.6-17.1 1.7-25.4l-43.3-39.4c-6.9-6.2-9.6-15.9-6.4-24.6 4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2 5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8 8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                  style="fill: var(--font)"
                />
              </svg>
              Dashboard</a
            >
            <a
              *ngIf="user.id === environment.botOwnerId"
              class="dropdown-item"
              routerLink="/stats"
              routerLinkActive="active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 512 512"
              >
                <path
                  d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64v336c0 44.2 35.8 80 80 80h400c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l89.4-89.3 57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"
                  style="fill: var(--font)"
                />
              </svg>
              Estadísticas de bot
            </a>
            <hr class="m-1 small-devices" />
            <a
              class="dropdown-item small-devices"
              routerLink="/commands"
              routerLinkActive="active"
            >
              <span>{{ "DASHBOARD.COMMANDS" | translate }}</span>
            </a>
            <a
              class="dropdown-item small-devices"
              routerLink="/docs"
              routerLinkActive="active"
            >
              <span>{{ "DASHBOARD.DOCUMENTATION" | translate }}</span>
            </a>
            <a
              class="dropdown-item small-devices"
              routerLink="/premium"
              routerLinkActive="active"
            >
              <span style="color: #d2af26 !important">{{
                "DASHBOARD.PREMIUM" | translate
              }}</span>
            </a>
            <hr class="m-1" />
            <a class="dropdown-item" routerLink="/logout">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 512 512"
              >
                <path
                  d="m377.9 105.9 122.8 122.8c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9-18.7 0-33.9-15.2-33.9-33.9V320H192c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32h128v-62.1c0-18.7 15.2-33.9 33.9-33.9 9 0 17.6 3.6 24 9.9zM160 96H96c-17.7 0-32 14.3-32 32v256c0 17.7 14.3 32 32 32h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H96c-53 0-96-43-96-96V128c0-53 43-96 96-96h64c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                  style="fill: var(--font)"
                />
              </svg>
              {{ "DASHBOARD.LOGOUT" | translate }}</a
            >
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
