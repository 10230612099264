<navbar></navbar>
<header class="container text-center pb-2">
  <h1 class="fw-bold">{{ "COMMANDS.TITLE" | translate }}</h1>
  <p class="lead">{{ "COMMANDS.LEAD" | translate }}</p>
</header>
<section class="container px-lg-5">
  <div
    class="d-flex justify-content-center align-items-center rounded-2 gap-2 p-2"
    style="background-color: var(--background-tertiary)"
  >
    <div class="px-2 py-1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24">
        <path
          d="M416 208c0 45.9-14.9 88.3-40 122.7l126.6 126.7c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z "
          style="fill: var(--font)"
        />
      </svg>
    </div>
    <input
      type="text"
      (keypress)="search(searchInput.value)"
      [placeholder]="'COMMANDS.SEARCH' | translate"
      #searchInput
    />
    <span
      class="px-2 py-1 rounded-2"
      style="background-color: var(--background-secondary)"
      >/</span
    >
  </div>

  <div class="row">
    <div class="col-sm-3">
      <ul class="list-group categories">
        <li
          *ngFor="let mod of modules"
          (click)="setModule(mod.name)"
          class="list-group-item my-1 rounded cursor-pointer"
          [class.active]="mod.name === selectedModule"
        >
          <span
            class="fas mr-1 text-warning"
            aria-hidden="true"
          >{{mod.icon}}</span>
          <span> {{ mod.name | camelToSentenceCase }}</span>
        </li>

        <div *ngIf="guild">
          <p class="category fw-bold text-uppercase">{{ guild.name }}</p>

          <li
            (click)="setModule('custom')"
            class="list-group-item my-1 rounded cursor-pointer"
            [class.active]="selectedModule === 'custom'"
          >
            <i class="fas fa-cogs mr-1 text-warning" aria-hidden="true"></i>
            <span>{{ "COMMANDS.CUSTOM" | translate }}</span>
          </li>
        </div>
      </ul>
    </div>
    <div class="col-sm-9">
      <ul class="commands">
        <li *ngFor="let command of displayedCommands" class="my-1 rounded">
          <zippy [title]="command.data.name">
            <p *ngIf="command.usage" class="m-0">
              <strong>{{ "COMMANDS.USAGE" | translate }}</strong
              >: <code>{{ command.usage | snakeToSentenceCase }}</code>
            </p>
            <p *ngIf="command.aliases" class="m-0">
              <strong>{{ "COMMANDS.ALIAS" | translate }}</strong
              >: <code>{{ command.aliases }}</code>
            </p>
            <p class="m-0">
              <strong>{{ "COMMANDS.RESUME" | translate }}</strong
              >: {{ command.data.description | snakeToSentenceCase }}
            </p>
            <p *ngIf="command.precondition" class="m-0">
              <strong>{{ "COMMANDS.PERMISIONS" | translate }}</strong
              >: <code>{{ command.precondition | snakeToSentenceCase }}</code>
            </p>
            <p *ngIf="command.anywhere" class="m-0">
              <strong>{{ "COMMANDS.EXECUTE" | translate }}</strong
              >: <code>{{ command.anywhere }}</code>
            </p>
          </zippy>
        </li>
      </ul>
      <div *ngIf="displayedCommands.length <= 0" class="pl-5">
        {{ "COMMANDS.NOT-COMMANDS" | translate }}
        <i class="fas fa-sad-cry" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</section>
