<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <h1 class="fw-bold">{{ "SETTINGS.TITLE" | translate }}</h1>
    <hr />
    <form #f="ngForm" [formGroup]="form">
      <div class="row">
        <div class="d-flex align-items-center">
          <label class="mx-2">{{ "SETTINGS.TABLE" | translate }}</label>
          <input type="checkbox" formControlName="privateLeaderboard" />
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
