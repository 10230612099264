<guild-sidebar [waitFor]="savedGuild && reactionRoles && reactionRoleMessages">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <h1 routerLink="/docs/reaction-roles" class="fw-bold">
            {{ "ROLEREACTION.TITLE" | translate }}
          </h1>
          <div class="slide-toggle">
            <input
              type="checkbox"
              id="check"
              class="slide-toggle-input"
              aria-checked="true"
              formControlName="enabled"
            />
            <label for="check" class="slide-toggle-label"></label>
          </div>
        </div>
        <hr />
      </div>
      <div formArrayName="configs" class="row g-3">
        <div *ngFor="let i of reactionRolesIndices" class="col-lg-6 col-md-12">
          <div
            [formGroupName]="i"
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <div class="p-2">
              <label>{{ "ROLEREACTION.CHANNEL" | translate }}</label>
              <select formControlName="channel">
                <option
                  *ngFor="let channel of textChannels"
                  [value]="channel.id"
                >
                  #{{ channel.name }}
                  <span class="float-right text-muted"
                    >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
                  >
                </option>
              </select>
            </div>
            <!-- message -->
            <div class="p-2">
              <label>{{ "ROLEREACTION.IDMESSAGE" | translate }}</label>
              <input type="text" formControlName="messageId" />
            </div>
            <!-- emote -->
            <div class="p-2">
              <label>{{ "ROLEREACTION.EMOTE" | translate }}</label>
              <input type="text" #emote formControlName="emote" />
            </div>
            <!-- role -->
            <div class="p-2">
              <label>{{ "ROLEREACTION.ROL" | translate }}</label>
              <select formControlName="role">
                <option *ngFor="let role of roles" value="role.id">
                  <span [style.color]="'#' + role.color?.toString(16)"
                    >&#64;{{ role.name }}</span
                  >
                </option>
              </select>
            </div>
            <div>
              <div *ngIf="reactionRoleMessages[i]">
                <message-preview
                  [author]="reactionRoleMessages[i].author"
                  [createdAt]="toDate(reactionRoleMessages[i].createdTimestamp)"
                  [content]="
                    reactionRoleMessages[i].reactions.message.cleanContent
                  "
                  [member]="reactionRoleMessages[i].member"
                ></message-preview>
              </div>
              <div *ngIf="!reactionRoleMessages[i]">
                <message-preview
                  content="Mensaje no encontrado."
                ></message-preview>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
