<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="fw-bold">{{ "GUILD-TICKET.TITLE" | translate }}</h4>
        <div class="slide-toggle">
          <input
            type="checkbox"
            id="check"
            class="slide-toggle-input"
            aria-checked="true"
            formControlName="enabled"
          />
          <label for="check" class="slide-toggle-label"></label>
        </div>
      </div>
      <hr />
      <div class="alert alert-info rounded-4" role="alert">
        {{ "GUILD-TICKET.ALERT-INFO" | translate }}
        <hr />
        {{ "GUILD-TICKET.ALERT-INFO-1" | translate }} <br />
      </div>
      <div
        class="row g-3 rounded p-3"
        style="background-color: var(--background-secondary)"
      >
        <!-- role -->
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-MOD" | translate }}</label>
          <select formControlName="moderatorRole">
            <option *ngFor="let role of roles" [value]="role.id">
              <span [style.color]="'#' + role.color?.toString(16)"
                >&#64;{{ role.name }}</span
              >
            </option>
          </select>
        </div>
        <!-- category -->
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-CATEGORY" | translate }}</label>
          <select formControlName="categoryId">
            <option *ngFor="let category of catChannels" [value]="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
        <!-- closed -->
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-CLOSED" | translate }}</label>
          <select formControlName="closedCategory">
            <option *ngFor="let category of catChannels" [value]="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
        <!-- archive -->
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-ARCHIVE" | translate }}</label>
          <select formControlName="archiveCategory">
            <option *ngFor="let category of catChannels" [value]="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
        <!-- claimed -->
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-CLAIMED" | translate }}</label>
          <select formControlName="claimedCategory">
            <option *ngFor="let category of catChannels" [value]="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
        <!-- channel -->
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-CHANNEL" | translate }}</label>
          <select formControlName="channelTicketEmbed">
            <option *ngFor="let channel of textChannels" [value]="channel.id">
              #{{ channel.name }}
              <span class="float-right text-muted"
                >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
              >
            </option>
          </select>
        </div>
      </div>
      <!-- Ticket Simple -->
      <div
        class="row g-3 rounded p-3"
        style="background-color: var(--background-secondary)"
      >
        <h5 class="fw-bold">{{ "GUILD-TICKET.SINGLE.TITLE" | translate }}</h5>
        <!-- table -->
        <table
          formArrayName="ticketMainEmbed"
          class="table table-striped table-hover table-sm"
        >
          <thead>
            <tr>
              <th>{{ "GUILD-TICKET.MAT-TITLE" | translate }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let ticket of form.get('ticketMainEmbed')?.value;
                let i = index
              "
              [formGroupName]="i"
            >
              <td>{{ ticket.title }}</td>
              <td>
                <button
                  class="btn btn-danger-default"
                  (click)="removeTicketMainEmbed(i)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#FF6B86"
                      d="M3 6.283a.75.75 0 0 0 0 1.5v-1.5zm18 1.5a.75.75 0 0 0 0-1.5v1.5zm-16-.75v-.75h-.75v.75H5zm14 0h.75v-.75H19v.75zm-.658 9.797.72.208-.72-.208zm-4.618 4.025.125.74-.125-.74zm-3.448 0 .125-.74-.125.74zm-.158-.026-.125.74.125-.74zm-4.46-3.999-.72.208.72-.208zm8.224 3.999-.125-.74.125.74zm-6.04-15.34.681.315-.68-.315zm.976-1.308-.5-.558.5.558zm1.46-.874.26.703-.26-.703zm3.444 0 .261-.703-.26.703zm2.435 2.182.681-.314-.68.314zM3 7.783h18v-1.5H3v1.5zm10.757 12.306-.158.027.25 1.479.158-.027-.25-1.479zm-3.356.027-.158-.027-.25 1.48.158.026.25-1.48zM18.25 7.033v5.143h1.5V7.033h-1.5zm-12.5 5.143V7.033h-1.5v5.143h1.5zm12.5 0c0 1.505-.212 3.002-.629 4.446l1.441.416c.456-1.58.688-3.217.688-4.862h-1.5zm-4.651 7.94a9.595 9.595 0 0 1-3.198 0l-.25 1.479c1.224.207 2.474.207 3.698 0l-.25-1.48zm-3.356-.027a4.877 4.877 0 0 1-3.864-3.467l-1.441.416a6.377 6.377 0 0 0 5.055 4.53l.25-1.479zM6.38 16.622a16.033 16.033 0 0 1-.629-4.446h-1.5c0 1.645.231 3.282.688 4.862l1.44-.416zm7.628 4.946a6.377 6.377 0 0 0 5.055-4.53l-1.44-.416a4.877 4.877 0 0 1-3.865 3.467l.25 1.48zM8.25 7.033c0-.42.092-.837.273-1.229l-1.361-.63a4.422 4.422 0 0 0-.412 1.859h1.5zm.273-1.229c.182-.393.45-.755.796-1.064L8.317 3.623c-.49.44-.884.966-1.155 1.552l1.361.63zM9.32 4.74a3.8 3.8 0 0 1 1.22-.73l-.522-1.406a5.323 5.323 0 0 0-1.7 1.019L9.32 4.74zm1.22-.73a4.21 4.21 0 0 1 1.461-.26v-1.5c-.679 0-1.352.12-1.983.354l.522 1.406zM12 3.75c.503 0 1 .089 1.461.26l.522-1.406A5.707 5.707 0 0 0 12 2.25v1.5zm1.461.26a3.8 3.8 0 0 1 1.22.73l1.002-1.117a5.317 5.317 0 0 0-1.7-1.02l-.522 1.407zm1.22.73c.345.309.614.671.796 1.064l1.361-.63a4.784 4.784 0 0 0-1.156-1.551l-1 1.117zm.796 1.064c.181.392.273.81.273 1.229h1.5c0-.64-.14-1.272-.412-1.858l-1.361.63zM5 7.783h14v-1.5H5v1.5z"
                      data-fill="main"
                    />
                    <path
                      stroke="#FF6B86"
                      stroke-linecap="round"
                      stroke-width="1.5"
                      d="M10 12v4m4-4v4"
                      data-stroke="main"
                    />
                  </svg>
                </button>
              </td>
              <td>
                <button
                  #ticketButton
                  class="btn btn-bd-primary"
                  (click)="
                    apiService.sendTicket(guild.id, [
                      ticket.title,
                      ticket.description,
                      ticket.thumbnail,
                      ticket.button.label,
                      ticket.button.style
                    ])
                  "
                  [disabled]="!form.get('channelTicketEmbed').value"
                >
                  {{ "GUILD-TICKET.MAT-BUTTON" | translate }}
                </button>
                <p
                  *ngIf="!form.get('channelTicketEmbed').value"
                  class="text-danger"
                >
                  {{ "Por favor, selecciona un canal" | translate }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-lg-4 col-sm-6" formGroupName="ticketMainEmbed">
          <label>{{ "GUILD-TICKET.MAT-TITLE" | translate }}</label>
          <textarea
            formControlName="title"
            placeholder="Sistema de ticket ..."
            #ticketMainEmbedTitle
            maxlength="256"
          ></textarea>
          <hint align="end">{{ ticketMainEmbedTitle.value.length }} / 256</hint>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-DESC" | translate }}</label>
          <textarea
            formControlName="description"
            placeholder="Haga clic en ✉️ ​​para ..."
            #ticketMainEmbedDesc
            maxlength="4096"
          ></textarea>
          <hint align="end">{{ ticketMainEmbedDesc.value.length }} / 4096</hint>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-THUMNAIL" | translate }}</label>
          <textarea
            formControlName="thumbnail"
            placeholder="https://i.imgur.com/rdvO6lD.png"
            #ticketMainEmbedThumbnail
          ></textarea>
        </div>
        <hr />
        <h5 class="fw-bold">Button Ticket</h5>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-BUTTON-LABEL" | translate }}</label>
          <textarea placeholder="✉️ Ticket" #ticketMainEmbedLabel></textarea>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-BUTTON-STYLE" | translate }}</label>
          <select #ticketMainEmbedStyle>
            <option value="PRIMARY" selected>
              {{ "GUILD-TICKET.MAT-BUTTON-BLUE" | translate }}
            </option>
            <option value="SECONDARY">
              {{ "GUILD-TICKET.MAT-BUTTON-GREY" | translate }}
            </option>
            <option value="SUCCESS">
              {{ "GUILD-TICKET.MAT-BUTTON-GREEN" | translate }}
            </option>
            <option value="DANGER">
              {{ "GUILD-TICKET.MAT-BUTTON-RED" | translate }}
            </option>
          </select>
        </div>
        <hr />
        <h5 class="fw-bold">{{ "GUILD-TICKET.SUBTICKET" | translate }}</h5>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-TITLE" | translate }}</label>
          <textarea
            placeholder="Sistema de ticket ..."
            #ticketSupportEmbedTitle
          ></textarea>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-DESC" | translate }}</label>
          <textarea
            placeholder="El apoyo estará contigo ..."
            #ticketSupportEmbedDesc
          ></textarea>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-THUMNAIL" | translate }}</label>
          <textarea
            placeholder="https://i.imgur.com/rdvO6lD.png"
            #ticketSupportEmbedThumbnail
          ></textarea>
        </div>
        <embed-preview
          [title]="ticketMainEmbedTitle.value"
          [thumbnail]="ticketMainEmbedThumbnail.value"
          [content]="ticketMainEmbedDesc.value"
          [button]="{
            label: ticketMainEmbedLabel.value,
            customId: 'ticket',
            style: ticketMainEmbedStyle.value,
            display: true
          }"
          [guild]="guild"
        >
        </embed-preview>
        <button
          class="btn btn-bd-primary mt-3"
          style="max-width: max-content"
          (click)="
            addNewTicketMainEmbed(
              ticketMainEmbedTitle.value,
              ticketMainEmbedDesc.value,
              ticketMainEmbedThumbnail.value,
              ticketMainEmbedLabel.value,
              ticketMainEmbedStyle.value,
              ticketSupportEmbedTitle.value,
              ticketSupportEmbedDesc.value,
              ticketSupportEmbedThumbnail.value
            )
          "
        >
          {{ "GUILD-TICKET.ADD" | translate }}
        </button>
      </div>
      <!-- Multi Ticket -->
      <div
        class="row g-3 rounded p-3"
        style="background-color: var(--background-secondary)"
      >
        <h5 class="fw-bold">{{ "GUILD-TICKET.MULTIPLE.TITLE" | translate }}</h5>
        <!-- table -->
        <table
          formArrayName="multipleTickets"
          class="table table-striped table-hover table-sm"
        >
          <thead>
            <tr>
              <th>{{ "GUILD-TICKET.MAT-TITLE" | translate }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody
            *ngFor="
              let ticket of form.get('multipleTickets')?.value;
              let i = index
            "
          >
            <tr [formGroupName]="i">
              <td>{{ ticket.title }}</td>
              <td>
                <button
                  class="btn btn-danger-default"
                  (click)="removeMultipleTickets(i)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#FF6B86"
                      d="M3 6.283a.75.75 0 0 0 0 1.5v-1.5zm18 1.5a.75.75 0 0 0 0-1.5v1.5zm-16-.75v-.75h-.75v.75H5zm14 0h.75v-.75H19v.75zm-.658 9.797.72.208-.72-.208zm-4.618 4.025.125.74-.125-.74zm-3.448 0 .125-.74-.125.74zm-.158-.026-.125.74.125-.74zm-4.46-3.999-.72.208.72-.208zm8.224 3.999-.125-.74.125.74zm-6.04-15.34.681.315-.68-.315zm.976-1.308-.5-.558.5.558zm1.46-.874.26.703-.26-.703zm3.444 0 .261-.703-.26.703zm2.435 2.182.681-.314-.68.314zM3 7.783h18v-1.5H3v1.5zm10.757 12.306-.158.027.25 1.479.158-.027-.25-1.479zm-3.356.027-.158-.027-.25 1.48.158.026.25-1.48zM18.25 7.033v5.143h1.5V7.033h-1.5zm-12.5 5.143V7.033h-1.5v5.143h1.5zm12.5 0c0 1.505-.212 3.002-.629 4.446l1.441.416c.456-1.58.688-3.217.688-4.862h-1.5zm-4.651 7.94a9.595 9.595 0 0 1-3.198 0l-.25 1.479c1.224.207 2.474.207 3.698 0l-.25-1.48zm-3.356-.027a4.877 4.877 0 0 1-3.864-3.467l-1.441.416a6.377 6.377 0 0 0 5.055 4.53l.25-1.479zM6.38 16.622a16.033 16.033 0 0 1-.629-4.446h-1.5c0 1.645.231 3.282.688 4.862l1.44-.416zm7.628 4.946a6.377 6.377 0 0 0 5.055-4.53l-1.44-.416a4.877 4.877 0 0 1-3.865 3.467l.25 1.48zM8.25 7.033c0-.42.092-.837.273-1.229l-1.361-.63a4.422 4.422 0 0 0-.412 1.859h1.5zm.273-1.229c.182-.393.45-.755.796-1.064L8.317 3.623c-.49.44-.884.966-1.155 1.552l1.361.63zM9.32 4.74a3.8 3.8 0 0 1 1.22-.73l-.522-1.406a5.323 5.323 0 0 0-1.7 1.019L9.32 4.74zm1.22-.73a4.21 4.21 0 0 1 1.461-.26v-1.5c-.679 0-1.352.12-1.983.354l.522 1.406zM12 3.75c.503 0 1 .089 1.461.26l.522-1.406A5.707 5.707 0 0 0 12 2.25v1.5zm1.461.26a3.8 3.8 0 0 1 1.22.73l1.002-1.117a5.317 5.317 0 0 0-1.7-1.02l-.522 1.407zm1.22.73c.345.309.614.671.796 1.064l1.361-.63a4.784 4.784 0 0 0-1.156-1.551l-1 1.117zm.796 1.064c.181.392.273.81.273 1.229h1.5c0-.64-.14-1.272-.412-1.858l-1.361.63zM5 7.783h14v-1.5H5v1.5z"
                      data-fill="main"
                    />
                    <path
                      stroke="#FF6B86"
                      stroke-linecap="round"
                      stroke-width="1.5"
                      d="M10 12v4m4-4v4"
                      data-stroke="main"
                    />
                  </svg>
                </button>
              </td>
              <td>
                <button
                  #ticketButton
                  class="btn btn-bd-primary"
                  (click)="sendMultiTicket(ticket)"
                  [disabled]="!form.get('channelTicketEmbed').value"
                >
                  {{ "GUILD-TICKET.MAT-BUTTON" | translate }}
                </button>
                <p
                  *ngIf="!form.get('channelTicketEmbed').value"
                  class="text-danger"
                >
                  {{ "Por favor, selecciona un canal" | translate }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-TITLE" | translate }}</label>
          <textarea
            placeholder="Sistema de ticket ..."
            #multipleTicketsTitle
            maxlength="256"
          ></textarea>
          <hint align="end">{{ multipleTicketsTitle.value.length }} / 256</hint>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-DESC" | translate }}</label>
          <textarea
            placeholder="Haga clic en ✉️ ​​para ..."
            #multipleTicketsDesc
            maxlength="4096"
          ></textarea>
          <hint align="end">{{ multipleTicketsDesc.value.length }} / 4096</hint>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-THUMNAIL" | translate }}</label>
          <textarea
            placeholder="https://i.imgur.com/rdvO6lD.png"
            #multipleTicketsThumbnail
          ></textarea>
        </div>
        <div formArrayName="ticketMainEmbed" class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-TICKET.MAT-TICKETS" | translate }}</label>
          <mat-select
            multiple
            #multipleTicketsbuttons
            (changes)="(multipleTicketsbuttons)"
          >
            <mat-option *ngFor="let title of ticketTitles" [value]="title">{{
              title
            }}</mat-option>
          </mat-select>
        </div>
        <button
          class="btn btn-bd-primary mt-3"
          style="max-width: max-content"
          (click)="
            addNewMultipleTickets(
              multipleTicketsTitle.value,
              multipleTicketsDesc.value,
              multipleTicketsThumbnail.value,
              getSelectedButtons(multipleTicketsbuttons)
            )
          "
        >
          {{ "GUILD-TICKET.ADD" | translate }}
        </button>
      </div>
    </form>
  </div>
</guild-sidebar>
