import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SaveChangesComponent } from './utils/discord/save-changes/save-changes.component';
import { GuildService } from './services/guild.service';
import { OnDestroy, Directive } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subscription } from 'rxjs';
import { UserService } from './services/user.service';

// TODO: Añadir decorador angular.
@Directive()
export abstract class ModuleConfig implements OnDestroy {
  abstract moduleName: string;

  form: FormGroup;

  private _savedGuild: any;
  public get savedGuild(): any {
    return this._savedGuild;
  }
  public set savedGuild(value: any) {
    this._savedGuild = value;
  }
  guild: any;
  originalSavedGuild: any;

  catChannels: any = [];
  channels: any = [];
  textChannels: any = [];
  roles: any = [];

  get guildId() {
    return this.route.snapshot.paramMap.get("id");
  }

  private saveChanges$: Subscription;
  private valueChanges$: Subscription;

  constructor(
    protected guildService: GuildService,
    protected route: ActivatedRoute,
    protected userService: UserService,
    public saveChanges: MatSnackBar
  ) { }

  /**
   * Cargue todos los datos necesarios para el formulario y enganche los eventos.
   */
  async init() {
    const data = this.route.snapshot.data;

    this.guild = this.guildService.guilds.find(
      (guild) => guild.id === this.guildId
    );

    this.roles = data['roles'];
    this.channels = data['channels'];
    this.textChannels = data['channels'].filter((c) => c.type === 0);
    this.catChannels = data['channels'].filter((c) => c.type === 4);

    this.savedGuild = data['savedGuild'];
    this.originalSavedGuild = JSON.parse(JSON.stringify(this.savedGuild));

    await this.resetForm();

    this.valueChanges$ = this.form.valueChanges.subscribe(() =>
      this.openSaveChanges()
    );
  }

  private async resetForm() {
    this.savedGuild = JSON.parse(JSON.stringify(this.originalSavedGuild));
    this.form = await this.buildForm(this.savedGuild);
    this.form.addControl(
      "enabled",
      new FormControl(this.savedGuild[this.moduleName]?.enabled)
    );
  }

  /**
   * Cree el formulario que se utilizará.
   * Se llama cuando está en el formulario init.
   */
  abstract buildForm(savedGuild: any): FormGroup | Promise<FormGroup>;

  private openSaveChanges() {
    const snackBarRef = this.saveChanges._openedSnackBarRef;
    if (!this.form.valid || snackBarRef) return;

    this.saveChanges$ = this.saveChanges
      .openFromComponent(SaveChangesComponent)
      .afterOpened()
      .subscribe(() => {
        const component = this.saveChanges._openedSnackBarRef
          .instance as SaveChangesComponent;
        component.onSave.subscribe(async () => await this.submit());
        component.onReset.subscribe(async () => await this.reset());
      });
  }

  /**
   * Limpiar suscripciones: para evitar pérdidas de memoria.
   */
  ngOnDestroy() {
    this.valueChanges$?.unsubscribe();
    this.saveChanges$?.unsubscribe();
  }

  /**
   * Envíe los datos del formulario a la API.
   */
  async submit() {
    try {
      if (this.form.invalid) return;
      await this.guildService.saveGuild(
        this.guildId,
        this.moduleName,
        this.form.value
      );
      this.guildService.singleton.savedGuild[this.moduleName] = this.form.value;
    } catch {
      alert("Ocurrió un error al enviar el formulario - verifique la consola");
    }
  }

  /**
   * Restablezca los valores del formulario y reconstruya el formulario.
   */
  async reset() {
    await this.resetForm();
    this.savedGuild = JSON.parse(JSON.stringify(this.originalSavedGuild));

    this.form.valueChanges.subscribe(() => this.openSaveChanges());
  }

  // eventos de entrada

  add(event: MatChipInputEvent, array: any[]) {
    const { value, input } = event;

    if ((value || "").trim()) array.push(value.trim());

    if (input) input.value = "";

    this.openSaveChanges();
  }

  remove(item: any, array: any[]) {
    const index = array.indexOf(item);
    if (index >= 0) array.splice(index, 1);

    this.openSaveChanges();
  }

  getChannel(id: string) {
    return this.channels.find((c) => c.id === id);
  }

  getcatChannel(id: string) {
    return this.catChannels.find((c) => c.id === id);
  }
}