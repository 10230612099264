<div
  class="d-flex flex-wrap justify-content-around p-4 gap-2"
  style="background-color: var(--background-tertiary)"
>
  <div style="max-width: 80rem; width: 100%">
    <div class="mt-6 px-4 py-6">
      <div class="d-flex flex-wrap justify-content-between">
        <ul>
          <li>
            <h2 style="color: var(--bottom)">
              {{ "HOME.FOOTER.POLITYC.TITLE" | translate }}
            </h2>
          </li>
          <li>
            <a
              class="text-decoration-none"
              routerLink="/privacy-policy"
              routerLinkActive="active"
            >
              <span style="color: var(--muted)">{{
                "HOME.FOOTER.POLITYC.PRIVACY" | translate
              }}</span>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <h2 style="color: var(--bottom)">LINKS</h2>
          </li>
          <li>
            <a
              class="text-decoration-none"
              routerLink="/commands"
              routerLinkActive="active"
            >
              <span style="color: var(--muted)">{{
                "DASHBOARD.COMMANDS" | translate
              }}</span>
            </a>
          </li>
          <li>
            <a
              class="text-decoration-none"
              routerLink="/premium"
              routerLinkActive="active"
            >
              <span style="color: #d2af26 !important">{{
                "DASHBOARD.PREMIUM" | translate
              }}</span>
            </a>
          </li>
          <li>
            <a class="text-decoration-none" [href]="statusURL">
              <span style="color: var(--muted)">Status</span>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <h2 style="color: var(--bottom)">ESP CUSTOMS</h2>
          </li>
          <li>
            <a
              class="text-decoration-none"
              routerLink="/docs/changelog"
              routerLinkActive="active"
              [fragment]="anchorVersion"
            >
              <span style="color: var(--muted)">{{ version }}</span>
            </a>
          </li>
          <li>
            <a class="text-decoration-none" [href]="environment.discordInvite">
              <span style="color: var(--muted)">{{
                "DASHBOARD.SUPPORT" | translate
              }}</span>
            </a>
          </li>
          <li>
            <a
              class="text-decoration-none"
              routerLink="/docs"
              routerLinkActive="active"
            >
              <span style="color: var(--muted)">{{
                "DASHBOARD.DOCUMENTATION" | translate
              }}</span>
            </a>
          </li>
        </ul>
      </div>
      <hr />
      <div class="d-flex justify-content-center">
        <ul>
          <li>
            <span style="color: var(--muted)"
              >Copyright &copy; 2020 - {{ CurrentYear }} ESP CUSTOMS</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
