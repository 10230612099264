<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div class="d-flex align-items-center justify-content-between">
        <h4 routerLink="/docs/general" class="fw-bold">
          {{ "GUILD-GENERAL.TITLE" | translate }}
        </h4>
        <div class="slide-toggle">
          <input
            type="checkbox"
            id="check"
            class="slide-toggle-input"
            aria-checked="true"
            formControlName="enabled"
          />
          <label for="check" class="slide-toggle-label"></label>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div appearance="outline">
            <label>{{ "GUILD-GENERAL.MAT-PREFIX" | translate }}</label>
            <input type="text" formControlName="prefix" />
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <label>{{ "GUILD-GENERAL.MAT-IGNORECHANNELS" | translate }}</label>
          <mat-select multiple formControlName="ignoredChannels">
            <mat-option
              *ngFor="let channel of textChannels"
              [value]="channel.id"
              >#{{ channel.name }}
              <span class="float-right text-muted"
                >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
              >
            </mat-option>
          </mat-select>
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
