import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy-policy/privacy-policy.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CommandsComponent } from './pages/commands/commands.component';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { InviteComponent } from './pages/auth/invite/invite.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { DashboardOverviewComponent } from './dashboard/dashboard/dashboard-overview/dashboard-overview.component';
import { GuildComponent } from './dashboard/guild/guild/guild.component';
import { DashboardAuthGuard } from './guards/dashboard-auth.guard';
import { GuildAuthGuard } from './guards/guild-auth.guard';
import { GeneralModuleComponent } from './dashboard/guild/general-module/general-module.component';
import { CustomizeTciketsComponent } from './dashboard/guild/customize-ticket/customize-ticket.component';
import { FortniteCustomComponent } from './dashboard/guild/fortnite-custom/fortnite-custom.component';
import { LevelingModuleComponent } from './dashboard/guild/leveling-module/leveling-module.component';
import { AutoModModuleComponent } from './dashboard/guild/auto-mod-module/auto-mod-module.component';
import { LogsModuleComponent } from './dashboard/guild/logs-module/logs-module.component';
import { LogModuleComponent } from './dashboard/guild/log-module/log-module.component';
import { SettingsModuleComponent } from './dashboard/guild/settings-module/settings-module.component';
import { CommandsModuleComponent } from './dashboard/guild/commands-module/commands-module.component';
import { LeaderboardModuleComponent } from './dashboard/guild/leaderboard-module/leaderboard-module.component';
import { LeaderboardAuthGuard } from './guards/leaderboard-auth.guard';
import { XPCardComponent } from './xp-card/xp-card.component';
import { WelcomeModuleComponent } from "./dashboard/guild/welcome-module/welcome-module.component";
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PremiumComponent } from './pages/premium/premium/premium.component';
import { CanDeactivateDashboard } from './guards/can-deactivate-dashboard.guard';
import { ReactionRolesModuleComponent } from './dashboard/guild/reaction-roles-module/reaction-roles-module.component';
import { StatsComponent } from './stats/stats.component';
import { DocsComponent } from './pages/docs/docs.component';
import { TwitchModuleComponent } from './dashboard/guild/social-module/twitch-module/twitch-module.component';
import { YoutubeModuleComponent } from './dashboard/guild/social-module/youtube-module/youtube-module.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "commands", component: CommandsComponent },
  { path: "auth", component: AuthComponent },
  { path: "docs", component: DocsComponent },
  { path: "docs/:page", component: DocsComponent },
  { path: "privacy-policy", component: PrivacyComponent },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "stats", component: StatsComponent },
  { path: "invite", component: InviteComponent },

  {
    path: "leaderboard/:id",
    component: LeaderboardModuleComponent,
    canActivate: [LeaderboardAuthGuard],
  },

  {
    path: "dashboard",
    component: DashboardOverviewComponent,
    canActivate: [DashboardAuthGuard],
  },
  {
    path: "dashboard/xp-card",
    component: XPCardComponent,
    canActivate: [DashboardAuthGuard],
  },

  {
    path: "servers/:id",
    component: GuildComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/auto-mod",
    component: AutoModModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/welcome",
    component: WelcomeModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/commands",
    component: CommandsModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/general",
    component: GeneralModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/customize-ticket",
    component: CustomizeTciketsComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/fortnite-custom",
    component: FortniteCustomComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/twitch",
    component: TwitchModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/youtube",
    component: YoutubeModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/leveling",
    component: LevelingModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/log",
    component: LogModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/logs",
    component: LogsModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/reaction-roles",
    component: ReactionRolesModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },
  {
    path: "servers/:id/settings",
    component: SettingsModuleComponent,
    canActivate: [GuildAuthGuard],
    canDeactivate: [CanDeactivateDashboard],
  },

  { path: "premium", component: PremiumComponent },
  { path: "payment-success", component: PaymentSuccessComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: "enabledNonBlocking" }),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppRoutingModule { }
