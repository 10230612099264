<sidebar>
  <div class="side-nav navbar-dark">
    <div class="header-profile rounded-4">
      <img
        width="100%"
        height="100%"
        class="rounded-4"
        [src]="bannerURL"
        loading="lazy"
        [alt]="user.username"
      />
      <img
        width="100%"
        height="100%"
        class="profile"
        [src]="user.displayAvatarURL"
        loading="lazy"
        [alt]="user.username"
      />
      <h4 class="p-2" routerLink="/dashboard">{{ user.tag }}</h4>
    </div>
    <div class="navbar-nav nav-pills px-2">
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "DASHBOARD.USER" | translate }}
        </div>
        <a
          routerLink="/dashboard/xp-card"
          routerLinkActive="active"
          class="nav-item nav-link px-2"
        >
          {{ "DASHBOARD.XPCARD" | translate }}</a
        >
      </div>
      <hr />
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "DASHBOARD.THEMES" | translate }}
        </div>
        <form #f="ngForm" class="px-2">
          <select
            [value]="service.currentTheme"
            class="nav-item"
            [formControl]="themeControl"
            id="theme-select"
            name="theme-select"
            #themeSelect
            aria-label="select"
          >
            <option value="ESSENTIALS">Essentials</option>
            <option value="COSMIC">Cosmic</option>
            <option value="GITHUB">Github</option>
            <option value="DISCORD" selected>
              Discord {{ "DASHBOARD.DEFAULT" | translate }}
            </option>
            <option value="WHITE">White</option>
            <option value="TWILIGHT">Twilight</option>
          </select>
        </form>
      </div>
    </div>
  </div>
  <div id="content">
    <ng-content></ng-content>
  </div>
</sidebar>
