import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ThemeService } from '../../../services/theme.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.css']
})
export class DashboardSidebarComponent implements OnInit {
  @ViewChild('themeSelect') themeSelect: string;
  @ViewChild('langSelect') langSelect: string;

  defaultTheme = 'DISCORD';

  themeControl = new FormControl(localStorage.getItem('theme') ?? this.defaultTheme);

  bannerURL: string;
  
  get user() { return this.userService.user ?? {}; }

  constructor(public translate: TranslateService,
    public service: ThemeService,
    private userService: UserService
  ) {
    document.title = 'ESP CUSTOMS - Dashboard';
    this.service.updateTheme();
  }
  async ngOnInit() {
    this.themeControl.valueChanges.subscribe(theme => {
      this.service.changeTheme(theme);
    });

    this.bannerURL = `https://i.imgur.com/Q5hLYaW.gif`;
  }
}