<navbar></navbar>

<div class="container jumbrotron text-center">
  <h1 class="fw-bold">Estadísticas</h1>
  <p class="lead">
    Ver las estadísticas de ESP CUSTOMS, actualizadas cada 30 minutos.
  </p>
</div>

<section *ngIf="stats" class="container">
  <div class="row align-items-center">
    <div class="col-lg-3 col-sm-6">
      <div
        class="d-flex align-items-center justify-content-center gap-3 stats p-3 m-3 rounded-4 cursor-pointer tooltip"
      >
        <span class="tooltiptext">Los comandos totales ejecutados.</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 640 512"
        >
          <path
            d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3l89.3 89.4-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"
            style="fill: var(--font)"
          />
        </svg>
        Ejecuta {{ stats.general.commandsExecuted | number }} Comandos
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div
        class="d-flex align-items-center justify-content-center gap-3 stats p-3 m-3 rounded-4 cursor-pointer tooltip"
      >
        <span class="tooltiptext"
          >Se actualizaron las propiedades de configuración de gremio
          personalizables totales.</span
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 448 512"
        >
          <path
            d="M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7c-12-12-28.3-18.7-45.3-18.7H64zm0 96c0-17.7 14.3-32 32-32h192c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32v-64zm160 160a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
            style="fill: var(--font)"
          />
        </svg>
        {{ stats.general.inputsChanged | number }} Entradas actualizadas
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div
        class="d-flex align-items-center justify-content-center gap-3 stats p-3 m-3 rounded-4 cursor-pointer tooltip"
      >
        <span class="tooltiptext"
          >Las propiedades de configuración de gremio personalizables
          totales.</span
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 512 512"
        >
          <path
            d="M495.9 166.6c3.2 8.7.5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4l-55.6 17.8c-8.8 2.8-18.6.3-24.5-6.8-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4c-1.1-8.4-1.7-16.9-1.7-25.5s.6-17.1 1.7-25.4l-43.3-39.4c-6.9-6.2-9.6-15.9-6.4-24.6 4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2 5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8 8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
            style="fill: var(--font)"
          />
        </svg>
        {{ stats.general.inputsCount | number }} Entradas totales
      </div>
    </div>
    <div class="col-lg-3 col-sm-6">
      <div
        class="d-flex align-items-center justify-content-center gap-3 stats p-3 m-3 rounded-4 cursor-pointer tooltip"
      >
        <span class="tooltiptext">¿Qué tan grande es el cerebro del bot?.</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 384 512"
        >
          <path
            d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2 5.2-7.1 10.4-14.2 15.4-21.4 19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3 5 7.2 10.2 14.3 15.4 21.4 19.8 27.1 39.7 54.4 49.2 86.2h160zm-80 128c44.2 0 80-35.8 80-80v-16H112v16c0 44.2 35.8 80 80 80zm-80-336c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112 8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"
            style="fill: var(--font)"
          />
        </svg>
        {{ stats.general.iq | number }} IQ
      </div>
    </div>
  </div>
  <hr />
  <div class="row align-items-center">
    <div class="col-lg-6 col-sm-12">
      <div class="large stats p-3 m-3 rounded-4">
        <popular-inputs-graph [stats]="stats"></popular-inputs-graph>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="large stats p-3 m-3 rounded-4">
        <popular-inputs-graph
          [stats]="stats"
          [reversed]="true"
        ></popular-inputs-graph>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="large stats p-3 m-3 rounded-4">
        <popular-commands-graph></popular-commands-graph>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="large stats p-3 m-3 rounded-4">
        <popular-commands-graph
          [stats]="stats"
          [reversed]="true"
        ></popular-commands-graph>
      </div>
    </div>
  </div>
  <h1>Discord Servers</h1>

  <table class="table table-striped table-borderless">
    <thead>
      <tr>
        <th>Icon</th>
        <th>Name</th>
        <th>Users</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let guild of stats.general.guilds; let i = index">
        <td>
          <img
            [src]="
              iconUrl + stats.general.guilds[i] + '/' + stats.general.imgs[i]
            "
          />
        </td>
        <td>{{ stats.general.names[i] }}</td>
        <td>{{ stats.general.countmembers[i] }}</td>
        <td>
          <button
            class="btn btn-danger"
            (click)="deleteGuild(stats.general.guilds[i])"
            [disabled]="loading"
          >
            <span *ngIf="!loading">Delete</span>
            <span *ngIf="loading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Deleting...
            </span>
          </button>

          <div
            *ngIf="errorMessage"
            class="alert alert-danger rounded-4"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</section>
