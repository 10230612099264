<div class="modal-dialog" role="document">
  <div
    class="modal-content"
    style="background-color: var(--background-secondary)"
  >
    <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit(f.value)">
      <div class="modal-header">
        <h5 class="modal-title">{{ "CUSTOMIZE-XP.TITLE" | translate }}</h5>
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          class="btn-close"
        ></button>
      </div>
      <div class="modal-body">
        <img [src]="xpCardPreviewURL" alt="" class="img-fluid" loading="auto" style="aspect-ratio: 467/141;" />
        <div class="d-flex align-items-center justify-content-between">
          <div class="m-1">
            <label>{{ "CUSTOMIZE-XP.OVERLAY" | translate }}</label>
            <input
              type="color"
              value="colorPickers[0]"
              formControlName="primary"
            />
          </div>
          <div class="m-1">
            <label>{{ "CUSTOMIZE-XP.PROCESSBAR1" | translate }}</label>
            <input
              type="color"
              value="colorPickers[1]"
              formControlName="secondary"
            />
          </div>
          <div class="m-1">
            <label>{{ "CUSTOMIZE-XP.PROCESSBAR2" | translate }}</label>
            <input
              type="color"
              value="colorPickers[2]"
              formControlName="tertiary"
            />
          </div>
          <div class="m-1">
            <label>{{ "CUSTOMIZE-XP.HASTAG" | translate }}</label>
            <input
              type="color"
              value="colorPickers[3]"
              formControlName="quarter"
            />
          </div>
        </div>
        <hr />
        <div>
          <label>{{ "CUSTOMIZE-XP.BACKGROUND" | translate }}</label>
          <input
            type="url"
            *ngIf="savedUser.premium"
            formControlName="backgroundURL"
          />
          <input
            type="url"
            *ngIf="!savedUser.premium"
            premium
            readonly
            formControlName="backgroundURL"
          />
          <slider class="d-flex gap-2">
            <input
              type="range"
              step="0.1"
              min="0.001"
              max="1.001"
              *ngIf="savedUser"
              readonly
              formControlName="overlay"
            />
            <output>{{ this.form.value.overlay }}</output>
          </slider>
        </div>
        <p>{{ "CUSTOMIZE-XP.OTHERELEMENTS" | translate }} ...</p>
        <div class="row">
          <div
            *ngFor="let image of stockImages"
            (click)="setBackground(image)"
            class="col-6 stock-image"
          >
            <img [src]="image" loading="lazy" alt="" class="img-fluid m-1" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{ "MODAL.CLOSE" | translate }}
        </button>
        <button type="submit" class="btn btn-bd-primary" id="submit">
          {{ "MODAL.SAVE" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
