<div class="d-flex grow vw-100 vh-100 position-fixed top-0 z-10 transition-all">
  <div class="sidenav" [ngClass]="{ side: true, opened: sidenavOpened }">
    <div class="guilds">
      <button class="toggle p-2 d-flex align-items-center">
        <div id="nav-icon2" (click)="toggle()" class="open">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div class="user">
        <div routerLink="/dashboard" class="guild-icon">
          <img
            width="52px"
            height="52px"
            class="rounded-5"
            [src]="user.displayAvatarURL"
            loading="lazy"
            alt="{{ user.username }}"
          />
        </div>
        <hr class="guild-seperator" />
      </div>
      <div
        *ngFor="let guild of guilds"
        loading="lazy"
        [routerLink]="['/servers/' + guild.id]"
        class="tooltip guild-icon"
      >
        <span class="tooltiptextdown">{{ guild.name }}</span>
        <img
          *ngIf="guild.iconURL"
          width="52px"
          height="52px"
          class="rounded-5"
          [src]="guild.iconURL"
          loading="lazy"
          [alt]="guild.name"
        />
        <span *ngIf="!guild.iconURL" class="d-flex justify-content-center">{{
          guild.nameAcronym
        }}</span>
      </div>
      <div routerLink="/invite" class="tooltip guild-icon invite">
        <span class="tooltiptextdown">Agregar un servidor</span>
        <span class="add-guild pt-1 d-flex justify-content-center flex-column"
          >+</span
        >
      </div>
    </div>
    <div class="options">
      <ng-content select=".side-nav"></ng-content>
    </div>
  </div>
  <div class="main-content overflow-y-auto">
    <navbar></navbar>
    <button class="toggle">
      <div id="nav-icon1" class="open" (click)="toggle()">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <ng-content select="#content"></ng-content>
  </div>
</div>
