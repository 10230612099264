<dashboard-sidebar>
  <div class="alert alert-dark mx-5 rounded-4" role="alert">
    {{ "DASHBOARD-OVERVIEW.ALERT1PARAGRAPH" | translate }} {{ user.username
    }}{{ "DASHBOARD-OVERVIEW.ALERT1PARAGRAPH2" | translate }} <br />
    {{ "DASHBOARD-OVERVIEW.ALERT2PARAGRAPH" | translate }}
    <hr />
    {{ "DASHBOARD-OVERVIEW.ALERT3PARAGRAPH" | translate }} <br />
  </div>
  <div class="container">
    <h1 class="fw-bold">
      {{ "DASHBOARD-OVERVIEW.TITLE" | translate }}{{ user.username }}
    </h1>
    <p>{{ "DASHBOARD-OVERVIEW.PARAGRAPH" | translate }}</p>
    <div>
      <a
        href="https://infinitybots.gg/bot/939986359697956874/vote"
        target="_blank"
      >
        <img
          width="300px"
          height="100%"
          class="rounded-4"
          src="https://widgets.infinitybots.gg/bot/939986359697956874/?size=large&theme=light&accent=blue"
          loading="lazy"
          alt="ESP CUSTOMS"
          onerror="this.onerror=null;this.src='assets/img/404.svg';"
        />
      </a>
      <a href="https://top.gg/bot/939986359697956874/vote" target="_blank">
        <img
          width="300px"
          height="100%"
          class="rounded-4"
          src="https://top.gg/api/widget/939986359697956874.svg"
          loading="lazy"
          alt="ESP CUSTOMS"
          onerror="this.onerror=null;this.src='assets/img/404.svg';"
        />
      </a>
      <a
        href="https://discordbotlist.com/bots/esp-customs/upvote"
        target="_blank"
      >
        <img
          width="300px"
          height="100%"
          class="rounded-4"
          src="https://discordbotlist.com/api/v1/bots/939986359697956874/widget"
          loading="lazy"
          alt="ESP CUSTOMS"
          onerror="this.onerror=null;this.src='assets/img/404.svg';"
        />
      </a>
      <a
        href="https://discords.com/bots/bot/939986359697956874/vote"
        target="_blank"
      >
        <img
          width="300px"
          height="100%"
          class="rounded-4"
          src="https://discords.com/bots/api/bot/939986359697956874/widget"
          loading="lazy"
          title="Visit ESP CUSTOMS listed on Discords.com!"
          alt="ESP CUSTOMS's Widget Failed to Load"
          onerror="this.onerror=null;this.src='assets/img/404.svg';"
        />
      </a>
      <a href="https://vcodes.xyz/bot/939986359697956874/vote" target="_blank">
        <img
          width="300px"
          height="100%"
          class="rounded-4"
          src="https://vcodes.xyz/api/v1/bot/939986359697956874/widget.svg"
          loading="lazy"
          alt="ESP CUSTOMS"
          onerror="this.onerror=null;this.src='assets/img/404.svg';"
        />
      </a>
    </div>

    <div class="row g-3" #feedbackForm>
      <div class="col">
        <div
          class="d-grid rounded p-3 gap-2"
          style="background-color: var(--background-secondary)"
        >
          <div class="d-flex justify-content-between">
            <label
              >{{ "DASHBOARD-OVERVIEW.FEEDBACK" | translate }} /
              {{ "DASHBOARD-OVERVIEW.FEEDBACKTITLE" | translate }}</label
            >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 512 512"
            >
              <path
                d="M256 0c53 0 96 43 96 96v3.6c0 15.7-12.7 28.4-28.4 28.4H188.4c-15.7 0-28.4-12.7-28.4-28.4V96c0-53 43-96 96-96zM41.4 105.4c12.5-12.5 32.8-12.5 45.3 0l64 64c.7.7 1.3 1.4 1.9 2.1 14.2-7.3 30.4-11.4 47.5-11.4H312c17.1 0 33.2 4.1 47.5 11.4.6-.7 1.2-1.4 1.9-2.1l64-64c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-64 64c-.7.7-1.4 1.3-2.1 1.9 6.2 12 10.1 25.3 11.1 39.5H480c17.7 0 32 14.3 32 32s-14.3 32-32 32h-64c0 24.6-5.5 47.8-15.4 68.6 2.2 1.3 4.2 2.9 6 4.8l64 64c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-63.1-63.1c-24.5 21.8-55.8 36.2-90.3 39.6V240c0-8.8-7.2-16-16-16s-16 7.2-16 16v239.2c-34.5-3.4-65.8-17.8-90.3-39.6l-63 63c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l64-64c1.9-1.9 3.9-3.4 6-4.8C101.5 367.8 96 344.6 96 320H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h64.3c1.1-14.1 5-27.5 11.1-39.5-.7-.6-1.4-1.2-2.1-1.9l-64-64c-12.5-12.5-12.5-32.8 0-45.3z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          <input
            type="text"
            [(ngModel)]="feedbackValue"
            #feedbackInput="ngModel"
            required
          />
          <button
            [disabled]="feedbackInput.errors?.['required']"
            class="btn btn-success ml-3"
            (click)="
              apiService.sendFeedback([
                feedbackValue,
                user.id,
                user.displayAvatarURL
              ]) && (feedbackForm.innerHTML = 'Gracias por tus comentarios!')
            "
          >
            {{ "DASHBOARD-OVERVIEW.SEND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</dashboard-sidebar>
