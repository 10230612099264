<section class="container-fluid">
  <div class="container">
    <div class="my-4 card position-relative m-0 rounded p-4">
      <strong class="text-center fs-2" style="font-family: 'Ginto-Nord'">{{
        duration
      }}</strong>
      <div class="my-4">
        <h2 class="fw-bold">{{ price }}€</h2>
        <h5 class="text-secondary">
          <del>{{ price * 1.5 }}€</del>
        </h5>
      </div>
      <ul class="d-grid gap-3">
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 text-success-default"
          >
            <path
              d="M9 1.6665L3.28571 8.33317L1 5.6665"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          {{ "PREMIUM.XPCARD" | translate }}
        </li>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 text-success-default"
          >
            <path
              d="M9 1.6665L3.28571 8.33317L1 5.6665"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          {{ "PREMIUM.WELCOMECARD" | translate }}
        </li>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 text-success-default"
          >
            <path
              d="M9 1.6665L3.28571 8.33317L1 5.6665"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          {{ "PREMIUM.AUTO-PUNISH" | translate }}
        </li>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 text-success-default"
          >
            <path
              d="M9 1.6665L3.28571 8.33317L1 5.6665"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          {{ "PREMIUM.CUSTOM-COMMANDS" | translate }}
        </li>
        <li>
          <svg
            width="24"
            height="24"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 text-success-default"
          >
            <path
              d="M9 1.6665L3.28571 8.33317L1 5.6665"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          {{ "PREMIUM.MUSIC-MANAGER" | translate }}
        </li>
      </ul>
      <button
        *ngIf="userService.user && userService.savedUser?.premium"
        (click)="checkout.emit()"
        class="checkout d-inline-flex justify-content-center align-items-center h-2 px-3 mt-2 btn btn-bd-premium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 384 512"
        >
          <path
            d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4.7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9.7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"
            style="fill: var(--font)"
          />
        </svg>
        {{ "PREMIUM.OTHERBUY" | translate }}
      </button>
      <button
        *ngIf="userService.user && !userService.savedUser?.premium"
        (click)="checkout.emit()"
        class="checkout d-inline-flex justify-content-center align-items-center h-2 px-3 mt-2 btn btn-bd-premium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 384 512"
        >
          <path
            d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4.7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9.7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"
            style="fill: var(--font)"
          />
        </svg>
        {{ "PREMIUM.BUY" | translate }}
      </button>
      <button
        *ngIf="!userService.user"
        routerLink="/login"
        class="checkout d-inline-flex justify-content-center align-items-center h-2 px-3 mt-2 btn btn-bd-premium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 448 512"
        >
          <path
            d="M224 256a128 128 0 1 0 0-256 128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7 0-98.5-79.8-178.3-178.3-178.3h-91.4z"
            style="fill: var(--font)"
          />
        </svg>
        {{ "HOME.SESSION" | translate }}
      </button>
    </div>
  </div>
</section>
