import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuildService } from '../../../services/guild.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-leaderboard-module',
  templateUrl: './leaderboard-module.component.html',
  styleUrls: ['./leaderboard-module.component.css']
})
export class LeaderboardModuleComponent implements OnInit {
  members: any;
  guild: any = {};
  iconURL: any;

  constructor(public translate: TranslateService,
    private guildService: GuildService,
    private route: ActivatedRoute) {}

  async ngOnInit() {
    const guildId = this.route.snapshot.paramMap.get('id');

    this.members = await this.guildService.getMembers(guildId);
    this.guild = await this.guildService.getPublicGuild(guildId);
    if (!this.guild.iconURL){
      this.iconURL = `https://cdn.discordapp.com/icons/${this.guild.id}/${this.guild.icon}.gif?size=512` ? `https://cdn.discordapp.com/icons/${this.guild.id}/${this.guild.icon}.png?size=2048` : `https://cdn.discordapp.com/icons/${this.guild.id}/${this.guild.icon}.jpg?size=2048`;
    } else {
      this.iconURL = `https://cdn.discordapp.com/icons/${this.guild.id}/${this.guild.icon}.png?size=2048`;
    }
  }
}