// app.module.ts:
import { BrowserModule } from '@angular/platform-browser';
import { APP_ID, NgModule, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowOnDirtyErrorStateMatcher, ErrorStateMatcher } from '@angular/material/core';
import { provideCharts, BaseChartDirective, withDefaultRegisterables } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavbarComponent } from './core/navbar/navbar.component';
import { FooterComponent } from './core/footer/footer.component';
import { CommandsComponent } from './pages/commands/commands.component';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { InviteComponent } from './pages/auth/invite/invite.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { DashboardOverviewComponent } from './dashboard/dashboard/dashboard-overview/dashboard-overview.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { GuildComponent } from './dashboard/guild/guild/guild.component';
import { SpinnerComponent } from './utils/spinner/spinner.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CommandsModuleComponent } from './dashboard/guild/commands-module/commands-module.component';
import { LogsModuleComponent } from './dashboard/guild/logs-module/logs-module.component';
import { AutoModModuleComponent } from './dashboard/guild/auto-mod-module/auto-mod-module.component';
import { GeneralModuleComponent } from './dashboard/guild/general-module/general-module.component';
import { CustomizeTciketsComponent } from './dashboard/guild/customize-ticket/customize-ticket.component';
import { FortniteCustomComponent } from './dashboard/guild/fortnite-custom/fortnite-custom.component';
import { TwitchModuleComponent } from './dashboard/guild/social-module/twitch-module/twitch-module.component';
import { YoutubeModuleComponent } from './dashboard/guild/social-module/youtube-module/youtube-module.component';
import { LogModuleComponent } from './dashboard/guild/log-module/log-module.component';
import { SettingsModuleComponent } from './dashboard/guild/settings-module/settings-module.component';
import { LevelingModuleComponent } from './dashboard/guild/leveling-module/leveling-module.component';
import { GuildSidebarComponent } from './dashboard/guild/guild-sidebar/guild-sidebar.component';
import { LeaderboardModuleComponent } from './dashboard/guild/leaderboard-module/leaderboard-module.component';
import { XPCardComponent } from './xp-card/xp-card.component';
import { WelcomeModuleComponent } from "./dashboard/guild/welcome-module/welcome-module.component";
import { DashboardSidebarComponent } from './dashboard/dashboard/dashboard-sidebar/dashboard-sidebar.component';
import { CustomizeXPCardComponent } from './dashboard/dashboard/customize-xp-card/customize-xp-card.component';
import { PremiumDirective } from '../directives/premium.directive';
import { SaveChangesComponent } from './utils/discord/save-changes/save-changes.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PremiumComponent } from './pages/premium/premium/premium.component';
import { CleanDateTimePipe } from './pipes/clean-date-time.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { MemberUsernameComponent } from './utils/discord/member-username/member-username.component';
import { ZippyComponent } from './zippy/zippy.component';
import { AuditLogWidgetComponent } from './dashboard/widgets/audit-log-widget/audit-log-widget.component';
import { DocsSidebarComponent } from './pages/docs-sidebar/docs-sidebar.component';
import { CommandsWidgetComponent } from './dashboard/widgets/commands-widget/commands-widget.component';
import { MiniDatePipe } from './pipes/mini-date.pipe';
import { environment } from '../environments/environment';
import { SnakeToSentenceCasePipe } from './pipes/snake-to-sentence-case.pipe';
import { TruncatedPipe } from './pipes/truncated.pipe';
import { DurationStringPipe } from './pipes/duration-string.pipe';
import { CamelToSentenceCasePipe } from './pipes/camel-to-sentence-case.pipe';
import { MessagePreviewComponent } from './utils/discord/message-preview/message-preview.component';
import { EmbedPreviewComponent } from './utils/discord/embed-preview/embed-preview.component';
import { FortniteConfigPreviewComponent } from './utils/discord/fortniteConfig-preview/fortniteConfig-preview.component';
import { ReactionRolesModuleComponent } from './dashboard/guild/reaction-roles-module/reaction-roles-module.component';
import { PremiumPaywallComponent } from './utils/premium-paywall/premium-paywall.component';
import { PremiumBadgeComponent } from './utils/premium-badge/premium-badge.component';
import { WavesComponent } from './utils/waves/waves.component';
import { StatsComponent } from './stats/stats.component';
import { PopularInputsGraphComponent } from './stats/popular-inputs-graph/popular-inputs-graph.component';
import { PopularCommandsGraphComponent } from './stats/popular-commands-graph/popular-commands-graph.component';
import { PremiumCardComponent } from './pages/premium/premium-card/premium-card.component';
import { DocsComponent } from './pages/docs/docs.component';
import { MaterialModule } from './material-module';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class AlertErrorHandler implements ErrorHandler {
  async handleError(error: Error | any) {
    try {
      console.log(error?.rejection?.error ?? error?.message ?? error);

      const key = localStorage.getItem('key');
      await fetch(`${environment.endpoint}/error?key=${key}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: error.message })
      });
    } finally {
      console.log(error);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    PrivacyComponent,
    CommandsComponent,
    AuthComponent,
    LoginComponent,
    InviteComponent,
    LogoutComponent,
    DashboardOverviewComponent,
    SidebarComponent,
    GuildComponent,
    SpinnerComponent,
    CommandsModuleComponent,
    AutoModModuleComponent,
    GeneralModuleComponent,
    CustomizeTciketsComponent,
    FortniteCustomComponent,
    TwitchModuleComponent,
    YoutubeModuleComponent,
    LevelingModuleComponent,
    SettingsModuleComponent,
    LevelingModuleComponent,
    GuildSidebarComponent,
    LeaderboardModuleComponent,
    XPCardComponent,
    WelcomeModuleComponent,
    CustomizeXPCardComponent,
    DashboardSidebarComponent,
    PremiumDirective,
    SaveChangesComponent,
    NotFoundComponent,
    PaymentSuccessComponent,
    PremiumComponent,
    CleanDateTimePipe,
    TimeFormatPipe,
    MemberUsernameComponent,
    ZippyComponent,
    AuditLogWidgetComponent,
    DocsSidebarComponent,
    CommandsWidgetComponent,
    MiniDatePipe,
    SnakeToSentenceCasePipe,
    TruncatedPipe,
    DurationStringPipe,
    CamelToSentenceCasePipe,
    MessagePreviewComponent,
    EmbedPreviewComponent,
    FortniteConfigPreviewComponent,
    LogModuleComponent,
    LogsModuleComponent,
    ReactionRolesModuleComponent,
    PremiumPaywallComponent,
    PremiumBadgeComponent,
    WavesComponent,
    StatsComponent,
    PopularInputsGraphComponent,
    PopularCommandsGraphComponent,
    PremiumCardComponent,
    DocsComponent    
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgOptimizedImage,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MaterialModule,
    BaseChartDirective
  ],
  exports: [PremiumDirective],
  providers: [
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: ErrorHandler, useClass: AlertErrorHandler },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }