import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModuleConfig } from '../../../../module-config';
import { GuildService } from '../../../../services/guild.service';
import { UserService } from '../../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../services/api.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: "twitch-module",
  templateUrl: "./twitch-module.component.html",
  styleUrls: ["./twitch-module.component.css"],
})
export class TwitchModuleComponent extends ModuleConfig implements OnInit {
  moduleName = "social";

  async getTwitchOAuthToken() {
    const data = await this.apiService.getSavedBot();
    return data?.social?.twitch?.access_token_app || '';
  }

  buildForm({ social }: any) {
    const form = new FormGroup({
      twitch: new FormGroup({
        enabled: new FormControl(Boolean ?? false),
        channel: new FormControl(""),
        message: new FormControl(""),
        streamers: new FormControl([])
      }),
      youtube: new FormGroup({
        enabled: new FormControl(social.youtube.enabled ?? false),
        channel: new FormControl(social.youtube.channel),
        message: new FormControl(social.youtube.message),
        youtubers: new FormControl(social.youtube.youtubers)
      })
    });
    form.patchValue(social);
    return form;
  }
  constructor(
    public apiService: ApiService,
    private http: HttpClient,
    public translate: TranslateService,
    guildService: GuildService,
    route: ActivatedRoute,
    userService: UserService,
    saveChanges: MatSnackBar
  ) {
    super(guildService, route, userService, saveChanges);
  }
  async ngOnInit() {
    await super.init();
  }

  async addStreamer(streamerName: string) {
    if (!streamerName) {
      return null;
    }
    const oAuthToken = await this.getTwitchOAuthToken();
    try {
      const response = await this.http.get<any>(`https://api.twitch.tv/helix/users?login=${streamerName}`, {
        headers: {
          'Client-ID': 'eapy368fxdmr3p1ggpyrs78qgli1hb',
          'Authorization': `Bearer ${oAuthToken}`
        }
      }).toPromise();
      this.apiService.sendTwitch(response.data[0].id);
      if (response.data && response.data.length > 0) {
        const streamers = this.form.get("twitch.streamers") as FormControl;
        const value = streamers.value;

        if (value.indexOf(streamerName) >= 0) {
          return null;
        }

        value.push(streamerName);
        streamers.setValue(value);
      } else {
        // Si el canal no existe, mostrar un mensaje de error
        const errorMessage = await firstValueFrom(this.translate.get('Twitch.channelNotFound', { channel: streamerName }));
        return this.saveChanges.open(errorMessage, null, { duration: 3000 });
      }
    } catch (error) {
      const errorMessage = await firstValueFrom(this.translate.get('Twitch.channelNotFound', { channel: streamerName }));
      return this.saveChanges.open(errorMessage, null, { duration: 3000 });
    }
    
    return null; // Agregar declaración de retorno al final de la función
  }

  removeStreamer(streamerName: string) {
    const streamers = this.form.get("twitch.streamers") as FormControl;
    const value = streamers.value.filter((name: string) => name !== streamerName);

    return streamers.setValue(value);
  }
}