import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LangService {

  readonly defaultLang = 'es';

  changeLang(lang: string) {
    localStorage.setItem('lang', lang);

    this.updateLang();
  }

  updateLang() {
    const lang = localStorage.getItem('lang') ?? this.defaultLang;
    document.querySelector('html').setAttribute('lang', lang);
  }
}