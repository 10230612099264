import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { CommandsService } from './services/commands.service';
import { ThemeService } from './services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from './services/lang.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public langService: LangService,
    private commandService: CommandsService,
    private themeService: ThemeService,
    private userService: UserService,
  ) {
    translate.addLangs(['es', 'en', 'fr']);
    let browserLang: string;
    if (localStorage.getItem("lang")) {
      browserLang = localStorage.getItem("lang");
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/es|en|fr/) ? browserLang : 'es');
  }

  async ngOnInit() {
    this.themeService.updateTheme();
    this.langService.updateLang();
    await this.commandService.updateCommands();
    await this.userService.updateUser();
  }
}