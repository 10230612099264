<div class="sidenav-container fullscreen">
  <div class="sidenav scrollbar-trigger overflow-y-auto" [ngClass]="{ side: true, opened: sidenavOpened }">
    <div class="side-nav navbar-dark">
      <h2 class="m-4">
        ESP CUSTOMS <i class="fas fa-book-open" aria-hidden="true"></i>
      </h2>
      <button class="toggle p-2 d-flex align-items-center">
        <div id="nav-icon2" (click)="toggle()" class="open">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <hr />
      <div class="navbar-nav nav-pills px-2">
        <div class="my-2">
          <div class="category fw-bold text-uppercase">
            {{ "DOC.START" | translate }}
          </div>
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/get-started"
            routerLinkActive="active"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 384 512"
            >
              <path
                d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                style="fill: var(--font)"
              />
            </svg>
            {{ "DOC.START" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            href="https://espcustoms.statuspage.io/"
            routerLinkActive="active"
            >{{ "DOC.STATUS" | translate }}</a
          >
        </div>
        <hr />
        <div class="my-2">
          <div class="category fw-bold text-uppercase">
            {{ "DOC.MODULE" | translate }}
          </div>
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/announce"
            routerLinkActive="active"
            >{{ "DOC.REGISTER" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/auto-mod"
            routerLinkActive="active"
            >{{ "DOC.MODERATION" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/commands"
            routerLinkActive="active"
            >{{ "DOC.COMMANDS" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/general"
            routerLinkActive="active"
            >{{ "DOC.GENERAL" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/leveling"
            routerLinkActive="active"
            >{{ "DOC.LEVELS" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/music"
            routerLinkActive="active"
            >{{ "DOC.MUSIC" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/welcome"
            routerLinkActive="active"
            >{{ "DOC.WELCOME" | translate }}</a
          >
        </div>
        <hr />
        <div class="my-2">
          <div class="category fw-bold text-uppercase">
            {{ "DOC.OTHER" | translate }}
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </div>
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/api"
            routerLinkActive="active"
            >API</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/changelog"
            routerLinkActive="active"
            >{{ "DOC.CHANGE-LOGS" | translate }}</a
          >
          <a
            class="nav-item nav-link d-flex align-items-center px-2 gap-2"
            routerLink="/docs/bot"
            routerLinkActive="active"
            >ESP CUSTOMS Code</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="main-content overflow-y-auto">
    <navbar></navbar>
    <button class="toggle">
      <div id="nav-icon1" class="open" (click)="toggle()">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <ng-content select="#content"></ng-content>
  </div>
</div>
