<sidebar>
  <div *ngIf="guild" class="side-nav navbar-dark">
    <div class="header-profile rounded-4">
      <img
        width="100%"
        height="100%"
        class="rounded-4"
        *ngIf="bannerURL"
        [src]="bannerURL"
        loading="lazy"
        [alt]="guild.name"
      />
      <img
        width="100%"
        height="100%"
        class="profile"
        *ngIf="guild.iconURL"
        [src]="guild.iconURL"
        loading="lazy"
        [alt]="guild.name"
      />
      <div
        *ngIf="!guild.iconURL"
        class="alt-name d-flex flex-column justify-content-center"
      >
        <span>{{ guild.nameAcronym }}</span>
      </div>
      <h4 class="p-2" [routerLink]="['/servers/' + id]">
        {{ guild.name }}
      </h4>
    </div>
    <div *ngIf="guild && savedGuild" class="navbar-nav nav-pills px-2">
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "GUILD-SIDEBAR.CAT-CONFIG" | translate }}
        </div>
        <a
          [routerLink]="['/servers/' + id + '/welcome']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.welcome?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M256 0h64c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32h-64c-17.7 0-32-14.3-32-32V32c0-17.7 14.3-32 32-32zM64 64h128v48c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V64h128c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128c0-35.3 28.7-64 64-64zm112 373.3c0 5.9 4.8 10.7 10.7 10.7h202.6c5.9 0 10.7-4.8 10.7-10.7 0-29.5-23.9-53.3-53.3-53.3H229.3c-29.5 0-53.3 23.9-53.3 53.3zM288 352a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-WELCOME" | translate }}
        </a>
        <a
          [routerLink]="['/servers/' + id + '/auto-mod']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.autoMod?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 512 512"
            >
              <path
                d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4 106.8 106.7-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4 58.8-58.7-45.3-45.3-58.7 58.7-1.4-1.4z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-MODERATION" | translate
          }}<premium-badge
            class="d-inline-flex justify-content-center align-items-center h-2 px-2 btn btn-bd-premium"
          ></premium-badge>
        </a>
        <a
          [routerLink]="['/servers/' + id + '/commands']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.commands?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 640 512"
            >
              <path
                d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3l89.3 89.4-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-COMMAND" | translate
          }}<premium-badge
            class="d-inline-flex justify-content-center align-items-center h-2 px-2 btn btn-bd-premium"
          ></premium-badge>
        </a>
        <a
          [routerLink]="['/servers/' + id + '/general']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.general?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M9.4 86.6c-12.5-12.5-12.5-32.7 0-45.2s32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 9.4 86.6zM256 416h288c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-GENERAL" | translate }}
        </a>
        <a
          [routerLink]="['/servers/' + id + '/leveling']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.leveling?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M316.9 18c-5.3-11-16.5-18-28.8-18s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329l-24.6 145.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329l104.2-103.1c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7l-143.7-21.2L316.9 18z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-LEVLES" | translate }}
        </a>
        <a
          [routerLink]="['/servers/' + id + '/logs']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.logs?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 512 512"
            >
              <path
                d="M288 448H64V224h64v-64H64c-35.3 0-64 28.7-64 64v224c0 35.3 28.7 64 64 64h224c35.3 0 64-28.7 64-64v-64h-64v64zm-64-96h224c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64v224c0 35.3 28.7 64 64 64z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-LOGS" | translate }}
        </a>
        <a
          [routerLink]="['/servers/' + id + '/reaction-roles']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.reactionRoles?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 640 512"
            >
              <path
                d="M74.6 373.2c41.7 36.1 108 82.5 166.1 73.7 6.1-.9 12.1-2.5 18-4.5-9.2-12.3-17.3-24.4-24.2-35.4-21.9-35-28.8-75.2-25.9-113.6-20.6 4.1-39.2 13-54.7 25.4-6.5 5.2-16.3 1.3-14.8-7 6.4-33.5 33-60.9 68.2-66.3 2.6-.4 5.3-.7 7.9-.8l19.4-131.3c2-13.8 8-32.7 25-45.9 18.6-14.3 50.9-30.5 103.6-35.3l-2.4-2.1c-20.2-15.6-72.4-41.6-185.1-24.5S20.5 63 5.7 83.9C0 91.9-.8 102 .6 111.8l24.2 164.3c5.5 37.3 21.5 72.6 49.8 97.2zm87.7-219.6c4.4-3.1 10.8-2 11.8 3.3.1.5.2 1.1.3 1.6 3.2 21.8-11.6 42-33.1 45.3S99.8 192 96.6 170.3c-.1-.5-.1-1.1-.2-1.6-.6-5.4 5.2-8.4 10.3-6.7 9 3 18.8 3.9 28.7 2.4s19.1-5.3 26.8-10.8zM261.6 390c29.4 46.9 79.5 110.9 137.6 119.7s124.5-37.5 166.1-73.7c28.3-24.5 44.3-59.8 49.8-97.2l24.2-164.3c1.4-9.8.6-19.9-5.1-27.9-14.8-20.9-57.3-61.2-170-78.3s-164.8 8.9-185 24.5c-7.8 6-11.5 15.4-12.9 25.2l-24.2 164.3c-5.5 37.3-.4 75.8 19.6 107.7zm142.9-154.7c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7.1-.5.1-1.1.2-1.6 3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1.5-.2 1.1-.3 1.6-1 5.3-7.4 6.4-11.8 3.3zm136.2 15.5c-1 5.3-7.4 6.4-11.8 3.3-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7.1-.5.1-1.1.2-1.6 3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1.5-.2 1.1-.3 1.6zM530 350.2c-19.6 44.7-66.8 72.5-116.8 64.9s-87.1-48.2-93-96.7c-1-8.3 8.9-12.1 15.2-6.7 23.9 20.8 53.6 35.3 87 40.3s66.1.1 94.9-12.8c7.6-3.4 16 3.2 12.6 10.9z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-ROLEREACTION" | translate }}
        </a>
        <a
          [routerLink]="['/servers/' + id + '/customize-ticket']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.embedticket?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64h448c35.3 0 64-28.7 64-64v-64c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6v-64c0-35.3-28.7-64-64-64H64zm64 112v160c0 8.8 7.2 16 16 16h288c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zm-32-16c0-17.7 14.3-32 32-32h320c17.7 0 32 14.3 32 32v192c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-TICKET" | translate }}
        </a>
        <a
          [routerLink]="['/servers/' + id + '/fortnite-custom']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.fortnite?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 512 512"
            >
              <path
                d="M0 96c0-35.3 28.7-64 64-64h384c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64h256V96zM64 224v64h64v-64H64zm384 0H192v64h256v-64zM64 352v64h64v-64H64zm384 0H192v64h256v-64z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-FORTNITE" | translate }}
        </a>
      </div>
      <hr />
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "GUILD-SIDEBAR.CAT-SOCIAL" | translate }}
        </div>
        <a
          [routerLink]="['/servers/' + id + '/twitch']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.social.twitch?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 512 512"
            >
              <path
                d="M391.2 103.5h-38.7v109.7h38.6zM285 103h-38.6v109.8H285zM120.8 0 24.3 91.4v329.2h115.8V512l96.5-91.4h77.3L487.7 256V0zm328.3 237.8-77.2 73.1h-77.3l-67.6 64v-64h-86.9V36.6h309z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-SOCIAL-TWITCH" | translate
          }}<span
            class="badge text-uppercase px-1 lh-1 rounded-4"
            style="
              background: linear-gradient(
                40deg,
                var(--secondary-scrollbar) 0%,
                var(--primary-scrollbar) 100%
              );
              font-family: 'Ginto-Nord';
            "
            >Beta</span
          >
        </a>
        <a
          [routerLink]="['/servers/' + id + '/youtube']"
          routerLinkActive="active"
          [class.interactive-muted]="!savedGuild.social.youtube?.enabled"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <div class="position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6C14.9 167 14.9 256.4 14.9 256.4s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zM232.2 337.6V175.2l142.7 81.2-142.7 81.2z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          {{ "GUILD-SIDEBAR.CAT-SOCIAL-YOUTUBE" | translate }}
        </a>
      </div>
      <hr />
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "GUILD-SIDEBAR.CAT-MORE" | translate }}
        </div>
        <a
          [routerLink]="['/leaderboard/' + id]"
          routerLinkActive="active"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 576 512"
          >
            <path
              d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2.2 5.3.4 10.6.7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7 44.3 43.1 98.3 64.8 138.1 75.8 23.4 6.5 39.4 26 39.4 45.6 0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32h192c17.7 0 32-14.3 32-32s-14.3-32-32-32h-26.1c-20.9 0-37.9-17-37.9-37.9 0-19.6 15.9-39.2 39.4-45.6 39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2.5-10.4.7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6-24.9-11-50.8-26.5-73.2-48.3-32-31.1-58-76-63-142.3zm415.2 142.3c-22.4 21.8-48.3 37.3-73.2 48.3 22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"
              style="fill: var(--font)"
            />
          </svg>
          {{ "GUILD-SIDEBAR.SCOREBOARD" | translate }}</a
        >
        <a
          [routerLink]="['/servers/' + id + '/log']"
          routerLinkActive="active"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 384 512"
          >
            <path
              d="M64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zm192 0v128h128L256 0zM112 256h160c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h160c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h160c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
              style="fill: var(--font)"
            />
          </svg>
          {{ "GUILD-SIDEBAR.CAT-LOG" | translate }}</a
        >
      </div>
      <hr />
      <div class="my-2">
        <div class="category fw-bold text-uppercase">
          {{ "GUILD-SIDEBAR.CAT-OTHER" | translate }}
        </div>
        <a
          [routerLink]="['/servers/' + id + '/settings']"
          routerLinkActive="active"
          class="nav-item nav-link d-flex align-items-center px-2 gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 512 512"
          >
            <path
              d="M495.9 166.6c3.2 8.7.5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4l-55.6 17.8c-8.8 2.8-18.6.3-24.5-6.8-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4c-1.1-8.4-1.7-16.9-1.7-25.5s.6-17.1 1.7-25.4l-43.3-39.4c-6.9-6.2-9.6-15.9-6.4-24.6 4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2 5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8 8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
              style="fill: var(--font)"
            />
          </svg>
          {{ "GUILD-SIDEBAR.CAT-SETTINGS" | translate }}
        </a>
      </div>
    </div>
  </div>
  <div id="content">
    <div *ngIf="loaded">
      <ng-content></ng-content>
      <div class="mt-5 pt-2"></div>
    </div>
    <div *ngIf="!loaded">
      <spinner></spinner>
    </div>
  </div>
</sidebar>
