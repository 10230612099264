import { Component, OnInit } from '@angular/core';
import { ModuleConfig } from '../../../module-config';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { GuildService } from '../../../services/guild.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toIterable } from '../../../utils';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: "app-auto-mod-module",
  templateUrl: "./auto-mod-module.component.html",
  styleUrls: ["./auto-mod-module.component.css"],
})
export class AutoModModuleComponent extends ModuleConfig implements OnInit {
  maxRules = toIterable(4);
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  filters = [
    MessageFilter.Links,
    MessageFilter.Words,
    MessageFilter.MassMention,
    MessageFilter.MassCaps,
  ];
  moduleName = "autoMod";

  constructor(
    public translate: TranslateService,
    guildService: GuildService,
    route: ActivatedRoute,
    userService: UserService,
    saveChanges: MatSnackBar
  ) {
    super(guildService, route, userService, saveChanges);
  }

  async ngOnInit() {
    await super.init();
  }

  buildForm({ autoMod }: any) {
    const form = new FormGroup({
      banWords: new FormControl([]),
      banLinks: new FormControl([]),
      enabled: new FormControl(Boolean),
      filters: new FormControl([]),
      autoDeleteMessages: new FormControl(Boolean),
      autoWarnUsers: new FormControl(Boolean),
      ignoredRoles: new FormControl([]),
      ignoredChannels: new FormControl([]),
      filterThreshold: new FormControl(5, [
        Validators.min(1),
        Validators.max(20),
      ]),
      punishments: new FormArray(
        new Array(this.maxRules.length).fill(
          new FormGroup({
            type: new FormControl(""),
            warnings: new FormControl(5, [
              Validators.min(1),
              Validators.max(100),
            ]),
            minutes: new FormControl(5, [
              Validators.min(1),
              Validators.max(60),
            ]),
          }),
          0,
          this.maxRules.length
        )
      ),
    });
    form.patchValue(autoMod);
    return form;
  }
  formatLabel(filterThreshold: number): string {
    if (filterThreshold >= 20) {
      return Math.round(filterThreshold / 20) + 'k';
    }

    return `${filterThreshold}`;
  }
}

export enum MessageFilter {
  Links = 'LINKS',
  MassCaps = 'MASS_CAPS',
  MassMention = 'MASS_MENTION',
  Words = 'WORDS',
  Toxicity = 'TOXICITY'
}
