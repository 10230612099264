import { Component, ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'docs-sidebar',
  templateUrl: './docs-sidebar.component.html',
  styleUrls: ['./docs-sidebar.component.css']
})
export class DocsSidebarComponent {

  sidenavOpened: boolean = true;
  isDrawerOpen: boolean = true;

  constructor(
    public translate: TranslateService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  toggle() {
    const icon = document.querySelector('#nav-icon1');
    icon.classList.toggle('open');
    this.isDrawerOpen = !this.isDrawerOpen;

    const sidenav = this.el.nativeElement.querySelector('.sidenav');

    if (this.isDrawerOpen) {
      // Lógica cuando el panel lateral está abierto
      this.renderer.setStyle(sidenav, 'display', 'block');
    } else {
      // Lógica cuando el panel lateral está cerrado
      this.renderer.setStyle(sidenav, 'display', 'none');
    }
  }
}
