import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GuildService } from '../../../services/guild.service';

@Component({
  selector: 'guild-sidebar',
  templateUrl: './guild-sidebar.component.html',
  styleUrls: ['./guild-sidebar.component.css']
})
export class GuildSidebarComponent implements OnInit {
  @Input('waitFor') loaded = true as any;
  
  id: string;
  guild: any;
  savedGuild: any;
  bannerURL: any;

  constructor(public translate: TranslateService,
    private guildService: GuildService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    document.title = 'ESP CUSTOMS - Dashboard';
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(async(paramMap) => {
      this.id = paramMap.get('id');

      this.savedGuild = await this.guildService.getSavedGuild(this.id);
      this.guild = this.guildService.getGuild(this.id);

      if (!this.guild.bannerURL){
        this.bannerURL = `https://i.imgur.com/Q5hLYaW.gif`;
      } else {
        this.bannerURL = `https://cdn.discordapp.com/banners/${this.guild.id}/${this.guild.banner}.jpg?size=2048`;
      }
      
      if (!this.guild)
        this.router.navigate(['/dashboard']);
    });
  }
}
