import { Component } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.css']
})
export class DashboardOverviewComponent {
  feedbackValue: string = '';
  get user() { return this.userService.user ?? {}; }

  constructor(public translate: TranslateService,
    public apiService: ApiService,
    private userService: UserService
  ) {
    document.title = 'ESP CUSTOMS - Dashboard';
  }
}