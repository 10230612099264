import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';
import { GuildService } from '../../services/guild.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  sidenavOpened: boolean = true;
  isDrawerOpen: boolean = true;

  get guilds() { return this.guildService.guilds || []; }
  get user() { return this.userService.user || {}; }

  constructor(
    private guildService: GuildService,
    private userService: UserService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  async ngOnInit() {
    if (this.guildService.guilds.length <= 0)
      await this.guildService.updateGuilds();
  }

  toggle() {
    const icon = document.querySelector('#nav-icon1');
    icon.classList.toggle('open');
    this.isDrawerOpen = !this.isDrawerOpen;

    const sidenav = this.el.nativeElement.querySelector('.sidenav');

    if (this.isDrawerOpen) {
      // Lógica cuando el panel lateral está abierto
      this.renderer.setStyle(sidenav, 'display', 'block');
    } else {
      // Lógica cuando el panel lateral está cerrado
      this.renderer.setStyle(sidenav, 'display', 'none');
    }
  }

  identify(index, guild) {
    return guild.id;
  }
}