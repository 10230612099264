<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto"
    [style.transform]="inverted ? 'rotate(180deg)' : ''">
    <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g class="parallax">
        <use id="wave1" xlink:href="#gentle-wave" x="48" y="0" />
        <use id="wave2" xlink:href="#gentle-wave" x="48" y="3" />
        <use id="wave3" xlink:href="#gentle-wave" x="48" y="5" />
        <use id="wave4" xlink:href="#gentle-wave" x="48" y="7" />
    </g>
</svg>