<dashboard-sidebar>
  <section class="container">
    <customize-xp-card #customize hidden></customize-xp-card>
    <h2 class="fw-bold">{{ "DASHBOARD.XPCARD" | translate }}</h2>
    <hr />
    <div class="float-none">
      <img
        [src]="xpCardURL"
        alt="Tu tarjeta XP"
        loading="auto"
        alt=""
        style="aspect-ratio: 467/141"
      />
    </div>
    <div class="pt-1" title="Customize">
      <h1 class="fw-bold">{{ "XPCARDPAGE.TITLE" | translate }}</h1>
      <p>{{ "XPCARDPAGE.PARAGRAPH" | translate }}</p>
      <button
        class="btn btn-bd-primary"
        data-bs-toggle="modal"
        data-bs-target="#customize"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="24"
          viewBox="0 0 576 512"
        >
          <path
            d="M339.3 367.1c27.3-3.9 51.9-19.4 67.2-42.9L568.2 74.1c12.6-19.5 9.4-45.3-7.6-61.2s-42.9-17.3-61.5-3.3L262.4 187.2c-24 18-38.2 46.1-38.4 76.1l115.3 103.8zm-19.6 25.4-116-104.4C143.9 290.3 96 339.6 96 400c0 3.9.2 7.8.6 11.6 1.8 17.5-10.2 36.4-27.8 36.4H64c-17.7 0-32 14.3-32 32s14.3 32 32 32h144c61.9 0 112-50.1 112-112 0-2.5-.1-5-.2-7.5z"
            style="fill: var(--font)"
          />
        </svg>
        {{ "XPCARDPAGE.EDIT" | translate }}
      </button>
    </div>
  </section>
</dashboard-sidebar>

<div
  class="modal fade"
  id="customize"
  tabindex="-1"
  role="dialog"
  aria-labelledby="customize"
  aria-hidden="true"
>
  <customize-xp-card (xpCardUpdate)="updateXPCard()"></customize-xp-card>
</div>
