import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LangService } from '../../../app/services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('langSelect') langSelect: string;

  OneUser: any = []

  environment = environment;
  defaultLang = 'es';

  form = new FormGroup({
    lang: new FormControl(localStorage.getItem('lang') ?? this.defaultLang),
    coins: new FormControl(5, [Validators.required, Validators.min(0.50)]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  coinstest = this.form.controls.coins;
  emailtest = this.form.controls.email;

  get user() { return this.userService.user; }

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private servicelang: LangService,
    public userService: UserService,
  ) {
    this.servicelang.updateLang();
  }

  async ngOnInit() {
    this.form.valueChanges.subscribe(() =>
      this.servicelang.changeLang(this.form.get('lang').value)
    );
    const status = this.route.snapshot.queryParamMap.get('payment_status');
    if (status === 'failed')
      alert('Payment Failed');
  }
}