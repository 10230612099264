import { Component, Input } from '@angular/core';
import { toHTML } from 'discord-markdown-fix';
import { textEmoji } from 'markdown-to-text-emoji';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'fortniteConfig-preview',
	templateUrl: './fortniteConfig-preview.component.html',
	styleUrls: ['../embed-preview.component.css']
})
export class FortniteConfigPreviewComponent {
	@Input() guild = { name: 'Servidor', id: '597084607447564319', iconURL: 'https://cdn.discordapp.com/icons/597084607447564319/8affa43a84ab6af5734315a2b1d2857c.webp?size=1024', ownerId: '360881334647914506', memberCount: 420 };
	@Input() eventVariables = true;


	@Input() thumbnail = 'https://cdn.discordapp.com/attachments/689769447602520067/754892886306193488/Moving_Zonev2.gif';
	@Input() mode = 'ARENA SOLO';
	@Input() content = 'Hola mundo';
	@Input() author = {
		username: 'ESP CUSTOMS',
		avatarURL: '/assets/img/esp-customs-cara-transparente.webp'
	}
	@Input() member = {
		displayName: 'ESP CUSTOMS'
	}
	@Input() createdAt = new Date();

	constructor(public translate: TranslateService, private userService: UserService) { }

	get timestamp() {
		const createdAt = new Date(this.createdAt ?? new Date());
		const timestamp = createdAt
			.toTimeString()
			.slice(0, 5);

		const wasToday = new Date().getDay() / createdAt.getDay() === 1;
		const wasYesterday = new Date().getDate() % createdAt.getDate() === 1;
		const isTommorow = createdAt.getTime() % new Date().getDate() === 1;

		if (wasToday)
			return `Hoy a las ${timestamp}`;
		if (wasYesterday)
			return `Ayer a las ${timestamp}`;
		else if (isTommorow)
			return `Mañana a ${timestamp}`;

		return createdAt
			.toJSON()
			.slice(0, 10)
			.split('-')
			.reverse()
			.join('/');
	}

	get processed() {
		const user = this.userService.user;

		return (this.eventVariables) ? toHTML(textEmoji(this.content
			.replace(/\{instigator\}/, '@ESP CUSTOMS')
			.replace(/\{message\}/g, 'Mensaje Borrado')
			.replace(/\{module\}/g, 'General')
			.replace(/\{new_level\}/g, '2')
			.replace(/\{new_value\}/g, JSON.stringify({ prefix: '!' }, null, 2))
			.replace(/\{old_level\}/g, '1')
			.replace(/\{old_value\}/g, JSON.stringify({ prefix: '/' }, null, 2))
			.replace(/\{reason\}/g, 'El usuario fue prohibido por `hacking`')
			.replace(/\{server\}/, this.guild?.name)
			.replace(/\{server.id\}/, this.guild?.id)
			.replace(/\{server.icon_url\}/, `${this.guild?.iconURL}`)
			.replace(/\{server.member_count\}/g, this.guild?.memberCount.toString())
			.replace(/\{server.name\}/, this.guild?.name)
			.replace(/\{server.owner_id\}/, this.guild?.ownerId)
			.replace(/\{server.owner\}/, `@${this.guild?.ownerId}`)
			.replace(/\{user\}/g, `@${user.username}`)
			.replace(/\{user.mention\}/g, `@${user.username}`)
			.replace(/\{user.avatar_url\}/g, user.displayAvatarURL)
			.replace(/\{user.discriminator\}/g, user.discriminator)
			.replace(/\{user.id\}/g, user.id)
			.replace(/\{user.name\}/g, `${user.username}`)
			.replace(/\{user.tag\}/g, user.tag)
			.replace(/\{warnings\}/g, 'El usuario fue adbertido por `hacking`')
			.replace(/\{xp\}/g, '300'))) : toHTML(textEmoji(this.content));
	}

	get arenamode() {
		const user = this.userService.user;

		return (this.eventVariables) ? toHTML(textEmoji(this.mode
			.replace(/\{instigator\}/, '@ESP CUSTOMS')
			.replace(/\{message\}/g, 'Mensaje Borrado')
			.replace(/\{module\}/g, 'General')
			.replace(/\{new_level\}/g, '2')
			.replace(/\{new_value\}/g, JSON.stringify({ prefix: '!' }, null, 2))
			.replace(/\{old_level\}/g, '1')
			.replace(/\{old_value\}/g, JSON.stringify({ prefix: '/' }, null, 2))
			.replace(/\{reason\}/g, 'El usuario fue prohibido por `hacking`')
			.replace(/\{server\}/, this.guild?.name)
			.replace(/\{server.id\}/, this.guild?.id)
			.replace(/\{server.icon_url\}/, `${this.guild?.iconURL}`)
			.replace(/\{server.member_count\}/g, this.guild?.memberCount.toString())
			.replace(/\{server.name\}/, this.guild?.name)
			.replace(/\{server.owner_id\}/, this.guild?.ownerId)
			.replace(/\{server.owner\}/, `@${this.guild?.ownerId}`)
			.replace(/\{user\}/g, `@${user.username}`)
			.replace(/\{user.mention\}/g, `@${user.username}`)
			.replace(/\{user.avatar_url\}/g, user.displayAvatarURL)
			.replace(/\{user.discriminator\}/g, user.discriminator)
			.replace(/\{user.id\}/g, user.id)
			.replace(/\{user.name\}/g, `${user.username}`)
			.replace(/\{user.tag\}/g, user.tag)
			.replace(/\{warnings\}/g, 'El usuario fue adbertido por `hacking`')
			.replace(/\{xp\}/g, '300'))) : toHTML(textEmoji(this.mode));
	}
}
