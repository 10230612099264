import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuildService {

  endpoint = environment.endpoint + '/guilds';
  singleton = null;

  private _guilds = [];
  get guilds() { return this._guilds; }

  private get key() {
    return localStorage.getItem('key');
  }

  get welcomeCardPreviewURL() {
    return `${this.endpoint}/welcome-card-preview?key=${this.key}`;
  }

  constructor(private http: HttpClient) { }

  async init() {
    if (this.guilds.length <= 0) await this.updateGuilds();
  }

  async updateGuilds(force = false) {
    this._guilds = (this.key) ?
      await this.http.get(`${this.endpoint}?key=${this.key}&force=${force}`).toPromise() as any : [];
  }

  getGuild(id: string) {
    return this.guilds?.find(g => g.id === id);
  }

  getPublicGuild(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/public`).toPromise();
  }

  getMember(id: string, memberID: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/members/${memberID}`).toPromise();
  }

  getMembers(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/members`).toPromise();
  }

  getSavedGuild(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/config?key=${this.key}`).toPromise();
  }

  getCommands(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/commands`).toPromise();
  }

  getSavedLog(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/log?key=${this.key}`).toPromise();
  }

  saveGuild(id: string, module: string, value: any): Promise<any> {
    return this.http.put(`${this.endpoint}/${id}/${module}?key=${this.key}`, value).toPromise();
  }

  getChannels(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/channels`).toPromise();
  }

  getRoles(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/roles`).toPromise();
  }

  getBotStatus(id: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/bot-status`).toPromise();
  }

  getMessage(id: string, channelId: string, messageId: string): Promise<any> {
    return this.http.get(`${this.endpoint}/${id}/channels/${channelId}/messages/${messageId}`).toPromise();
  }

  updateWelcomeCard(welcomeCard: WELCOMECard) {
    return this.http.put(`${this.endpoint}/welcome-card?key=${this.key}`, welcomeCard).toPromise();
  }

  getWELCOMECardPreviewURL(id: string, memberId: string, welcomeCard: WELCOMECard) {
    const { primary, secondary, tertiary, quarter, backgroundURL, titulo, subtitulo, opacityOverlay, typeOverlay, fontFamily } = welcomeCard;
    return `${this.endpoint}/${id}/members/${memberId}/welcome-card-preview?key=${this.key}` +
      `&primary=${primary}` +
      `&secondary=${secondary}` +
      `&tertiary=${tertiary}` +
      `&quarter=${quarter}` +
      `&fontFamily=${fontFamily}` +
      `&backgroundURL=${backgroundURL}` +
      `&titulo=${titulo}` +
      `&subtitulo=${subtitulo}` +
      `&opacityOverlay=${opacityOverlay}` +
      `&typeOverlay=${typeOverlay}`;
  }
}

export interface WELCOMECard {
  primary: string;
  secondary: string;
  tertiary: string;
  quarter: string;
  fontFamily: string;
  backgroundURL: "https://i.imgur.com/aClDVjh.jpg";
  titulo: string;
  subtitulo: string;
  opacityOverlay: 0.5;
  typeOverlay: string;
}