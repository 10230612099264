import { Component, OnInit } from '@angular/core';
import { ModuleConfig } from '../../../module-config';
import { toIterable } from '../../../utils';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { GuildService } from '../../../services/guild.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reaction-roles-module',
  templateUrl: './reaction-roles-module.component.html',
  styleUrls: ['./reaction-roles-module.component.css']
})
export class ReactionRolesModuleComponent extends ModuleConfig implements OnInit {
  moduleName = 'reactionRoles';

  reactionRoleMessages = [];
  reactionRolesIndices = toIterable(8);

  get reactionRoles() { return this.savedGuild.reactionRoles; }

  constructor(
    public translate: TranslateService,
    public override guildService: GuildService,
    route: ActivatedRoute,
    public override userService: UserService,
    saveChanges: MatSnackBar
  ) {
    super(guildService, route, userService, saveChanges);
  }

  async ngOnInit() {
    await super.init();

    await this.updateReactionRolePreviews();
  }

  buildForm({ reactionRoles }: any) {
    const emojiPattern = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]){1}/gm;

    const form = new FormGroup({
      configs: new FormArray(
        new Array(this.reactionRolesIndices.length).fill(
          new FormGroup({
            channel: new FormControl(''),
            role: new FormControl(''),
            emote: new FormControl('',
              Validators.pattern(emojiPattern)),
            messageId: new FormControl('', Validators.pattern(/[0-9]{18}/g))
          })
        )
      ),
      enabled: new FormControl(this.savedGuild.reactionRoles?.enabled ?? true),
    });
    form.patchValue(reactionRoles);
    this.buildReactionRolesFormArray(form, reactionRoles);
    return form;
  }

  override async submit() {
    await super.submit();

    await this.updateReactionRolePreviews();
  }

  async updateReactionRolePreviews() {
    this.reactionRoleMessages = [];
    for (const i of this.reactionRolesIndices) {
      const config = (this.form.controls['configs'] as FormArray)
        .get(i.toString())?.value;
      if (!(config.channel && config.messageId)) {
        this.reactionRoleMessages.push(null);
        continue;
      }
      try {
        const msg = await this.getMessage(config.channel, config.messageId);
        this.reactionRoleMessages.push(msg);
      } catch {
        this.reactionRoleMessages.push(null);
      }
    }
    return; // Agregar declaración de retorno
  }

  private buildReactionRolesFormArray(form: FormGroup, reactionRoles: any) {
    for (const i of this.reactionRolesIndices)
      (form.get('configs') as FormArray)
        .setControl(i,
          (new FormGroup({
            channel: new FormControl(reactionRoles.configs[i]?.channel ?? 0, Validators.min(0)),
            role: new FormControl(reactionRoles.configs[i]?.role ?? ''),
            emote: new FormControl(reactionRoles.configs[i]?.emote ?? ''),
            messageId: new FormControl(reactionRoles.configs[i]?.messageId ?? '')
          })));
  }

  filterFormValue() {
    this.form.value.configs = this.form.value.configs
      .filter(value => Object
        .keys(value)
        .some(key => value[key]));
  }

  getMessage(channelId: string, messageId: string) {
    return this.guildService.getMessage(this.guildId, channelId, messageId);
  }

  toDate(dateString: string) { return new Date(dateString); }
}