<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <h4 routerLink="/docs/commands" class="fw-bold">
      {{ "GUILD-COMMANDS.TITLE" | translate }}
    </h4>
    <hr />
    <div class="alert alert-info rounded-4" role="alert">
      {{ "GUILD-COMMANDS.ALERT-INFO" | translate }}
      <hr />
      {{ "GUILD-COMMANDS.ALERT-INFO-1" | translate }} <br />
    </div>
    <form #f="ngForm" [formGroup]="form">
      <div formArrayName="configs" class="row g-3">
        <div
          *ngFor="let command of commands; index as i"
          [formGroupName]="i"
          class="col-lg-4 col-md-6"
        >
          <div
            class="d-flex justify-content-between rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <div class="flexWT">
              <div>
                <span class="d-flex justify-content-between">
                  <strong>{{ command.data.name }}</strong>
                  <div class="slide-toggle">
                    <input
                      type="checkbox"
                      [id]="i"
                      class="slide-toggle-input"
                      aria-checked="true"
                      formControlName="enabled"
                    />
                    <label [htmlFor]="i" class="slide-toggle-label"></label>
                  </div>
                </span>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <label>{{
                    "GUILD-COMMANDS.WHITELIST-ROL" | translate
                  }}</label>
                  <mat-select multiple formControlName="roles">
                    <mat-option *ngFor="let role of roles" [value]="role.id">
                      <div
                        [style.color]="
                          '#' + role.color?.toString(16) + '!important'
                        "
                      >
                        &#64;{{ role.name }}
                      </div>
                    </mat-option>
                  </mat-select>
                </div>
                <div class="col-md-6">
                  <label>{{
                    "GUILD-COMMANDS.WHITELIST-CHANNELS" | translate
                  }}</label>
                  <mat-select multiple formControlName="channels">
                    <mat-option
                      *ngFor="let channel of textChannels"
                      [value]="channel.id"
                    >
                      #{{ channel.name }}
                      <span class="float-right text-muted"
                        >[{{
                          getChannel(channel.parentId)?.name || "N/A"
                        }}]</span
                      >
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <premium-paywall>
        <h3 class="gap-2 d-flex justify-content-start align-items-center">
          {{ "GUILD-COMMANDS.CUSTOM-COMMANDS" | translate
          }}<premium-badge
            class="d-inline-flex justify-content-center align-items-center h-2 px-3 btn btn-bd-premium"
            title="{{ 'DASHBOARD.PREMIUM' | translate }}"
            classvg="me-2"
          ></premium-badge>
        </h3>
        <p class="desc">
          {{ "GUILD-COMMANDS.CUSTOM-COMMANDS-DEC" | translate }}
        </p>
        <div class="row">
          <div
            *ngFor="let i of customCommandIndices"
            formArrayName="custom"
            class="col-lg-6 col-sm-12"
          >
            <div
              [formGroupName]="i"
              class="rounded custom-command d-flex justify-content-around row m-1 p-2"
              style="background-color: var(--background-secondary)"
            >
              <div class="col-sm-6">
                <label>{{ "GUILD-COMMANDS.ALIAS" | translate }}</label>
                <input type="text" formControlName="alias" #alias />
              </div>
              <div class="col-sm-6">
                <label>{{ "GUILD-COMMANDS.COMAND" | translate }}</label>
                <input type="text" formControlName="command" #command />
              </div>
              <div>
                <label class="me-2">{{
                  "GUILD-COMMANDS.EXECUTE-ANYWHERE" | translate
                }}</label>
                <input type="checkbox" formControlName="anywhere" />
              </div>
              <div
                [@expandCollapse]="alias.value && command.value ? 'down' : 'up'"
                [hidden]="!alias.value || !command.value"
                [class]="alias.value && command.value ? 'pb-3 px-2' : ''"
              >
                <code>{{ command.value }}</code>
                {{ "GUILD-COMMANDS.ALIAS-INFO" | translate }}
                <code>{{ alias.value }}</code>
              </div>
            </div>
          </div>
        </div>
      </premium-paywall>
    </form>
  </div>
</guild-sidebar>
