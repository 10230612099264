import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[premium]'
})
export class PremiumDirective {
  el: HTMLElement;

  constructor(elementRef?: ElementRef) {
    if (!elementRef) return;

    this.el = elementRef.nativeElement as HTMLInputElement;
    this.el.onclick = () => window.location.href = '/premium';
  }
}
