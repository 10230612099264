<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="fw-bold">Customs Fortnite</h4>
        <div class="slide-toggle">
          <input
            type="checkbox"
            id="check"
            class="slide-toggle-input"
            aria-checked="true"
            formControlName="enabled"
          />
          <label for="check" class="slide-toggle-label"></label>
        </div>
      </div>
      <hr />
      <div class="row g-3">
        <div class="col-lg-6 col-md-12">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-TITLE" | translate }}</label>
              <textarea
                formControlName="titleVerify"
                #titleVerify
                maxlength="256"
              ></textarea>
              <hint align="end">{{ titleVerify.value.length }} / 256</hint>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-DESC" | translate }}</label>
              <textarea
                formControlName="descriptionVerify"
                #descriptionVerify
                maxlength="4096"
              ></textarea>
              <hint align="end"
                >{{ descriptionVerify.value.length }} / 4096</hint
              >
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-THUMNAIL" | translate }}</label>
              <textarea
                formControlName="thumbnailVerify"
                #thumbnailVerify
              ></textarea>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-CHANNEL" | translate }}</label>
              <select formControlName="channelVerify">
                <option
                  *ngFor="let channel of textChannels"
                  [value]="channel.id"
                >
                  #{{ channel.name }}
                  <span class="float-right text-muted"
                    >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
                  >
                </option>
              </select>
            </div>
            <div class="mt-2">
              <embed-preview
                [title]="titleVerify.value"
                [content]="descriptionVerify.value"
                [thumbnail]="thumbnailVerify.value"
                [button]="this.button"
                [guild]="guild"
              ></embed-preview>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-MODE" | translate }}</label>
              <textarea
                formControlName="modeSolo"
                #modeSolo
                maxlength="256"
              ></textarea>
              <hint align="end">{{ modeSolo.value.length }} / 256</hint>
            </div>
            <div>
              <label>Rules</label>
              <textarea
                formControlName="rulesSolo"
                #rulesSolo
                maxlength="4096"
              ></textarea>
              <hint align="end">{{ rulesSolo.value.length }} / 4096</hint>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-THUMNAIL" | translate }}</label>
              <textarea
                formControlName="thumbnailSolo"
                #thumbnailSolo
              ></textarea>
            </div>
            <div>
              <label>Canal</label>
              <select formControlName="channelSolo">
                <option
                  *ngFor="let channel of textChannels"
                  [value]="channel.id"
                >
                  #{{ channel.name }}
                  <span class="float-right text-muted"
                    >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
                  >
                </option>
              </select>
            </div>
            <!-- role -->
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-ROLE" | translate }}</label>
              <select formControlName="roleSolo">
                <option *ngFor="let role of roles" [value]="role.id">
                  <span [style.color]="'#' + role.color?.toString(16)"
                    >&#64;{{ role.name }}</span
                  >
                </option>
                <option *ngFor="let option of optionsArray" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="mt-2">
              <fortniteConfig-preview
                [mode]="modeSolo.value"
                [thumbnail]="thumbnailSolo.value"
                [content]="rulesSolo.value"
                [guild]="guild"
              ></fortniteConfig-preview>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-MODE" | translate }}</label>
              <textarea
                formControlName="modeDuo"
                #modeDuo
                maxlength="256"
              ></textarea>
              <hint align="end">{{ modeDuo.value.length }} / 256</hint>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-RULES" | translate }}</label>
              <textarea
                formControlName="rulesDuo"
                #rulesDuo
                maxlength="4096"
              ></textarea>
              <hint align="end">{{ rulesDuo.value.length }} / 4096</hint>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-THUMNAIL" | translate }}</label>
              <textarea formControlName="thumbnailDuo" #thumbnailDuo></textarea>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-CHANNEL" | translate }}</label>
              <select formControlName="channelDuo">
                <option
                  *ngFor="let channel of textChannels"
                  [value]="channel.id"
                >
                  #{{ channel.name }}
                  <span class="float-right text-muted"
                    >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
                  >
                </option>
              </select>
            </div>
            <!-- role -->
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-ROLE" | translate }}</label>
              <select formControlName="roleDuo">
                <option *ngFor="let role of roles" [value]="role.id">
                  <span [style.color]="'#' + role.color?.toString(16)"
                    >&#64;{{ role.name }}</span
                  >
                </option>
                <option *ngFor="let option of optionsArray" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="mt-2">
              <fortniteConfig-preview
                [mode]="modeDuo.value"
                [thumbnail]="thumbnailDuo.value"
                [content]="rulesDuo.value"
                [guild]="guild"
              ></fortniteConfig-preview>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-MODE" | translate }}</label>
              <textarea
                formControlName="modeTrio"
                #modeTrio
                maxlength="256"
              ></textarea>
              <hint align="end">{{ modeTrio.value.length }} / 256</hint>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-RULES" | translate }}</label>
              <textarea
                formControlName="rulesTrio"
                #rulesTrio
                maxlength="4096"
              ></textarea>
              <hint align="end">{{ rulesDuo.value.length }} / 4096</hint>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-THUMNAIL" | translate }}</label>
              <textarea
                formControlName="thumbnailTrio"
                #thumbnailTrio
              ></textarea>
            </div>
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-CHANNEL" | translate }}</label>
              <select formControlName="channelTrio">
                <option
                  *ngFor="let channel of textChannels"
                  [value]="channel.id"
                >
                  #{{ channel.name }}
                  <span class="float-right text-muted"
                    >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
                  >
                </option>
              </select>
            </div>
            <!-- role -->
            <div>
              <label>{{ "GUILD-FORTNITE.MAT-ROLE" | translate }}</label>
              <select formControlName="roleTrio">
                <option *ngFor="let role of roles" [value]="role.id">
                  <span [style.color]="'#' + role.color?.toString(16)"
                    >&#64;{{ role.name }}</span
                  >
                </option>
                <option *ngFor="let option of optionsArray" [value]="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="mt-2">
              <fortniteConfig-preview
                [mode]="modeTrio.value"
                [thumbnail]="thumbnailTrio.value"
                [content]="rulesTrio.value"
                [guild]="guild"
              ></fortniteConfig-preview>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
