"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var src_1 = require("ramda/src");
var escape = require("escape-string-regexp");
var emoji_1 = require("./emoji");
exports.createMapMdToEmoji = function (emoji) {
  var ret = {};
  for (var md in emoji) {
    ret[":" + md + ":"] = emoji[md];
  }
  return ret;
};
exports.emojiMd = exports.createMapMdToEmoji(emoji_1.default);
exports.createRegExpText = src_1.compose(src_1.concat(':('), src_1.concat(src_1.__, '):'), src_1.join('|'), src_1.map(escape), src_1.keys);
var regexp = new RegExp(exports.createRegExpText(emoji_1.default), 'gm');
exports.textEmoji = src_1.ifElse(src_1.isNil, src_1.always(''), src_1.pipe(src_1.ifElse(src_1.is(String), src_1.identity, src_1.toString), src_1.replace(regexp, src_1.prop(src_1.__, exports.emojiMd))));