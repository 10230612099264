import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endpoint = environment.endpoint;

  get key() { return localStorage.getItem('key'); }

  get user() { return this.userService.user; }

  private _savedUser: any;
  get savedUser() {
    return this._savedUser;
  }

  constructor(private http: HttpClient, private userService: UserService) { }

  async init() {
    this.savedUser;
  }

  getStats(): Promise<any> {
    return this.http.get(`${this.endpoint}/stats?key=${this.key}`).toPromise();
  }

  getSavedBot(): Promise<any> {
    return this.http.get(`${this.endpoint}/bot`).toPromise();
  }

  async sendTwitch(id: string) {
    return this.http.post(`${this.endpoint}/eventsub/suscription/${id}`, { }).toPromise();
  }

  async sendFeedback(arrayFeedback: { feedback: string, user_id: string , avatar: string}[]) {
    return this.http.post(`${this.endpoint}/feedback`, { arrayFeedback }).toPromise();
  }

  async sendTicket(id: string, arrayTicket: { title: string, description: string, thumbnail: string, label: string, style: string }[]) {
    return this.http.post(`${this.endpoint}/${id}/tickets`, { arrayTicket }).toPromise();
  }

  async sendMultiTicket(id: string, arrayTicket: { title: string, description: string, thumbnail: string, buttons: { label: string, style: string }[] }[]) {
    return this.http.post(`${this.endpoint}/${id}/multitickets`, { arrayTicket }).toPromise();
  }

  async deleteGuild(guildId: string): Promise<any> {
    return this.http.post(`${this.endpoint}/${guildId}/delete?key=${this.key}`, {}).toPromise();
  }
}