<navbar></navbar>
<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <h1 class="d-flex align-items-baseline fw-bold">
        <div class="jpcFTu me-2 d-flex justify-content-center align-items-center">
          <svg
            viewBox="0 0 256 192"
            width="56"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="svg-path"
              xmlns="http://www.w3.org/2000/svg"
              d="M 97.782 98.807 Z M 85.814 135.231 L 54.41 135.231 L 33.431 98.807 L 64.706 98.807 L 85.814 135.231 Z M 84.913 98.807 L 74.874 81.304 L 3.185 81.304 L 13.224 98.807 L 34.204 135.231 L 44.371 152.736 L 116.059 152.736 L 106.02 135.231 L 84.913 98.807 Z M 160.076 98.807 Z M 172.046 135.231 L 203.449 135.231 L 224.429 98.807 L 193.152 98.807 L 172.046 135.231 Z M 128.929 167.407 L 204.995 167.407 L 194.954 184.911 L 62.905 184.911 L 52.866 167.407 L 128.929 167.407 Z M 172.946 98.807 L 182.985 81.304 L 254.674 81.304 L 244.634 98.807 L 223.654 135.231 L 213.488 152.736 L 141.799 152.736 L 151.839 135.231 L 172.946 98.807 Z M 128.929 117.728 L 117.99 98.807 L 107.951 81.304 L 128.929 44.881 L 149.908 81.304 L 139.869 98.807 L 128.929 117.728 Z M 118.762 135.231 L 128.929 152.736 L 139.098 135.231 L 160.076 98.807 L 170.114 81.304 L 160.076 63.801 L 128.929 9.873 L 97.782 63.801 L 87.744 81.304 L 97.782 98.807 L 118.762 135.231 Z"
              style="fill: var(--premium)"
            />
          </svg>
        </div>
        PREMIUM
      </h1>
      <p class="lead">{{ "PREMIUM.LEAD" | translate }}</p>
      <p *ngIf="userService.savedUser" class="text-info">
        {{ "PREMIUM.LEAD-1" | translate }}
        <em
          >{{ premiumDaysLeft | number }} {{ "PREMIUM.LEAD-2" | translate }}</em
        >
        {{ "PREMIUM.LEAD-3" | translate }} ESP CUSTOMS+.
      </p>
    </div>
    <div class="d-flex justify-content-center col-sm-6">
      <img
        class="float float-right premium"
        src="assets/img/esp-customs-avatar-transparente.webp"
        loading="lazy"
        alt=""
      />
    </div>
  </div>
  <section id="benefits" class="row py-5">
    <app-premium-card
      duration="1 {{ 'PREMIUM.MONTH' | translate }}"
      [price]="1"
      (checkout)="checkout(0)"
      class="col-sm-4"
    ></app-premium-card>
    <app-premium-card
      duration="3 {{ 'PREMIUM.MONTHS' | translate }}"
      [price]="3"
      (checkout)="checkout(1)"
      class="col-sm-4"
    ></app-premium-card>
    <app-premium-card
      duration="1 {{ 'PREMIUM.YEAR' | translate }}"
      [price]="10"
      (checkout)="checkout(2)"
      class="col-sm-4"
    ></app-premium-card>
  </section>
  <section id="faq mb-5">
    <h2 class="text-center py-2">{{ "PREMIUM.TEX-CENTER" | translate }}</h2>
    <zippy title="{{ 'PREMIUM.ZIPPYTITLE-1' | translate }}">
      {{ "PREMIUM.TITLE-1" | translate }}
    </zippy>
    <zippy title="{{ 'PREMIUM.ZIPPYTITLE-2' | translate }}">
      {{ "PREMIUM.TITLE-2" | translate }}
    </zippy>
    <zippy title="{{ 'PREMIUM.ZIPPYTITLE-3' | translate }}">
      {{ "PREMIUM.TITLE-3" | translate }}
    </zippy>
    <zippy title="{{ 'PREMIUM.ZIPPYTITLE-4' | translate }}">
      {{ "PREMIUM.TITLE-4" | translate }}
    </zippy>
    <zippy title="{{ 'PREMIUM.ZIPPYTITLE-5' | translate }}">
      {{ "PREMIUM.TITLE-5" | translate }}
    </zippy>
  </section>
</div>
