<guild-sidebar>
  <section class="container">
    <div class="row align-items-center">
      <div class="col-lg-3 col-sm-6">
        <div class="d-flex align-items-center justify-content-center stats p-3 m-3 rounded-4 cursor-pointer">
          <span>
            {{ "GUILD.RUNNING" | translate }} {{ commands?.length | number }}
            {{ "GUILD.COMMANDS" | translate }}
          </span>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div
          class="tooltip d-flex align-items-center justify-content-center stats p-3 m-3 rounded-4 cursor-pointer"
        >
          <span class="tooltiptext">{{
            botNeedsPerms
              ? "El bot requiere 'ADMINISTRATOR' para permitir todas las funciones."
              : "El bot tiene 'ADMINISTRADOR' y puede funcionar a su máximo potencial."
          }}</span>
          <span *ngIf="botNeedsPerms">
            {{ "GUILD.PERMISSIONS-MISSING" | translate }}
          </span>
          <span *ngIf="!botNeedsPerms">
            {{ "GUILD.READY" | translate }}
          </span>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="d-flex align-items-center justify-content-center stats p-3 m-3 rounded-4 cursor-pointer">
          <span>{{ guild.channels?.length }} {{ "GUILD.CHANNELS" | translate }}</span>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="d-flex align-items-center justify-content-center stats p-3 m-3 rounded-4 cursor-pointer">
          <span>
            {{ guild.memberCount | number }} {{ "GUILD.MEMBERS" | translate }}
          </span>
        </div>
      </div>
    </div>
    <hr />
    <div class="row align-items-center">
      <div class="col-lg-6 col-sm-12">
        <div class="large stats p-3 m-3 rounded-4">
          <commands-widget></commands-widget>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="large stats p-3 m-3 rounded-4">
          <audit-log-widget></audit-log-widget>
        </div>
      </div>
    </div>
  </section>
</guild-sidebar>
