<ng-container>
  <div class="zippy">
    <div class="zippy-heading" [class.expanded]="expanded" (click)="toggle()">
      <span class="d-flex justify-content-between align-items-center">
        {{ title }}
        <svg
          class="transition-all duration-200 transform"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 512 512"
        >
          <path
            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
            style="fill: var(--font)"
          />
        </svg>
      </span>
    </div>
    <div
      class="zippy-body"
      [@expandCollapse]="expanded ? 'expanded' : 'collapsed'"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>