import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-premium-card',
  templateUrl: './premium-card.component.html',
  styleUrls: ['./premium-card.component.css']
})
export class PremiumCardComponent {
  @Input() duration: string;
  @Input() price: number;
  @Output() checkout = new EventEmitter();

  constructor(public translate: TranslateService,
    public userService: UserService
  ) {}
}
