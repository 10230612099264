<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <h4 routerLink="/docs/leveling" class="fw-bold">
            {{ "GUILD-LEVELS.TITLE" | translate }}
          </h4>
          <div class="slide-toggle">
            <input
              type="checkbox"
              id="check"
              class="slide-toggle-input"
              aria-checked="true"
              formControlName="enabled"
            />
            <label for="check" class="slide-toggle-label"></label>
          </div>
        </div>
        <hr />
      </div>
      <div class="row g-3">
        <div class="col-lg-4 col-md-6">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <label>{{ "GUILD-LEVELS.MAT-LEVELS" | translate }}</label>
            <mat-select multiple formControlName="ignoredRoles">
              <mat-option *ngFor="let role of roles" [value]="role.id">
                <div
                  [style.color]="'#' + role.color?.toString(16) + '!important'"
                >
                  &#64;{{ role.name }}
                </div>
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <label>{{ "GUILD-LEVELS.MAT-XP" | translate }}</label>
            <input type="number" formControlName="xpPerMessage" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <label class="d-block">{{
              "GUILD-LEVELS.MAT-MSG-MAXIMUMS-PER-MINUTE" | translate
            }}</label>
            <slider class="d-flex gap-2">
              <input
                type="range"
                step="1"
                min="0"
                max="30"
                formControlName="maxMessagesPerMinute"
              />
              <output>{{ this.form.value.maxMessagesPerMinute }}</output>
            </slider>
          </div>
        </div>
      </div>
      <hr />
      <h3>{{ "GUILD-LEVELS.TITLE-H3" | translate }}</h3>
      <p class="desc">{{ "GUILD-LEVELS.TITLE-H3-DESC" | translate }}</p>
      <div formArrayName="levelRoles" class="row g-3">
        <div *ngFor="let i of levelRoleIndices" class="col-lg-6 col-sm-12">
          <div
            class="rounded p-3"
            style="background-color: var(--background-secondary)"
          >
            <div
              [formGroupName]="i"
              class="rounded role d-flex justify-content-around row"
            >
              <div class="col-sm-6">
                <label>{{ "GUILD-LEVELS.MAT-LEVEL" | translate }}</label>
                <input type="number" formControlName="level" #level />
              </div>
              <div class="col-sm-6">
                <label>{{ "GUILD-LEVELS.MAT-ROLE" | translate }}</label>
                <select formControlName="role">
                  <option *ngFor="let role of roles" [value]="role.id">
                    <span [style.color]="'#' + role.color?.toString(16)"
                      >&#64;{{ role.name }}</span
                    >
                  </option>
                </select>
              </div>
              <div
                [@expandCollapse]="
                  parseStringToInt(level.value) > 0 ? 'down' : 'up'
                "
                class="px-4"
                [class]="parseStringToInt(level.value) > 0 ? 'pb-3' : ''"
              >
                {{ "GUILD-LEVELS.DIV-CLASS" | translate }}
                {{ getXPForLevel(level.value) | number }}XP
                {{ "GUILD-LEVELS.DIV-CLASS-1" | translate }}
                {{ getHoursForLevel(parseStringToInt(level.value)) | number }}
                {{ "GUILD-LEVELS.HRS" | translate }}
                {{ "GUILD-LEVELS.DIV-CLASS-2" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
