import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModuleConfig } from '../../../../module-config';
import { GuildService } from '../../../../services/guild.service';
import { UserService } from '../../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "youtube-module",
  templateUrl: "./youtube-module.component.html",
  styleUrls: ["./youtube-module.component.css"],
})
export class YoutubeModuleComponent extends ModuleConfig implements OnInit {
  moduleName = "social";

  get user() {
    return this.userService.user;
  }
  get social() {
    return this.savedGuild.social;
  }

  buildForm({ social }: any) {
    const form = new FormGroup({
      twitch: new FormGroup({
        enabled: new FormControl(social.twitch.enabled ?? false),
        channel: new FormControl(social.twitch.channel),
        message: new FormControl(social.twitch.message),
        streamers: new FormControl(social.twitch.streamers)
      }),
      youtube: new FormGroup({
        enabled: new FormControl(Boolean ?? false),
        channel: new FormControl(""),
        message: new FormControl(""),
        youtubers: new FormControl([])
      })
    });
    form.patchValue(social);
    return form;
  }
  constructor(
    public translate: TranslateService,
    guildService: GuildService,
    route: ActivatedRoute,
    userService: UserService,
    saveChanges: MatSnackBar
  ) {
    super(guildService, route, userService, saveChanges);
  }
  async ngOnInit() {
    await super.init();
  }
}