import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  environment = environment;
  privacy = 'privacy-policy';
  statusURL = 'https://espcustoms.statuspage.io';
  version = environment.version;
  anchorVersion = environment.version.replace(/\./g, '');
  CurrentYear = new Date().getFullYear();

  constructor(public translate: TranslateService) {
    document.title = 'ESP CUSTOMS - Discord App';
  }
}