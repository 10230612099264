<navbar></navbar>
<div *ngIf="guild" class="container">
	<div>
		<img class="guild-icon" *ngIf="iconURL" [src]="iconURL" loading="lazy" [alt]="guild.name" />
		<h4 class="fw-bold">{{ guild.name }}</h4>
		<hr />
	</div>
	<div class="Page Panel">
		<div id="leaderboard">
			<div *ngFor="let member of members" class="LeaderboardPanel">
				<span [class]="'rank-' + (members.indexOf(member) + 1)" class="Panel LeaderboardItem"
					style="width: 40px; height: 50px">{{ members.indexOf(member) + 1 }}</span>
				<member-username [member]="member" class="Panel LeaderboardItem LeaderboardName"
					style="padding: 0 10px 0 0 !important"></member-username>
				<div class="Panel LeaderboardItem LeaderboardLevel">
					<span>LVL {{ member.level }}</span>
				</div>
				<div class="d-md-block d-none Panel LeaderboardItem LeaderboardXP">
					<span>{{ member.xp | number }}/{{
						member.xp + member.xpForNextLevel | number
						}}
						XP</span>
				</div>
			</div>
		</div>
	</div>
</div>