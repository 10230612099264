export function toIterable(length: number) {
  const array = [];
  for (let i = 0; i < length; i++)
    array.push(i);
  return array;
}

export function camelToSentenceCase(words: string) {
  const regex = /([A-Z])(?=[a-z][A-Z])|([a-z])(?=[A-Z])/g;
  return words
    .replace(regex, '$& ')[0]
    .toUpperCase() +
    words
      .replace(regex, '$& ')
      .slice(1);
}

export function sentenceToCamelCase(words: string) {
  const capitalized = words
    .replace(/\s+(.)/g, (match, group) => group.toUpperCase());
  return capitalized[0].toLowerCase() + capitalized.slice(1);
}

export function kebabToCamelCase(words: string) {
  if (!words) return null;

  const arr = words.split('-');
  const capital = arr.map((item, index) => (index)
    ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item);

  const capitalized = capital.join('');
  return capitalized[0].toLowerCase() + capitalized.slice(1);
}

export function generateRandomNumericCode(length: number): string {
  const digits = "0123456789";
  let code = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    code += digits[randomIndex];
  }

  return code;
}

export function amount(coins: number) {
  const multiplier = coins >= 10 ? 1.85 : 1.90;
  return (coins * multiplier);
}