import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModuleConfig } from '../../../module-config';
import { GuildService } from '../../../services/guild.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: "app-general-module",
  templateUrl: "./general-module.component.html",
  styleUrls: ["./general-module.component.css"],
})
export class GeneralModuleComponent extends ModuleConfig implements OnInit {
  moduleName = "general";

  get general() {
    return this.savedGuild.general;
  }

  constructor(
    public translate: TranslateService,
    guildService: GuildService,
    route: ActivatedRoute,
    userService: UserService,
    saveChanges: MatSnackBar
  ) {
    super(guildService, route, userService, saveChanges);
  }

  async ngOnInit() {
    await super.init();
  }

  buildForm({ general }: any) {
    const form = new FormGroup({
      enabled: new FormControl(Boolean),
      prefix: new FormControl("", [
        Validators.required,
        Validators.maxLength(5),
      ]),
      ignoredChannels: new FormControl([]),
    });
    form.patchValue(general);
    return form;
  }
}
