import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModuleConfig } from "../../../module-config";
import { GuildService } from "../../../services/guild.service";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../services/api.service";
import { UserService } from "../../../services/user.service";

@Component({
  selector: "customize-ticket",
  templateUrl: "./customize-ticket.component.html",
  styleUrls: ["./customize-ticket.component.css"],
})

export class CustomizeTciketsComponent extends ModuleConfig implements OnInit {
  moduleName = "embedticket";
  ticketMainEmbedIndices: number[] = [];
  multipleTicketsIndices: number[] = [];
  multipleTicketsButtons: number[] = [];

  selectedButtonStyles: { [key: string]: string } = {}; // Objeto para almacenar los estilos de los botones seleccionados

  get embedticket() {
    return this.savedGuild.embedticket;
  }

  constructor(
    public translate: TranslateService,
    public apiService: ApiService,
    guildService: GuildService,
    route: ActivatedRoute,
    userService: UserService,
    saveChanges: MatSnackBar
  ) {
    super(guildService, route, userService, saveChanges);
  }

  async ngOnInit() {
    await this.guildService.init();
    await super.init();
    await this.apiService.init();
  }

  async buildForm({ embedticket }: any) {
    const formGroup = new FormGroup({
      enabled: new FormControl(Boolean),
      ticketMainEmbed: new FormArray([]),
      multipleTickets: new FormArray([]),
      categoryId: new FormControl(""),
      closedCategory: new FormControl(""),
      archiveCategory: new FormControl(""),
      claimedCategory: new FormControl(""),
      moderatorRole: new FormControl(""),
      channelTicketEmbed: new FormControl(""),
    });

    for (const i in embedticket.ticketMainEmbed) {
      this.ticketMainEmbedIndices.push(Number(i));
    }

    for (const i of this.ticketMainEmbedIndices)
      (formGroup.get("ticketMainEmbed") as FormArray).setControl(i,
        new FormGroup({
          title: new FormControl(embedticket.ticketMainEmbed[i]?.title ?? ""),
          description: new FormControl(embedticket.ticketMainEmbed[i]?.description ?? ""),
          thumbnail: new FormControl(embedticket.ticketMainEmbed[i]?.thumbnail ?? ""),
          button: new FormGroup({
            label: new FormControl(embedticket.ticketMainEmbed[i]?.button?.label ?? ""),
            customId: new FormControl(embedticket.ticketMainEmbed[i]?.button?.customId ?? "Ticket"),
            style: new FormControl(embedticket.ticketMainEmbed[i]?.button?.style ?? "PRIMARY"),
            display: new FormControl(true),
          }),
          ticketSupportEmbed: new FormGroup({
            title: new FormControl(embedticket.ticketMainEmbed[i]?.ticketSupportEmbed?.title ?? ""),
            description: new FormControl(embedticket.ticketMainEmbed[i]?.ticketSupportEmbed?.description ?? ""),
            thumbnail: new FormControl(embedticket.ticketMainEmbed[i]?.ticketSupportEmbed?.thumbnail ?? ""),
          }),
        })
      );

    for (const i in embedticket.multipleTickets) {
      this.multipleTicketsIndices.push(Number(i));

      for (const y in embedticket.multipleTickets[i].buttons) {
        this.multipleTicketsButtons.push(Number(y));
      }
    }

    for (const i of this.multipleTicketsIndices) {
      const ticketFormGroup = new FormGroup({
        title: new FormControl(embedticket.multipleTickets[i]?.title ?? ""),
        description: new FormControl(embedticket.multipleTickets[i]?.description ?? ""),
        thumbnail: new FormControl(embedticket.multipleTickets[i]?.thumbnail ?? ""),
        buttons: new FormArray([]) // Creamos un FormArray vacío para los botones
      });

      const buttonsArray = ticketFormGroup.get("buttons") as FormArray;

      for (const y of this.multipleTicketsButtons) {
        const buttonFormGroup = new FormGroup({
          label: new FormControl(embedticket.multipleTickets[i]?.buttons[y]?.label ?? ""),
          customId: new FormControl(embedticket.multipleTickets[i]?.buttons[y]?.customId ?? ""),
          style: new FormControl(embedticket.multipleTickets[i]?.buttons[y]?.style ?? "PRIMARY"),
          display: new FormControl(true),
        });

        buttonsArray.push(buttonFormGroup); // Agregamos el FormGroup del botón al FormArray
      }

      (formGroup.get("multipleTickets") as FormArray).setControl(i, ticketFormGroup);
    }

    formGroup.patchValue(embedticket);
    return formGroup;
  }

  override async submit() {
    await super.submit();
  }

  addNewTicketMainEmbed(title: string, description: string, thumbnail: string, label: string, style: string, stitle: string, sdescription: string, sthumbnail: string) {
    const ticketMainEmbedArray = this.form.get("ticketMainEmbed") as FormArray;
    ticketMainEmbedArray.push(
      new FormGroup({
        title: new FormControl(title),
        description: new FormControl(description),
        thumbnail: new FormControl(thumbnail),
        button: new FormGroup({
          label: new FormControl(label),
          customId: new FormControl("Ticket"),
          style: new FormControl(style),
          display: new FormControl(true),
        }),
        ticketSupportEmbed: new FormGroup({
          title: new FormControl(stitle),
          description: new FormControl(sdescription),
          thumbnail: new FormControl(sthumbnail),
        }),
      })
    );
  }

  getSelectedButtons(multipleTicketsbuttons: any): any[] {
    return multipleTicketsbuttons.selected.map((option: any) => option.value);
  }

  // Método para agregar un nuevo ticket múltiple
  addNewMultipleTickets(Mtitle: string, Mdescription: string, Mthumbnail: string, Mbuttons: string[]) {
    const multipleTicketsArray = this.form.get("multipleTickets") as FormArray;
    const buttonsFormArray = new FormArray([]);

    const ticketMainEmbedArray = this.form.get("ticketMainEmbed") as FormArray;
    const ticketMainEmbedValues = ticketMainEmbedArray.value;

    for (const title of Mbuttons) {
      // Buscar el ticketMainEmbed correspondiente al título seleccionado
      const ticketMainEmbed = ticketMainEmbedValues.find((ticket: any) => ticket.title === title);
      if (ticketMainEmbed) {
        buttonsFormArray.push(
          new FormGroup({
            label: new FormControl(ticketMainEmbed.button.label),
            customId: new FormControl(ticketMainEmbed.button.label),
            style: new FormControl(ticketMainEmbed.button.style),
            display: new FormControl(true),
          })
        );
      }
    }

    const ticketFormGroup = new FormGroup({
      title: new FormControl(Mtitle),
      description: new FormControl(Mdescription),
      thumbnail: new FormControl(Mthumbnail),
      buttons: buttonsFormArray,
    });

    multipleTicketsArray.push(ticketFormGroup);
  }

  removeTicketMainEmbed(i: number) {
    this.ticketMainEmbedIndices.splice(i, 1);
    const formTicketsArray = this.form.get('ticketMainEmbed') as FormArray;
    formTicketsArray.removeAt(i);
  }

  removeMultipleTickets(i: number) {
    this.multipleTicketsIndices.splice(i, 1);
    const formMultiTicketsArray = this.form.get('multipleTickets') as FormArray;
    formMultiTicketsArray.removeAt(i);
  }

  get ticketTitles(): string[] {
    return this.form.get('ticketMainEmbed').value.map((ticket: any) => ticket.title);
  }

  sendMultiTicket(ticket: any) {
    if (!ticket.buttons) {
      ticket.buttons = []; // Inicializa "buttons" como un array vacío si no está definido
    }

    const arrayTicket = {
      title: ticket.title,
      description: ticket.description,
      thumbnail: ticket.thumbnail,
      buttons: ticket.buttons.map((button: any) => ({
        label: button.label,
        style: button.style
      }))
    };

    this.apiService.sendMultiTicket(this.guildId, [arrayTicket]);
  }

}