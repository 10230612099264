<guild-sidebar>
  <div class="container">
    <header>
      <h4 class="fw-bold">{{ "GUILD-LOGS.TITLE" | translate }}</h4>
      <hr class="my-2" />
    </header>
    <div class="d-flex justify-content-center" appearance="outline">
      <label>{{ "GUILD-LOGS.MAT-FILTER" | translate }}</label>
      <input type="text" (keyup)="applyFilter($event)" placeholder="prefix" />
    </div>

    <div *ngIf="dataSource" class="custom-table-container">
      <table style="width: 100%">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ "GUILD-LOGS.MAT-FOR" | translate }}</th>
            <th>{{ "GUILD-LOGS.MAT-OLD" | translate }}</th>
            <th>{{ "GUILD-LOGS.MAT-NEW" | translate }}</th>
            <th>{{ "GUILD-LOGS.MAT-TO" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of dataSource; let i = index">
            <td>{{ changes.length - i }}</td>
            <td class="user">
              <member-username [member]="getMember(row.by)"></member-username>
            </td>
            <td>
              <pre><code>{{row.changes?.old | json }}</code></pre>
            </td>
            <td>
              <pre><code>{{row.changes?.new | json }}</code></pre>
            </td>
            <td>{{ row.at | cleanDateTime }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-controls">
        <button
          (click)="changePage(currentPage - 1)"
          [disabled]="currentPage === 1"
        >
          Anterior
        </button>
        <span
          >Página {{ currentPage }} de
          {{ Math.ceil(totalItems / itemsPerPage) }}</span
        >
        <button
          (click)="changePage(currentPage + 1)"
          [disabled]="currentPage === Math.ceil(totalItems / itemsPerPage)"
        >
          Siguiente
        </button>

        <label for="itemsPerPage">Elementos por página:</label>
        <select
          id="itemsPerPage"
          [(ngModel)]="itemsPerPage"
          (change)="changeItemsPerPage($event)"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  </div>
</guild-sidebar>
