<guild-sidebar [waitFor]="savedGuild">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div formGroupName="youtube">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="fw-bold">Youtube</h4>
          <div class="slide-toggle">
            <input
              type="checkbox"
              id="check"
              class="slide-toggle-input"
              aria-checked="true"
              formControlName="enabled"
            />
            <label for="check" class="slide-toggle-label"></label>
          </div>
        </div>
        <hr />
        <div class="alert alert-warning rounded-4" role="alert">
          {{ "GUILD-YOUTUBE.ALERT-INFO" | translate }}
        </div>
        <div
          class="rounded gap-3 col-lg-12 col-md-12 p-3"
          style="background-color: var(--background-secondary)"
        >
          <label>{{ "GUILD-YOUTUBE.SEND-MESSAGE" | translate }}</label>
          <textarea
            formControlName="message"
            #message
            maxlength="256"
          ></textarea>
          <hint align="end">{{ message.value.length }} / 256</hint>
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
