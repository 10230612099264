<div class="row">
    <div class="col-md-3">
        <h5>{{ 'RECENTS-COMMANDS' | translate}}</h5>
    </div>
    <div class="col-md-9">
        <canvas *ngIf="chartReady" baseChart
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType">
        </canvas>
    </div>
</div>