import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModuleConfig } from '../../../module-config';
import { GuildService } from '../../../services/guild.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: "app-welcome-module",
  templateUrl: "./welcome-module.component.html",
  styleUrls: ["./welcome-module.component.css"],
})
export class WelcomeModuleComponent extends ModuleConfig implements OnInit {
  moduleName = "welcome";

  canvacardFonts: any[];

  welcomeCardPreviewURL: string;
  button = { label: "", customId: "", style: "", display: false };

  buttonClicked: string;
  colorspicker = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6', 'color7', 'color8', 'color9', 'color10'];
  arrayColors = {
    color1: "#ffffff",
    color2: "#607d8a",
    color3: "#ff7673",
    color4: "#ffbb5c",
    color5: "#ffd74e",
    color6: "#6de194",
    color7: "#63ecdb",
    color8: "#5acff5",
    color9: "#70b1ff",
    color10: "#b072ff",
  };
  colors = {
    primary: "#FFFFFF",
    secondary: "#5865F2",
    tertiary: "#FFFFFF",
    quarter: "#5865F2",
  };
  types = {
    option1: 'RECTANGLE',
    option2: 'ROUNDED',
  };
  fonts = [];
  colorPickers = [];
  get welcome() {
    return this.savedGuild.welcome;
  }
  get primary() {
    return this.form.get("card.primary");
  }
  get secondary() {
    return this.form.get("card.secondary");
  }
  get tertiary() {
    return this.form.get("card.tertiary");
  }
  get quarter() {
    return this.form.get("card.quarter");
  }
  get backgroundURL() {
    return this.form.get("card.backgroundURL");
  }
  get titulo() {
    return this.form.get("card.titulo");
  }
  get subtitulo() {
    return this.form.get("card.subtitulo");
  }
  get opacityOverlay() {
    return this.form.get("card.opacityOverlay");
  }
  get typeOverlay() {
    return this.form.get("card.typeOverlay");
  }
  get fontFamily() {
    return this.form.get("card.fontFamily");
  }
  get messageEnabled() {
    return this.form.get("message.enabled");
  }
  get embedEnabled() {
    return this.form.get("embed.enabled");
  }
  get color() {
    return this.form.get("embed.color");
  }

  buildForm({ welcome }: any) {
    const form = new FormGroup({
      enabled: new FormControl(Boolean),
      card: new FormGroup({
        enabled: new FormControl(Boolean),
        backgroundURL: new FormControl(""),
        titulo: new FormControl(""),
        subtitulo: new FormControl("", Validators.maxLength(512)),
        opacityOverlay: new FormControl(Number, [
          Validators.min(0.0), Validators.max(1)
        ]),
        typeOverlay: new FormControl(""),
        primary: new FormControl(""),
        secondary: new FormControl(""),
        tertiary: new FormControl(""),
        quarter: new FormControl(""),
        fontFamily: new FormControl(""),
      }),
      message: new FormGroup({
        enabled: new FormControl(Boolean),
        content: new FormControl(""),
      }),
      embed: new FormGroup({
        enabled: new FormControl(Boolean),
        title: new FormControl(""),
        description: new FormControl(""),
        color: new FormControl(""),
        thumbnail: new FormControl(""),
        image: new FormControl(""),
        footer: new FormControl(""),
      }),
      channel: new FormControl(""),
      autoRoles: new FormControl([]),
    });
    form.patchValue(welcome);
    return form;
  }

  constructor(
    public translate: TranslateService,
    guildService: GuildService,
    route: ActivatedRoute,
    userService: UserService,
    saveChanges: MatSnackBar,
  ) {
    super(guildService, route, userService, saveChanges);
  }

  async ngOnInit() {
    await super.init();
    // Inicializa los valores del formulario
    this.initFormValues();
    // Actualiza la vista previa del WelcomeCard
    this.updateWelcomeCard();
    // Carga las fuentes de Google Fonts
    this.loadCanvacardFonts();
    // Puedes cargar los roles desde tu servicio o cualquier otra fuente
    const roles = [];

    // Añade un control para cada rol en el FormArray
    roles.forEach(() => {
      this.addRoleControl();
    });
  };

  // Método para añadir un control para el rol en el FormArray
  async addRoleControl() {
    const control = new FormControl(false); // Inicializa con falso para desmarcar por defecto
    (this.form.get('autoRoles') as FormArray).push(control);
  }

  // Método para cambiar los valores de los colores de los botones
  async submitColor() {
    switch (this.buttonClicked) {
      case "WelcomeCard primary color1":
        this.primary.setValue(this.arrayColors.color1);
        break;
      case "WelcomeCard primary color2":
        this.primary.setValue(this.arrayColors.color2);
        break;
      case "WelcomeCard primary color3":
        this.primary.setValue(this.arrayColors.color3);
        break;
      case "WelcomeCard primary color4":
        this.primary.setValue(this.arrayColors.color4);
        break;
      case "WelcomeCard primary color5":
        this.primary.setValue(this.arrayColors.color5);
        break;
      case "WelcomeCard primary color6":
        this.primary.setValue(this.arrayColors.color6);
        break;
      case "WelcomeCard primary color7":
        this.primary.setValue(this.arrayColors.color7);
        break;
      case "WelcomeCard primary color8":
        this.primary.setValue(this.arrayColors.color8);
        break;
      case "WelcomeCard primary color9":
        this.primary.setValue(this.arrayColors.color9);
        break;
      case "WelcomeCard primary color10":
        this.primary.setValue(this.arrayColors.color10);
        break;
      case "WelcomeCard secondary color1":
        this.secondary.setValue(this.arrayColors.color1);
        break;
      case "WelcomeCard secondary color2":
        this.secondary.setValue(this.arrayColors.color2);
        break;
      case "WelcomeCard secondary color3":
        this.secondary.setValue(this.arrayColors.color3);
        break;
      case "WelcomeCard secondary color4":
        this.secondary.setValue(this.arrayColors.color4);
        break;
      case "WelcomeCard secondary color5":
        this.secondary.setValue(this.arrayColors.color5);
        break;
      case "WelcomeCard secondary color6":
        this.secondary.setValue(this.arrayColors.color6);
        break;
      case "WelcomeCard secondary color7":
        this.secondary.setValue(this.arrayColors.color7);
        break;
      case "WelcomeCard secondary color8":
        this.secondary.setValue(this.arrayColors.color8);
        break;
      case "WelcomeCard secondary color9":
        this.secondary.setValue(this.arrayColors.color9);
        break;
      case "WelcomeCard secondary color10":
        this.secondary.setValue(this.arrayColors.color10);
        break;
      case "WelcomeCard tertiary color1":
        this.tertiary.setValue(this.arrayColors.color1);
        break;
      case "WelcomeCard tertiary color2":
        this.tertiary.setValue(this.arrayColors.color2);
        break;
      case "WelcomeCard tertiary color3":
        this.tertiary.setValue(this.arrayColors.color3);
        break;
      case "WelcomeCard tertiary color4":
        this.tertiary.setValue(this.arrayColors.color4);
        break;
      case "WelcomeCard tertiary color5":
        this.tertiary.setValue(this.arrayColors.color5);
        break;
      case "WelcomeCard tertiary color6":
        this.tertiary.setValue(this.arrayColors.color6);
        break;
      case "WelcomeCard tertiary color7":
        this.tertiary.setValue(this.arrayColors.color7);
        break;
      case "WelcomeCard tertiary color8":
        this.tertiary.setValue(this.arrayColors.color8);
        break;
      case "WelcomeCard tertiary color9":
        this.tertiary.setValue(this.arrayColors.color9);
        break;
      case "WelcomeCard tertiary color10":
        this.tertiary.setValue(this.arrayColors.color10);
        break;
      case "WelcomeCard quarter color1":
        this.quarter.setValue(this.arrayColors.color1);
        break;
      case "WelcomeCard quarter color2":
        this.quarter.setValue(this.arrayColors.color2);
        break;
      case "WelcomeCard quarter color3":
        this.quarter.setValue(this.arrayColors.color3);
        break;
      case "WelcomeCard quarter color4":
        this.quarter.setValue(this.arrayColors.color4);
        break;
      case "WelcomeCard quarter color5":
        this.quarter.setValue(this.arrayColors.color5);
        break;
      case "WelcomeCard quarter color6":
        this.quarter.setValue(this.arrayColors.color6);
        break;
      case "WelcomeCard quarter color7":
        this.quarter.setValue(this.arrayColors.color7);
        break;
      case "WelcomeCard quarter color8":
        this.quarter.setValue(this.arrayColors.color8);
        break;
      case "WelcomeCard quarter color9":
        this.quarter.setValue(this.arrayColors.color9);
        break;
      case "WelcomeCard quarter color10":
        this.quarter.setValue(this.arrayColors.color10);
        break;
      case "EmbedCard color1":
        this.color.setValue(this.arrayColors.color1);
        break;
      case "EmbedCard color2":
        this.color.setValue(this.arrayColors.color2);
        break;
      case "EmbedCard color3":
        this.color.setValue(this.arrayColors.color3);
        break;
      case "EmbedCard color4":
        this.color.setValue(this.arrayColors.color4);
        break;
      case "EmbedCard color5":
        this.color.setValue(this.arrayColors.color5);
        break;
      case "EmbedCard color6":
        this.color.setValue(this.arrayColors.color6);
        break;
      case "EmbedCard color7":
        this.color.setValue(this.arrayColors.color7);
        break;
      case "EmbedCard color8":
        this.color.setValue(this.arrayColors.color8);
        break;
      case "EmbedCard color9":
        this.color.setValue(this.arrayColors.color9);
        break;
      case "EmbedCard color10":
        this.color.setValue(this.arrayColors.color10);
        break;
      default:
        // Acción por defecto si no se encuentra una coincidencia
        break;
    }
  }

  // Inicializa los valores del formulario
  async initFormValues() {
    const welcomeCard = this.savedGuild.welcome.card;
    this.colorPickers = [
      welcomeCard.primary ?? this.colors.primary,
      welcomeCard.secondary ?? this.colors.secondary,
      welcomeCard.tertiary ?? this.colors.tertiary,
      welcomeCard.quarter ?? this.colors.quarter,
    ];
    this.backgroundURL.setValue(welcomeCard.backgroundURL);
    this.titulo.setValue(welcomeCard.titulo);
    this.subtitulo.setValue(welcomeCard.subtitulo);
    this.opacityOverlay.setValue(welcomeCard.opacityOverlay);
    this.typeOverlay.setValue(welcomeCard.typeOverlay ?? this.types);
    this.fontFamily.setValue(welcomeCard.fontFamily ?? this.fonts);
  }

  // Método para actualizar la vista previa del WelcomeCard
  async updateWelcomeCard() {
    this.setValues();

    const primary = this.hexToRGB(this.primary.value);
    const secondary = this.hexToRGB(this.secondary.value);
    const tertiary = this.hexToRGB(this.tertiary.value);
    const quarter = this.hexToRGB(this.quarter.value);
    const backgroundURL = this.backgroundURL.value;
    const titulo = this.titulo.value;
    const subtitulo = this.subtitulo.value;
    const opacityOverlay = this.opacityOverlay.value;
    const typeOverlay = this.typeOverlay.value;
    const fontFamily = this.fontFamily.value;

    const member = await this.guildService.getMember(this.guildId, this.userService.user.id);
    const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    this.welcomeCardPreviewURL = this.guildService.getWELCOMECardPreviewURL(this.guildId, member,
      { primary, secondary, tertiary, quarter, backgroundURL, titulo, subtitulo, opacityOverlay, typeOverlay, fontFamily }) + `&cache=${randomString}`;
  }

  // Método para establecer los valores de los colores
  private setValues() {
    this.primary.setValue(this.colorPickers[0]);
    this.secondary.setValue(this.colorPickers[1]);
    this.tertiary.setValue(this.colorPickers[2]);
    this.quarter.setValue(this.colorPickers[3]);
  }

  // Método para convertir un color hexadecimal a RGB
  private hexToRGB(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return "";
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return `rgb(${r},${g},${b})`;
  }

  // Método para cargar los nombres de archivo de las fuentes de Assets Canvacard
  async loadCanvacardFonts() {
    try {
      const response = await fetch('https://raw.githubusercontent.com/SrGobi/canvacard/assets/datasrc.json');
      const data = await response.json();

      if (data && data.data.fonts) {
        // Obtener solo los nombres de archivo de las fuentes
        this.canvacardFonts = data.data.fonts.map(font => font.file_name);
      }

      // Actualiza tu lista de fuentes con los nombres de archivo de las fuentes disponibles de Assets Canvacard
      this.fonts = this.canvacardFonts;
    } catch (error) {
      console.error('Error loading Canvacard Fonts:', error);
    }
  }
}

//https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyD91tVC6XDEmNBzY3bMcWGtnJmQNDPWtuo