import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GuildService } from '../../../services/guild.service';

@Component({
  selector: 'app-log-module',
  templateUrl: './log-module.component.html',
  styleUrls: ['./log-module.component.css']
})
export class LogModuleComponent implements OnInit {
  members: any[];

  Math = Math;

  displayedColumns: string[] = ['number', 'by', 'old', 'new', 'at'];
  dataSource: any[] = [];
  changes: any[] = [];

  // Nuevas propiedades para la paginación personalizada
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private guildService: GuildService
  ) { }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

    const log = await this.guildService.getSavedLog(id);
    this.changes = log.changes.reverse();

    this.members = await this.guildService.getMembers(id);

    this.totalItems = this.changes.length;

    // Actualiza el dataSource con los elementos de la página actual
    this.updateDataSource();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    // Aplica el filtro a la lista original de cambios
    const filteredChanges = this.changes.filter(item =>
      // Verifica si item y item.changes existen y si incluyen la palabra del filtro
      item && this.objectContainsFilterValue(item, filterValue)
    );

    // Actualiza la lista filtrada y vuelve a calcular la paginación
    this.totalItems = filteredChanges.length;
    this.updateDataSource(filteredChanges);
  }

  // Método para verificar si el objeto contiene la palabra del filtro
  objectContainsFilterValue(obj: any, filterValue: string): boolean {
    const strObj = JSON.stringify(obj).toLowerCase();
    return strObj.indexOf(filterValue) !== -1;
  }

  getMember(id: string) {
    return this.members.find(m => m.id === id) || {};
  }

  // Método para actualizar el dataSource con los elementos de la página actual
  updateDataSource(changes?: any[]) {
    const filteredChanges = changes || this.changes; // Usa los cambios filtrados si se proporcionan, de lo contrario, usa la lista completa

    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    this.dataSource = filteredChanges.slice(startIndex, endIndex);
  }

  // Método para cambiar la página
  changePage(newPage: number) {
    this.currentPage = newPage;
    this.updateDataSource();
  }

  // Método para cambiar la cantidad de elementos por página
  changeItemsPerPage(event: any) {
    const target = event.target as HTMLSelectElement;
    this.itemsPerPage = +target.value; // Convierte el valor a número
    this.currentPage = 1;
    this.updateDataSource();
  }
}