import { AfterViewInit, Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare interface Window {
  adsbygoogle: any[];
}
declare var adsbygoogle: any[];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit {
  environment = environment;
  privacy = 'privacy-policy';
  statusURL = 'https://espcustoms.statuspage.io';
  version = environment.version;
  anchorVersion = environment.version.replace(/\./g, '');
  CurrentYear = new Date().getFullYear();

  constructor(public translate: TranslateService) {
    document.title = 'ESP CUSTOMS - Discord App';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      try {
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
      } catch (e) {
        console.error("ads", e);
      }
    }, 900);
  }
}
