<div class="rounded d-flex flex-column whitney theme-dark">
	<div class="rounded chat d-flex flex-column flex-spacer">
		<div class="rounded content flex-spacer flex-horizontal">
			<div class="rounded flex-spacer d-flex flex-column messages-wrapper">
				<div class="message-group overflow-hidden">
					<div class="avatar-large animate" [style.backgroundImage]="'url(' + author.avatarURL + ')'"></div>
					<div class="comment">
						<div class="message first">
							<h2 style="line-height: 16px;">
								<span class="username-wrapper v-btm">
									<strong class="user-name">{{ member.displayName }}</strong>
									<span class="bot-tag">✓ {{ "EMBED.APP" | translate }}</span>
								</span>
								<span class="timestamp">{{ timestamp }}</span>
							</h2>
							<div [innerHtml]="processed" class="message-text"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>