<guild-sidebar [waitFor]="savedGuild && form">
  <div *ngIf="form" class="container">
    <form #f="ngForm" [formGroup]="form">
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <h4 routerLink="/docs/announce" class="fw-bold">
            {{ "GUILD-LOGS2.TITLE" | translate }}
          </h4>
          <div class="slide-toggle">
            <input
              type="checkbox"
              id="check"
              class="slide-toggle-input"
              aria-checked="true"
              formControlName="enabled"
            />
            <label for="check" class="slide-toggle-label"></label>
          </div>
        </div>
        <hr />
      </div>
      <p href="#eventos-variables" class="desc">
        {{ "GUILD-LOGS2.DESC" | translate }}
      </p>
      <div formArrayName="events" class="row g-3">
        <div
          *ngFor="let event of events; index as i"
          class="col-lg-6 col-sm-12"
        >
          <div
            [formGroupName]="i"
            class="rounded p-3 large shadow"
            style="background-color: var(--background-secondary)"
          >
            <input type="hidden" formControlName="event" />
            <span class="d-flex justify-content-between">
              <strong>{{ events[i] | snakeToSentenceCase }}</strong>
              <div class="slide-toggle">
                <input
                  type="checkbox"
                  [id]="i"
                  class="slide-toggle-input"
                  aria-checked="true"
                  formControlName="enabled"
                />
                <label [htmlFor]="i" class="slide-toggle-label"></label>
              </div>
            </span>
            <hr />
            <div class="d-flex flex-column">
              <label>{{ "GUILD-LOGS2.MAT-MSG" | translate }}</label>
              <textarea formControlName="message" #message maxlength="2000">{{
                getEvent(events[i])?.message
              }}</textarea>
              <hint align="end">{{ message.value.length }} / 2000</hint>
            </div>
            <div class="py-2">
              <label>{{ "GUILD-LOGS2.MAT-CHANNEL" | translate }}</label>
              <select formControlName="channel">
                <option
                  *ngFor="let channel of textChannels"
                  [value]="channel.id"
                >
                  #{{ channel.name }}
                  <span class="float-right text-muted"
                    >[{{ getChannel(channel.parentId)?.name || "N/A" }}]</span
                  >
                </option>
              </select>
            </div>
            <message-preview
              [content]="message.value"
              [guild]="guild"
            ></message-preview>
          </div>
        </div>
      </div>
      <div>
        <h2 id="eventos-variables">
          {{ "GUILD-LOGS2.MAT-VARIBLES" | translate }}
        </h2>
        <p>{{ "GUILD-LOGS2.MAT-VARIABLES-DESC" | translate }}</p>
        <div class="table-responsive-sm">
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th scope="col">{{ "GUILD-LOGS2.THEAD-1" | translate }}</th>
                <th scope="col">{{ "GUILD-LOGS2.THEAD-2" | translate }}</th>
                <th scope="col">{{ "GUILD-LOGS2.THEAD-4" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <code>{{ "{instigator}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-1" | translate }}</td>
                <td scope="row">WARN</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{message}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-2" | translate }}</td>
                <td scope="row">MESSAGE_DELETED</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{module}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-3" | translate }}</td>
                <td scope="row">CONFIG_UPDATE</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{new_level}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-4" | translate }}</td>
                <td scope="row">LEVEL_UP</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{new_value}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-5" | translate }}</td>
                <td scope="row">CONFIG_UPDATE</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{old_level}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-6" | translate }}</td>
                <td scope="row">LEVEL_UP</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{old_value}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-7" | translate }}</td>
                <td scope="row">CONFIG_UPDATE</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{reason}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-8" | translate }}</td>
                <td scope="row">WARN</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{server} | {server.name}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-9" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{server.id}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-10" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{server.icon_url}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-11" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{server.member_count}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-12" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{server.owner_id} | {server.owner}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-13" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{user} | {user.mention}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-14" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{user.avatar_url}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-15" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{user.discriminator}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-16" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{user.id}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-17" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{user.name}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-18" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{user.tag}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-19" | translate }}</td>
                <td scope="row">All</td>
              </tr>
              <tr>
                <th scope="row">
                  <code>{{ "{xp}" }}</code>
                </th>
                <td scope="row">{{ "GUILD-LOGS2.TBODY-20" | translate }}</td>
                <td scope="row">LEVEL_UP</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</guild-sidebar>
