<div class="rounded d-flex flex-column theme-dark">
	<div class="rounded chat d-flex flex-column flex-spacer">
		<div class="rounded content flex-spacer flex-horizontal">
			<div class="rounded flex-spacer d-flex flex-column messages-wrapper">
				<div class="message-group overflow-hidden">
					<div class="avatar-large animate" [style.backgroundImage]="'url(' + author.avatarURL + ')'"></div>
					<div class="comment">
						<div class="message first">
							<h2 style="line-height: 16px;">
								<span class="username-wrapper v-btm">
									<strong class="user-name">{{ member.displayName }}</strong>
									<span class="bot-tag">✓ {{ "EMBED.APP" | translate }}</span>
								</span>
								<span class="timestamp">{{ timestamp }}</span>
							</h2>
							<div class="container-embed">
								<span class="stripeLine" [style.background]="color"></span>
								<div class="embedFull-2tM8-- embed-IeVjo6">
									<div class="grid-1nZz7S hasThumbnail-3FJf1w">
										<div class="embedTitle-3OXDkz embedMargin-UO5XwE" [innerHtml]="titleprocessed"></div>
										<div class="embedFields-2IPs5Z">
											<div [innerHtml]="processed"></div>
										</div>
										<div class="thumbnail" [style.backgroundImage]="'url(' + thumbnail + ')'"></div>
										<img class="image" [src]="image" loading="lazy" alt="">
										<div class="embedFooter-3yVop- embedMargin-UO5XwE embedFooterText-28V_Wb" [innerHtml]="footerprocessed">
										</div>
									</div>
								</div>
							</div>
							<div class="container-1v9gV9">
								<div class="container-2xsjOj">
									<div class="children-2goeSq">
										<button class="component-1IAYeC button-38aScr sizeSmall-2cSMqn"
											[hidden]="!button.display" [style.background]="buttonStyle" [style.border-color]="buttonStyle">
											<div class="contents-18-Yxp">
												<div class="content-2wNArO">
													<div class="label-3aEGGA">{{ button.label }}</div>
												</div>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>