<navbar id="homeNavbar"></navbar>
<header>
  <div class="text-center py-5">
    @defer {
    <img
      loading="auto"
      src="assets/img/esp-customs-avatar-transparente.webp"
      alt="ESP CUSTOMS Discord App"
      class="float robot"
      width="320"
      height="320"
      style="aspect-ratio: 1/1"
    />
    }
    <h1>{{ "HOME.TITLE" | translate }}</h1>
    <p>{{ "HOME.PARAGRAPH" | translate }}</p>
    <div class="d-flex justify-content-center align-items-center gap-4 column">
      <a
        class="d-inline-flex justify-content-center align-items-center h-27 px-3 btn btn-bd-primary"
        routerLink="/invite"
        ><svg
          class="me-2"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 640 512"
        >
          <path
            d="M525 70a2 2 0 0 0-1-1 485 485 0 0 0-120-37 2 2 0 0 0-2 1 338 338 0 0 0-15 31 448 448 0 0 0-134 0 310 310 0 0 0-15-31 2 2 0 0 0-2-1 484 484 0 0 0-120 37 2 2 0 0 0-1 1 497 497 0 0 0-87 334 2 2 0 0 0 1 2 488 488 0 0 0 147 74 2 2 0 0 0 2-1 348 348 0 0 0 30-49 2 2 0 0 0-1-2 321 321 0 0 1-46-22 2 2 0 0 1 0-3l9-7a2 2 0 0 1 2-1c96 44 200 44 296 0a2 2 0 0 1 1 1l9 7a2 2 0 0 1 0 3 301 301 0 0 1-46 22 2 2 0 0 0-1 2 391 391 0 0 0 30 49 2 2 0 0 0 3 1 486 486 0 0 0 147-74 2 2 0 0 0 1-2c12-126-21-236-87-334zM223 338c-29 0-53-27-53-60s23-59 53-59c29 0 53 27 52 59 0 33-23 60-52 60zm195 0c-29 0-53-27-53-60s23-59 53-59 53 27 53 59c0 33-23 60-53 60z"
            style="fill: var(--font)"
          />
        </svg>
        {{ "HOME.ADD-DISCORD" | translate }}</a
      >
      <premium-badge
        class="d-inline-flex justify-content-center align-items-center h-27 px-3 btn btn-bd-premium"
        title="{{ 'DASHBOARD.PREMIUM' | translate }}"
        classvg="me-2"
      ></premium-badge>
    </div>
  </div>
  <waves></waves>
</header>
<div style="background-color: var(--background-secondary)">
  @defer (on viewport) {
  <section
    class="d-flex py-2 justify-content-center align-items-center flex-column"
  >
    <div class="bwuBqu">
      <div class="iaFuEI">
        <div class="d-flex align-items-center gap-2">
          <div class="features-tag-icon p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M256 0h64c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32h-64c-17.7 0-32-14.3-32-32V32c0-17.7 14.3-32 32-32zM64 64h128v48c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V64h128c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128c0-35.3 28.7-64 64-64zm112 373.3c0 5.9 4.8 10.7 10.7 10.7h202.6c5.9 0 10.7-4.8 10.7-10.7 0-29.5-23.9-53.3-53.3-53.3H229.3c-29.5 0-53.3 23.9-53.3 53.3zM288 352a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          <p class="m-0">{{ "GUILD-SIDEBAR.CAT-WELCOME" | translate }}</p>
        </div>
        <h1>
          {{ "HOME.WELCOMECARD" | translate }}
        </h1>
        <p style="color: var(--muted)">
          {{ "HOME.WELCOMECARD-PARAGRAPH" | translate }}
        </p>
      </div>
      <div class="eIOKqD">
        <img
          loading="auto"
          fetchpriority="low"
          width="100%"
          height="100%"
          class="responsive"
          id="welcome"
          src="assets/img/home/welcomeCard.webp"
          alt="WELCOME CARD"
          style="aspect-ratio: 9/4"
        />
      </div>
    </div>
  </section>
  } @placeholder {
  <section>.</section>
  } @loading {
  <section>Cargando …</section>
  }
  <div
    class="position-relative d-flex justify-content-center align-items-center w-100 p-1 mx-auto rounded overflow-hidden"
    style="background-color: var(--background-tertiary); height: 290px;"
  >
    <!-- Home 1 -->
    <ins
      class="adsbygoogle"
      style="display: inline-block; width: 728px; height: 90px"
      data-ad-client="ca-pub-4415405501958128"
      data-ad-slot="4316392695"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
  @defer (on viewport) {
  <section
    class="d-flex py-2 justify-content-center align-items-center flex-column"
  >
    <div class="bwuBqu">
      <div class="eIOKqD">
        <img
          loading="auto"
          width="100%"
          height="100%"
          class="responsive"
          id="leaderboard"
          src="assets/img/home/leaderboard.webp"
          alt="Leaderboard"
          style="aspect-ratio: 573/310"
        />
      </div>
      <div class="iaFuEI">
        <div class="d-flex align-items-center gap-2">
          <div class="features-tag-icon p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2.2 5.3.4 10.6.7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7 44.3 43.1 98.3 64.8 138.1 75.8 23.4 6.5 39.4 26 39.4 45.6 0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32h192c17.7 0 32-14.3 32-32s-14.3-32-32-32h-26.1c-20.9 0-37.9-17-37.9-37.9 0-19.6 15.9-39.2 39.4-45.6 39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2.5-10.4.7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6-24.9-11-50.8-26.5-73.2-48.3-32-31.1-58-76-63-142.3zm415.2 142.3c-22.4 21.8-48.3 37.3-73.2 48.3 22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          <p class="m-0">{{ "GUILD-SIDEBAR.CAT-LEVLES" | translate }}</p>
        </div>
        <h1>
          {{ "HOME.LEVELS" | translate }}
        </h1>
        <p style="color: var(--muted)">
          {{ "HOME.LEVELS-PARAGRAPH" | translate }}
        </p>
      </div>
    </div>
  </section>
  } @placeholder {
  <section>.</section>
  } @loading {
  <section>Cargando …</section>
  }
  <div
    class="position-relative d-flex justify-content-center align-items-center w-100 p-1 mx-auto rounded overflow-hidden"
    style="background-color: var(--background-tertiary)"
  >
    <!-- Home 2 -->
    <ins
      class="adsbygoogle"
      style="display: inline-block; width: 728px; height: 90px"
      data-ad-client="ca-pub-4415405501958128"
      data-ad-slot="3312188260"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
  @defer (on viewport) {
  <section
    class="d-flex py-2 justify-content-center align-items-center flex-column"
  >
    <div class="bwuBqu">
      <div class="iaFuEI">
        <div class="d-flex align-items-center gap-2">
          <div class="features-tag-icon p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 640 512"
            >
              <path
                d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3l89.3 89.4-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          <p class="m-0">{{ "GUILD-SIDEBAR.CAT-LEVLES" | translate }}</p>
        </div>
        <h1>
          {{ "HOME.RANKS" | translate }}
        </h1>
        <p style="color: var(--muted)">
          {{ "HOME.RANKS-PARAGRAPH" | translate }}
        </p>
      </div>
      <div class="eIOKqD">
        <img
          loading="auto"
          width="100%"
          height="100%"
          class="responsive"
          id="ranking"
          src="assets/img/home/ranking.webp"
          alt="XP CARD"
          style="aspect-ratio: 3/2"
        />
      </div>
    </div>
  </section>
  } @placeholder {
  <section>.</section>
  } @loading {
  <section>Cargando …</section>
  }
  <div
    class="position-relative d-flex justify-content-center align-items-center w-100 p-1 mx-auto rounded overflow-hidden"
    style="background-color: var(--background-tertiary)"
  >
    <!-- Home 3 -->
    <ins
      class="adsbygoogle"
      style="display: inline-block; width: 728px; height: 90px"
      data-ad-client="ca-pub-4415405501958128"
      data-ad-slot="6736533549"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
  @defer (on viewport) {
  <section
    class="d-flex py-2 justify-content-center align-items-center flex-column"
  >
    <div class="bwuBqu">
      <div class="eIOKqD">
        <img
          loading="auto"
          width="100%"
          height="100%"
          class="responsive"
          id="announce"
          src="assets/img/home/announce.webp"
          alt="Announce"
          style="aspect-ratio: 53/32"
        />
      </div>
      <div class="iaFuEI">
        <div class="d-flex align-items-center gap-2">
          <div class="features-tag-icon p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 448 512"
            >
              <path
                d="M224 0c-17.7 0-32 14.3-32 32v19.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416h384c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3c-31.2-35.2-48.5-80.5-48.5-127.6V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H160c0 17 6.7 33.3 18.7 45.3S207 512 224 512s33.3-6.7 45.3-18.7z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          <p class="m-0">{{ "GUILD-SIDEBAR.CAT-LOGS" | translate }}</p>
        </div>
        <h1>
          {{ "HOME.EVENTS" | translate }}
        </h1>
        <p style="color: var(--muted)">
          {{ "HOME.EVENTS-PARAGRAPH" | translate }}
        </p>
      </div>
    </div>
  </section>
  } @placeholder {
  <section>.</section>
  } @loading {
  <section>Cargando …</section>
  }
  <div
    class="position-relative d-flex justify-content-center align-items-center w-100 p-1 mx-auto rounded overflow-hidden"
    style="background-color: var(--background-tertiary)"
  >
    <!-- Home 4 -->
    <ins
      class="adsbygoogle"
      style="display: inline-block; width: 728px; height: 90px"
      data-ad-client="ca-pub-4415405501958128"
      data-ad-slot="6761607756"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
  @defer (on viewport) {
  <section
    class="d-flex py-2 justify-content-center align-items-center flex-column"
  >
    <div class="bwuBqu">
      <div class="iaFuEI">
        <div class="d-flex align-items-center gap-2">
          <div class="features-tag-icon p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 576 512"
            >
              <path
                d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64h448c35.3 0 64-28.7 64-64v-64c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6v-64c0-35.3-28.7-64-64-64H64zm64 112v160c0 8.8 7.2 16 16 16h288c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zm-32-16c0-17.7 14.3-32 32-32h320c17.7 0 32 14.3 32 32v192c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          <p class="m-0">{{ "GUILD-SIDEBAR.CAT-TICKET" | translate }}</p>
        </div>
        <h1>
          {{ "HOME.TICKETS" | translate }}
        </h1>
        <p style="color: var(--muted)">
          {{ "HOME.TICKETS-PARAGRAPH" | translate }}
        </p>
      </div>
      <div class="eIOKqD">
        <img
          loading="auto"
          width="100%"
          height="100%"
          class="responsive"
          id="music"
          src="assets/img/home/tickets.webp"
          alt="Gerente de música"
          style="aspect-ratio: 53/32"
        />
      </div>
    </div>
  </section>
  } @placeholder {
  <section>.</section>
  } @loading {
  <section>Cargando …</section>
  }
  <div
    class="position-relative d-flex justify-content-center align-items-center w-100 p-1 mx-auto rounded overflow-hidden"
    style="background-color: var(--background-tertiary)"
  >
    <!-- Home 5 -->
    <ins
      class="adsbygoogle"
      style="display: inline-block; width: 728px; height: 90px"
      data-ad-client="ca-pub-4415405501958128"
      data-ad-slot="6761607756"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
  @defer (on viewport) {
  <section
    class="d-flex py-2 justify-content-center align-items-center flex-column"
  >
    <div class="bwuBqu">
      <div class="iaFuEI">
        <img
          loading="auto"
          width="100%"
          height="100%"
          class="responsive"
          id="music"
          src="assets/img/home/streamings.webp"
          alt="Alertas de redes sociales"
          style="aspect-ratio: 53/32"
        />
      </div>
      <div class="eIOKqD">
        <div class="d-flex align-items-center gap-2">
          <div class="features-tag-icon p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 512 512"
            >
              <path
                d="M391.2 103.5h-38.7v109.7h38.6zM285 103h-38.6v109.8H285zM120.8 0 24.3 91.4v329.2h115.8V512l96.5-91.4h77.3L487.7 256V0zm328.3 237.8-77.2 73.1h-77.3l-67.6 64v-64h-86.9V36.6h309z"
                style="fill: var(--font)"
              />
            </svg>
          </div>
          <p class="m-0">{{ "GUILD-SIDEBAR.CAT-SOCIAL" | translate }}</p>
        </div>
        <h1>
          {{ "HOME.ALERTS" | translate }}
        </h1>
        <p style="color: var(--muted)">
          {{ "HOME.ALERTS-PARAGRAPH" | translate }}
        </p>
      </div>
    </div>
  </section>
  } @placeholder {
  <section>.</section>
  } @loading {
  <section>Cargando …</section>
  }
</div>
@defer (on viewport) {
<footer id="homeFooter"></footer>
} @placeholder {
<footer>.</footer>
} @loading {
<footer>Cargando …</footer>
}
