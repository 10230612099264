import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
  stats: any;
  iconUrl: string;
  loading: boolean = false;
  errorMessage: string = '';

  constructor(private api: ApiService) { }

  async ngOnInit() {
    this.stats = await this.api.getStats();
    this.iconUrl = 'https://cdn.discordapp.com/icons/';
  }

  async deleteGuild(guildId: string) {
    this.loading = true;
    try {
      await this.api.deleteGuild(guildId);
    } catch (error) {
      this.errorMessage = error.message;
    }
    this.loading = false;
  }
}