import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModuleConfig } from '../../../module-config';
import { GuildService } from '../../../services/guild.service';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "fortnite-custom",
  templateUrl: "./fortnite-custom.component.html",
  styleUrls: ["./fortnite-custom.component.css"],
})
export class FortniteCustomComponent extends ModuleConfig implements OnInit {
  moduleName = "fortnite";
  button = { label: "✋ Link Epic Account", customId: "", style: "PRIMARY", display: true, };

  get user() {
    return this.userService.user;
  }
  get fortnite() {
    return this.savedGuild.fortnite;
  }
  optionsArray = ["@everyone", "@here"];
  buildForm({ fortnite }: any) {
    const form = new FormGroup({
      enabled: new FormControl(Boolean),
      titleVerify: new FormControl("", Validators.maxLength(40)),
      descriptionVerify: new FormControl("", Validators.maxLength(500)),
      modeSolo: new FormControl("", Validators.maxLength(20)),
      modeDuo: new FormControl("", Validators.maxLength(20)),
      modeTrio: new FormControl("", Validators.maxLength(20)),
      rulesSolo: new FormControl("", Validators.maxLength(500)),
      rulesDuo: new FormControl("", Validators.maxLength(500)),
      rulesTrio: new FormControl("", Validators.maxLength(500)),
      thumbnailVerify: new FormControl(""),
      thumbnailSolo: new FormControl(""),
      thumbnailDuo: new FormControl(""),
      thumbnailTrio: new FormControl(""),
      channelVerify: new FormControl(""),
      channelSolo: new FormControl(""),
      channelDuo: new FormControl(""),
      channelTrio: new FormControl(""),
      roleSolo: new FormControl(""),
      roleDuo: new FormControl(""),
      roleTrio: new FormControl(""),
    });
    form.patchValue(fortnite);
    return form;
  }
  constructor(
    public translate: TranslateService,
    guildService: GuildService,
    route: ActivatedRoute,
    userService: UserService,
    saveChanges: MatSnackBar
  ) {
    super(guildService, route, userService, saveChanges);
  }
  async ngOnInit() {
    await super.init();
  }
}