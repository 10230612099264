<body>
  <div class="stars">
    <div class="central-body">
      <img
        class="image-404"
        src="assets\img\404.svg"
        loading="auto"
        alt=""
        width="300px"
      />
      <a class="btn-go-home" routerLink="/">{{
        "NOT-FOUND.404" | translate
      }}</a>
    </div>
    <div class="objects">
      <img
        class="object_rocket"
        src="assets\img\rocket.svg"
        loading="auto"
        alt=""
        width="40px"
      />
      <img
        class="object_cometa2"
        src="assets\img\cometa.svg"
        loading="auto"
        alt=""
        width="40px"
      />
      <div class="blur">
        <img
          class="object_cometa"
          src="assets\img\cometa.svg"
          loading="auto"
          alt=""
          width="100px"
        />
      </div>
      <div class="earth-moon">
        <img
          class="object_earth"
          src="assets\img\earth.svg"
          loading="auto"
          alt=""
          width="100px"
        />
        <img
          class="object_moon"
          src="assets\img\moon.svg"
          loading="auto"
          alt=""
          width="80px"
        />
        <img
          class="object_mars"
          src="assets\img\Sol.svg"
          loading="auto"
          alt=""
        />
      </div>
      <div class="box_astronaut">
        <img
          class="object_astronaut"
          src="assets\img\esp-customs-avatar-transparente.webp"
          loading="auto"
          alt=""
          width="280px"
        />
      </div>
    </div>
  </div>
</body>
<footer>
  <p class="float-right text-muted">
    <strong>ESP CUSTOMS</strong>: Made with ❤ by srgobi
  </p>
</footer>
