import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'premium-paywall',
  templateUrl: './premium-paywall.component.html',
  styleUrls: ['./premium-paywall.component.css']
})
export class PremiumPaywallComponent {
  constructor(public userService: UserService) { }
}
