import { Injectable } from '@angular/core';
import Fuse from 'fuse.js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {
  endpoint = environment.endpoint + '/commands';
  private _commands: any[] = [];

  constructor() { }

  async init() {
    await this.updateCommands();
  }

  private async fetchCommands() {
    const response = await fetch(`${this.endpoint}`);
    const data = await response.json();
    return data;
  }

  async updateCommands() {
    const response = await this.fetchCommands();
    this._commands = response as any;
  }

  search(query: string) {
    const fuse = new Fuse(this.commands, {
      includeScore: true,
      keys: [
        { name: 'data.name', weight: 1 },
        { name: 'data.description', weight: 0.5 },
        { name: 'usage', weight: 0.3 }
      ]
    });

    return fuse
      .search(query)
      .map(r => r.item);
  }

  get commands() { return this._commands; }
}
