import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'premium-badge',
  templateUrl: './premium-badge.component.html',
  styleUrls: ['./premium-badge.component.css']
})
export class PremiumBadgeComponent {
  @Input('title') title = '';
  @Input('classvg') classvg = '';
  constructor(public translate: TranslateService) {}
}
