<div class="rounded d-flex flex-column whitney theme-dark">
	<div class="rounded chat d-flex flex-column flex-spacer">
		<div class="rounded content flex-spacer flex-horizontal">
			<div class="rounded flex-spacer d-flex flex-column messages-wrapper">
				<div class="message-group overflow-hidden">
					<div class="avatar-large animate" [style.backgroundImage]="'url(' + author.avatarURL + ')'"></div>
					<div class="comment">
						<div class="message first">
							<h2 style="line-height: 16px;">
								<span class="username-wrapper v-btm">
									<strong class="user-name">{{ member.displayName }}</strong>
									<span class="bot-tag">✓ {{ "EMBED.APP" | translate }}</span>
								</span>
								<span class="timestamp">{{ timestamp }}</span>
							</h2>
							<div class="container-embed">
								<div class="embedFull-2tM8-- embed-IeVjo6">
									<div class="grid-1nZz7S hasThumbnail-3FJf1w">
										<div class="embedTitle-3OXDkz embedMargin-UO5XwE"><strong>Arena Custom</strong></div>
										<div class="embedFields-2IPs5Z">
											<div class="embedField-1v-Pnh" style="grid-column: 1 / 7;">
												<div class="embedFieldName-NFrena"><strong>CUSTOM KEY:</strong></div>
												<div>code</div>
											</div>
											<div class="embedField-1v-Pnh" style="grid-column: 7 / 13;">
												<div class="embedFieldName-NFrena"><strong>MODO DE JUEGO</strong></div>
												<div class="message-text" [innerHtml]="arenamode"></div>
											</div>
											<div class="embedField-1v-Pnh" style="grid-column: 1 / 13;">
												<div class="embedFieldName-NFrena"><strong>NORMAS:</strong></div>
												<div class="message-text" [innerHtml]="processed"></div>
											</div>
										</div>
										<div class="thumbnail" [style.backgroundImage]="'url(' + thumbnail + ')'"></div>
										<div class="embedFooter-3yVop- embedMargin-UO5XwE"><span class="embedFooterText-28V_Wb">Made
												with ❤ by ! SrGobi#6966 | patreon.com/espcustoms</span></div>
									</div>
								</div>
								<button type="button" class="reactionInner-15NvIl">✋
									<div class="reactionCount-2mvXRV" style="min-width: 9px;">2</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>